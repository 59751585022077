import React, { useState } from 'react';
import { FormFields, AsyncSelectFields, InputFields, ButtonFields } from '../../../components/Index';
import { addProductSalesSchema } from '../../../utils/validations/ProductValidation';
import { getListaServiciosCombo } from '../../../services/ProductService';

export default function AddServicioComponents({onAddServicio}) {
    const [ detalle, setDetalle ] = useState({});
    

    const onClickAdd = (values, onSubmitProps) => {
        values.fechaInicio = values.fechaInicio === '' ? null : values.fechaInicio;
        values.fechaFin = values.fechaFin === '' ? null : values.fechaFin;
        values.descripcion = detalle.descripcion;
        values.nombre = detalle.nombre;
        values.precioTotal = values.precio === "" ? '' : parseInt(values.stock) * parseFloat(values.precio);
        onAddServicio(values);
        onSubmitProps.resetForm();
    }

    const onSelectProducto = (data) => {
        setDetalle(data);
    }

    const fechDataProduct = async (value) => {
        const resp_product = await getListaServiciosCombo(value);
        return resp_product;
    }
    return (
        <FormFields
            initialValues = {{
                productoId:'',
                stock:'1',
                precio: "",
                fechaInicio: '',
                fechaFin: ''
            }}
            onSubmit = {(values, onSubmitProps) => onClickAdd(values, onSubmitProps)}
            validationSchema = {addProductSalesSchema}
        >
            <div className='row'>
                <div className='col-12'>
                    <AsyncSelectFields
                        idFields = {"productoId"}
                        label={"Servicios"}
                        name={"productoId"}
                        loadOptions = {fechDataProduct}
                        onSelect={(item) => onSelectProducto(item)}
                    />
                </div>
                <div className='col-sm-12 col-md-5 col-lg-5'>
                    <InputFields
                        label={"Fecha Alquiler"}
                        type="datetime-local"
                        name="fechaInicio"
                        size = "sm"
                        isRequired = {false}
                    />
                </div>
                <div className='col-sm-6 col-md-5 col-lg-5'>
                    <InputFields
                        isRequired = {false}
                        label={"Precio Unitario"}
                        type="text"
                        name="precio"
                        size = "sm"
                    />
                </div>
                <div className='col-ms-12 col-md-2 col-lg-2 mt-3'>
                    <ButtonFields
                        className='btn btn-inverse-primary btn-sm btn-block float-right'
                        title = {
                            <i className='fa fa-shopping-cart'/> 
                        }/>
                </div>
            </div>
        </FormFields>
    );
}
