import React, { useState,useEffect } from 'react';
import { Row, Card } from 'react-bootstrap';
import { Search, FormFields, InputFields, Table, ButtonFields, InputFileFields, ButtonResetFields, ModalComponents, Loading} from '../../components/Index';
import { getListaCompanias, actualizarCompania } from '../../services/ExpensesService';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../utils/helper/AlertService';
import UserPermissionsComponent from '../auth/UserPermissionsComponent';

const search = {
    skip: 1,
    take: 10,
    search: ""
}

export default function ListCompany() {
    const model = {
        compania:'',
        actividadComenrcial:'',
        direccion:'',
        ruc:'',
        telefonoUno:'',
        telefonoDos:'',
        imagen: { contenido:"", extension:""}
    }
    const [ mdShow, setMdShow ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ listEmpresa, setListEmpresa ] = useState([]);
    const [ empresaPag, setEmpresaPag ] = useState({});
    const [ paramSearch, setParamSearch ] = useState(search);
    const [ inicialModel, setInicialModel ] = useState(model);
    const [ image, setImage ] = useState(null);
    
    const onClickCreate = (values,onSubmitProps) => {
        showConfirAlert({
            title: "Desea Actualizar Compañia",
            text: "Verifique que todos los campos esten registrados correctamente",
            preConfirm: () => {
                actualizarCompania(values).then(() => {
                    showSuccessAlert('Registro Exitoso');
                    onSubmitProps.setSubmitting(false)
                    onSubmitProps.resetForm();
                    getData(paramSearch);
                    setMdShow(false);
                }).catch((error) => {
                    showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
                });
            }
        });
    }

    const onClickEditar = (item) => {
        setMdShow(true);
        item.imagen = { contenido:"", extension:""}
        setInicialModel(item);
    }

    const onInputSearch = ({target}) => {
        const { value } = target;
        setParamSearch({...paramSearch,search:value})
    }

    const handleTableChange = (page, sizePerPage) => {
        setParamSearch({
            ...paramSearch,
            skip:sizePerPage.page,
            take:sizePerPage.sizePerPage,
        })
    }

    const getData = async (param) => {
        try {
            setLoading(true);
            var data = await getListaCompanias(param);
            setLoading(false);
            setListEmpresa(data.items);
            setEmpresaPag(data);

        } catch(e) {
            showErrorAlert('Hubo un problema de red, vuelva intentarlo');
        }
    }

    useEffect(() => {
        getData(paramSearch);
    },[paramSearch])

    const columns = [
        { 
            dataField: "compania", 
            text: "Nombre de Empresa"
        },
        { 
            dataField: "direccion", 
            text: "Dirección"
        },
		{ 
            dataField: "ruc", 
            text: "RUC" 
        },
        {   
            dataField: "telefonoUno",     
            text: "Celular 1"
        },
        { 
            dataField: "telefonoDos", 
            text: "Celular 2",
        },
        { 
            dataField: "telefonoTres", 
            text: "Celular 3",
        },
        {
            dataField: "opciones", 
            text: "Opciones",
            sort: false,
            align: 'center',
            formatter: (cellContent, row) => {
            return (
                <div>
                    <UserPermissionsComponent accion={['admin']}>
                        <i className="ti-pencil-alt text-primary mr-2" data-toggle="tooltip" data-placement="right" title="Editar" onClick={() => onClickEditar(row)}/>
                    </UserPermissionsComponent>
                </div>
            );
            }
        }
    ];
  return (
    <div>
        <Row >
            <div className="col-12">
                <Card >  
                    <Card.Body>
                        <div className="row mb-4 justify-content-between">
                            <div className = "col-lg-3 d-none d-sm-none d-md-block">
                                <h4 className="card-title float-left mt-2">Lista Empresas</h4>
                            </div>
                            <div className = "col-lg-6 pr-0 pl-0 col-ms-12">
                                <Search
                                    onSearchInput={onInputSearch}
                                    value={paramSearch.search}
                                    isSearchDrowp = {false}
                                    onRefesh={() => setParamSearch(search)}
                                />
                                
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-3">
                                {/* <button className="btn btn-outline-primary float-right" onClick={() => console.log("Hola")}>
                                    <i className="fa fa-plus mr-2"/>
                                    Nueva Empresa
                                </button> */}
                            </div>
                        </div>    
                        <Table
                            keyField = {'empresaId'}
                            columns={columns}
                            list={listEmpresa}
                            page={empresaPag.page}
                            sizePerPage={paramSearch.take}
                            totalSize={empresaPag.total}
                            onTableChange={handleTableChange}
                        />
                        <ModalComponents
                            titulo={"Registrar nueva Compania"}
                            show = {mdShow}
                            hide={() => setMdShow(false)}
                            size='md'
                        >
                            <FormFields
                                initialValues={inicialModel}
                                //validationSchema={}
                                onSubmit={(values,onSubmitProps) => onClickCreate(values,onSubmitProps)}
                            >
                            <div className='row'>
                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                    <InputFields
                                        label={"Nombre de Empresa"}
                                        type="text"
                                        name="compania"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                    <InputFields
                                        label={"RUC"}
                                        type="text"
                                        name="ruc"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                    <InputFields
                                        label={"Actividad Comenrcial"}
                                        type="text"
                                        isRequired={false}
                                        name="actividadComenrcial"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                    <InputFields
                                        label={"Telefono Uno"}
                                        type="text"
                                        name="telefonoUno"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                    <InputFields
                                        label={"Telefono Dos"}
                                        type="text"
                                        name="telefonoDos"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                    <InputFields
                                        label={"Telefono Tres"}
                                        type="text"
                                        name="telefonoTres"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                    <InputFileFields 
                                        label={"Logo de Empresa"}
                                        name={"imagen"}
                                        onSelectImage={(url) => setImage(url)}
                                    />
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                    <Card>
                                        <Card.Body className='p-3'>
                                            <div className='h-75 w-100'>
                                                <img src={image} className="img-fluid" alt=""></img>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-12 mt-2'>
                                    <ButtonFields/>
                                    <ButtonResetFields/>
                                </div>
                            </div>
                            </FormFields>
                        </ModalComponents>
                        <Loading isLoading = {loading}/>
                    </Card.Body>
                </Card>
            </div>
        </Row>
    </div>
  )
}
