import React from 'react';
import { useFormikContext } from 'formik';

function ButtonResetFields({title = "Cancelar", className = "btn btn-danger float-right ml-3", onClickCancel = () => {}}) {
    const { resetForm } = useFormikContext();
    return (
        <button
            type="button"
            onClick={() => {
                resetForm(); 
                onClickCancel()
            }}
            className={className}
            >
                {title}
        </button>
    );
}

export default ButtonResetFields;