import Client from './Client';

const endpoint= "api/v1/";
//Listar Persona
export const getListaBitacora = (data) => {
    return Client.post(`${endpoint}BitacoraEgreso/Lista`,data)
}
export const getListaDetalleBitacora = (bitacoraId) => {
    return Client.get(`${endpoint}BitacoraEgreso/ListaDetalle?BitacoraId=${bitacoraId}`)
}
export const getListaCompanias = (data) => {
    return Client.post(`${endpoint}Compania/Lista`,data)
}
export const getListaIngresos = (data) => {
    return Client.post(`${endpoint}Ingreso/Lista`,data)
}

export const getCaculoCierreCaja = (data) => {
    return Client.get(`${endpoint}Caja/Calcular`,data)
}

export const getListarCierreCaja = (data) => {
    return Client.post(`${endpoint}Caja/Listar`,data)
}

export const getDetalleCierreCaja = (detalleId) => {
    return Client.get(`${endpoint}Caja/DetalleCajaCierre?CajaId=${detalleId}`)
}



//Crear 
export const crearBitacora = (data) => {
    return Client.post(`${endpoint}BitacoraEgreso/Crear`,data)
}
export const crearDetalleBitacora = (data) => {
    return Client.post(`${endpoint}BitacoraEgreso/CrearDetalle`,data)
}
export const crearIngresos = (data) => {
    return Client.post(`${endpoint}Ingreso/Crear`,data)
}
export const crearCierreCaja = (data) => {
    return Client.post(`${endpoint}Caja/Crear`,data)
}



//Eliminar 
export const eliminarBitacora = (bitacoraId) => {
    return Client.delete(`${endpoint}BitacoraEgreso/Elimar/${bitacoraId}`)
}
export const eliminarDetalleBitacora = (detalleBitacoraId) => {
    return Client.delete(`${endpoint}BitacoraEgreso/ElimarDetalle/${detalleBitacoraId}`)
}

//Actualiar
export const actualizarBitacora = (data) => {
    return Client.put(`${endpoint}BitacoraEgreso/Update`,data)
}
export const actualizarCompania = (data) => {
    return Client.put(`${endpoint}Compania/Actualizar`,data)
}
export const actualizarIngreso = (data) => {
    return Client.put(`${endpoint}Ingreso/Actualizar`,data)
}

export const actualizarEstadoCaja = (data) => {
    return Client.put(`${endpoint}Caja/CambiarEstado`,data)
}