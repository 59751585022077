import React from 'react';

function Loading({isLoading = false}) {
    return (
        <div>
            {/* <div className="square-box-loader">
                <div className="square-box-loader-container">
                    <div className="square-box-loader-corner-top"></div>
                    <div className="square-box-loader-corner-bottom"></div>
                </div>
                <div className="square-box-loader-square"></div>
            </div> */}
            {
                isLoading &&  
                <div className="square-box-loader">
                    <div className="pixel-loader"></div>
                </div>
            }
            
        </div>
    );
}

export default Loading;