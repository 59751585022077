import * as Yup from 'yup'

export const logingSchema = Yup.object().shape({
        email: Yup.string()
                .email("No es un correo electronico adecuado")
                .required("El campo Email es requerido"),
        password: Yup.string()
                .required("No se proporcionó la contraseña")
                .min(8, 'La contraseña es demaciado corta; debe de tener un minimo de 8 caracteres.')
                .matches(/[a-zA-Z]/, 'La contraseña debe contener caracteneres y números.')
})

//CREATE
export const createPersonSchema = Yup.object().shape({

        primerNombre: Yup.string()
                .min(2, 'Texto muy corto')
                .required('El nombre es un campo requrido'),
        segundoNombre: Yup.string()
                .min(2, 'Texto muy corto'),
        apellidoPaterno: Yup.string()
                .min(2, 'Texto muy corto')
                .required('El apellido paterno es un campo requrido'),
        apellidoMaterno: Yup.string()
                .min(2, 'Texto muy corto')
                .required('El apellido materno es un campo requrido'),
        nroDoi: Yup.number()
                .typeError("No es un número")
                .min(8, 'DNI invalido')
                .required('El apellido paterno es un campo requrido'),
        direccion: Yup.string()
                .min(5, 'Texto muy corto')
                .required('La dirección es un campo requrido'),
        telefonoUno: Yup.number()
                .typeError("No es un número")
                .min(6, 'Celular invalido')
                .required('El número de celular es un campo requrido'),
        telefonoDos: Yup.number()
                .typeError("No es un número")
                .min(6, 'Celular invalido')
                .required('El número de celular es un campo requrido'),
        telefonoTres: Yup.string()
                .nullable(),
        
        email: Yup.string().email()
               .min(6, 'Celular invalido'),
        
        sexo: Yup.string()
                .required('El campo Sexo es requerido')
      
});

export const createDeceasedSchema = Yup.object().shape({

        primerNombre: Yup.string()
                .min(2, 'Texto muy corto')
                .required('El nombre es un campo requrido'),
        segundoNombre: Yup.string()
                .min(2, 'Texto muy corto'),
        apellidoPaterno: Yup.string()
                .min(2, 'Texto muy corto')
                .required('El apellido paterno es un campo requrido'),
        apellidoMaterno: Yup.string()
                .min(2, 'Texto muy corto')
                .required('El apellido materno es un campo requrido'),
        nroDoi: Yup.number()
                .typeError("No es un número")
                .min(8, 'DNI invalido')
                .required('El campo DNI es requrido'),
        direccion: Yup.string()
                .min(5, 'Texto muy corto')
                .required('La dirección es un campo requrido'),
        sexo: Yup.string()
                .required('El campo Sexo es requerido'),
        fechaFallecimiento: Yup.string()
                .required("Campo fecha fallecimiento es requerido")
      
       
});

export const createSupplierSchema = Yup.object().shape({
        ruc: Yup.number()
                .typeError("No es un número")
                .min(8, 'RUT o DNI invalido')
                .required('El campo RUT o DNI es requrido'),
        compania: Yup.string()
                .min(2, 'Texto muy corto')
                .required('El apellido paterno es un campo requrido'),
        direccion: Yup.string()
                .min(5, 'Texto muy corto')
                .required('La dirección es un campo requrido'),
        telefono: Yup.number()
                .typeError("No es un número")
                .min(6, 'Celular invalido')
                .required('El campo telefono es requerido'),
        telefonoDos: Yup.number()
                .typeError("No es un número")
                .min(6, 'Celular invalido'),
})

export const createUserSchema = Yup.object().shape({
        primerNombre: Yup.string()
                .min(2, 'Texto muy corto')
                .required('El nombre es un campo requrido'),
        segundoNombre: Yup.string()
                .min(2, 'Texto muy corto'),
        apellidoPaterno: Yup.string()
                .min(2, 'Texto muy corto')
                .required('El apellido paterno es un campo requrido'),
        apellidoMaterno: Yup.string()
                .min(2, 'Texto muy corto')
                .required('El apellido materno es un campo requrido'),
        email: Yup.string()
                .email("No es un correo electronico adecuado")
                .required("El campo Email es requerido"),
        nroDOI: Yup.number()
                .typeError("No es un número")
                .min(8, 'DNI invalido')
                .required('El apellido paterno es un campo requrido'),
        direccion: Yup.string()
                .min(5, 'Texto muy corto')
                .required('La dirección es un campo requrido'),
        telefono: Yup.number()
                .typeError("No es un número")
                .min(6, 'Celular invalido')
                .required('El número de celular es un campo requrido'),
        password: Yup.string()
                .required("No se proporcionó la contraseña")
                .min(8, 'La contraseña es demaciado corta; debe de tener un minimo de 8 caracteres.')
                .matches(/[a-zA-Z]/, 'La contraseña debe contener caracteneres y números.'),
        confirmPassword: Yup.string()
                .required("El campo es requerido")
                .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir'),
        fotoPerfil: Yup.object({
                contenido: Yup.string().min(100, 'Texto muy corto'),
                extension: Yup.string().min(2, 'Texto muy corto'),
        })
});

export const updateUserSchema = Yup.object().shape({
        id: Yup.string(),
        primerNombre: Yup.string()
                .min(2, 'Texto muy corto')
                .required('El nombre es un campo requrido'),
        segundoNombre: Yup.string()
                .nullable(true)
                .min(2, 'Texto muy corto'),
        apellidoPaterno: Yup.string()
                .min(2, 'Texto muy corto')
                .required('El apellido paterno es un campo requrido'),
        apellidoMaterno: Yup.string()
                .min(2, 'Texto muy corto')
                .required('El apellido materno es un campo requrido'),
        nroDOI: Yup.number()
                .typeError("No es un número")
                .min(8, 'DNI invalido')
                .required('El apellido paterno es un campo requrido'),
        direccion: Yup.string()
                .min(5, 'Texto muy corto')
                .required('La dirección es un campo requrido'),
        telefono: Yup.number()
                .typeError("No es un número")
                .min(6, 'Celular invalido')
                .required('El número de celular es un campo requrido'),
        fotoPerfil: Yup.object({
                contenido: Yup.string().min(100, 'Texto muy corto'),
                extension: Yup.string().min(2, 'Texto muy corto'),
        })
});

export const updateAccountUserSchema = Yup.object().shape({
        id: Yup.string(),
        email: Yup.string()
                .email("No es un correo electronico adecuado")
                .required("El campo Email es requerido"),
        password: Yup.string()
                .required("No se proporcionó la contraseña")
                .min(8, 'La contraseña es demaciado corta; debe de tener un minimo de 8 caracteres.')
                .matches(/[a-zA-Z]/, 'La contraseña debe contener caracteneres y números.'),
        confirmPassword: Yup.string()
                .required("El campo es requerido")
                .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir'),
});

export const editPersonSchema = Yup.object().shape({
        titulo: Yup.string()
            .min(5, 'Texto muy corto')
            .required('El campo es requrido')
});

export const createPermisoSchema = Yup.object().shape({
        usuarioId: Yup.string()
                .required('El campo es requrido'),
        permisos: Yup.array(),
        roles: Yup.array()
});