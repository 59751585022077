import React, { useEffect, useState } from 'react';
import { FormFields, InputFields, SelectFields, InputFileFields, ButtonFields, ButtonResetFields } from '../../components/Index';
import { Card } from 'react-bootstrap';
import { updateProductSchema } from '../../utils/validations/ProductValidation';
import { actualizarProducto, getListaProductoTipoCombo, getListaCategoria, getListaModelo, getTipoProducto } from '../../services/ProductService';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../utils/helper/AlertService';

export default function UpdateProducts({valueInitial, onClickUpdateProd = () => {}}) {
    const [ loading, setLoading ] = useState(false);
    const [ producto, setProducto ] = useState([]);
    const [ categoria, setCategoria ] = useState([]);
    const [ tipoProducto, setTipoProducto ] = useState([]);
    const [ modelo, setModelo ] = useState([]);
    const [ showCategoria, setShowCategoria ] = useState(false); 
    const [image, setImage] = useState(null);

    const onClickSend = (values,onSubmitProps) => {
        showConfirAlert({
            title: "Desea actualizar Producto",
            text: "Verifique que todos los campos esten registrados correctamente",
            preConfirm: () => {
                actualizarProducto(values).then(() => {
                    showSuccessAlert('Registro Exitoso');
                    onSubmitProps.setSubmitting(false)
                    onSubmitProps.resetForm();
                    onClickUpdateProd();
                }).catch((error) => {
                    showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
                });
            }
        });
    }

    const onSelectTipoProducto = async (item) => {
        if(item !== '2872a996-7da1-40c4-835c-8a6feb98f09c'){
            setShowCategoria(true)
            setLoading(true);
            const data = await getListaCategoria();
            setLoading(false);
            setCategoria(data);
        }
        else{
            setModelo([]);
            setShowCategoria(false);
        }
    }

    const getDataModel = async (categoriaId) => {
        setLoading(true)
        const data = await getListaModelo(categoriaId);
        setLoading(false);
        setModelo(data);
        
    }

    const getData = async (item) => {
        const resp_prod = await getListaProductoTipoCombo(item.tipoProductoCodigo);
        const res_tipo = await getTipoProducto();
        
        if(item.categoriaId){
            setShowCategoria(true);
            const resp_categ = await getListaCategoria();
            const rest_modelo = await getListaModelo(item.categoriaId);
            setModelo(rest_modelo);
            setCategoria(resp_categ);
        }

        setProducto(resp_prod);
        setTipoProducto(res_tipo);
    }

    useEffect(() => {
        getData(valueInitial);
        setImage(valueInitial.urlImagen)
    },[valueInitial])

  return (
    <FormFields
        initialValues = {{
            productoId: valueInitial.id,
            color: valueInitial.color,
            precio: valueInitial.precio ? valueInitial.precio :'',
            descripcion: valueInitial.descripcion,
            tipoProductoId: valueInitial.tipoProductoId,
            modeloId: valueInitial.modeloId,
            categoriaId: valueInitial.categoriaId,
            stock: valueInitial.stock,
            imagen: {
                contenido:"",
                extension:""
            }}
        }
        onSubmit = {(values, onSubmitProps) => onClickSend(values,onSubmitProps)}
        validationSchema = {updateProductSchema}
    >
        <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-8'>
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-6'>
                        <SelectFields
                            label={"Producto"}
                            name="productoId"
                            data={producto.map((item) =>({value:item.productoId, label: item.nombre }))}
                            isLoading = {loading}
                        />
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-6'>
                        <SelectFields
                            label={"Tipo"}
                            name="tipoProductoId"
                            data={tipoProducto.map((item) =>({value:item.id, label: item.nombre }))}
                            onSelect={(item) => onSelectTipoProducto(item.value)}
                            isLoading = {loading}
                        />
                    </div>
                    {
                        showCategoria &&
                        <div className='col-sm-12 col-md-12 col-lg-6'>
                            <SelectFields
                                label={"Categoría"}
                                name="categoriaId"
                                data={categoria.map((item) =>({value:item.id, label: item.nombre }))}
                                onSelect={(item) => getDataModel(item.value)}
                                //isLoading = {loading}
                            />
                        </div>
                    }
                    {
                        modelo.length > 0 && 
                        <div className='col-sm-12 col-md-12 col-lg-6'>
                            <SelectFields
                                label={"Modelo"}
                                name="modeloId"
                                isRequired={false}
                                data={modelo.map((item) =>({value:item.id, label: item.nombre }))}
                                //isLoading = {loading}
                            />
                        </div>
                    }
                    {
                        showCategoria &&
                        <div className='col-sm-12 col-md-12 col-lg-6'>
                            <InputFields
                                label={"Stock"}
                                type="number"
                                name="stock"
                                size = "sm"
                                isRequired = {false}
                            />
                        </div>
                    }
                   
                    <div className='col-sm-12 col-md-12 col-lg-6'>
                        <InputFields
                            label={"Precio Venta"}
                            type="number"
                            name="precio"
                            size = "sm"
                            isRequired = {false}
                        />
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-6'>
                        <InputFields
                            label={"Color"}
                            type="text"
                            name="color"
                            size = "sm"
                            isRequired = {false}
                        />
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-6'>
                        <InputFields
                            label={"Descipción"}
                            type="text"
                            name="descripcion"
                            size = "sm"
                            isRequired = {false}
                        />
                    </div>
                    
                    
                </div>
            </div>
            <div className='col-sm-12 col-md-12 col-lg-4 mt-4'>
                <Card>
                    <Card.Body className='p-3'>
                        <div className='h-75 w-100'>
                            <img src={image} className="img-fluid" alt="" />
                        </div>
                        <div className='pt-4'>
                            <InputFileFields 
                                name={"imagen"}
                                onSelectImage={(url) => setImage(url)}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div className='col-12 mt-2'>
                <ButtonFields/>
                <ButtonResetFields/>
            </div>
        </div>        
    </FormFields>
  )
}
