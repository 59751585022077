import * as Yup from 'yup'

export const createProductSchema = Yup.object().shape({
        nombre: Yup.string()
                .min(2, 'Texto muy corto')
                .required('El nombre es un campo requrido'),
        color: Yup.string()
                .min(3, 'Texto muy corto'),
        descripcion: Yup.string()
                .min(10, 'Texto muy corto'),
        tipoProductoId: Yup.string()
                .required('El tipo de producto es un campo requrido'),
        modeloId: Yup.string()
                .nullable(true),
        categoriaId: Yup.string().when('tipoProductoId',{
                is: (value) => (value !== '2872a996-7da1-40c4-835c-8a6feb98f09c' && value !== '19585307-e94f-468f-b90f-413f7d8b41eb'),
                then: Yup.string().required('La categoria es un campo requerido').nullable(true),
                otherwise: Yup.string().nullable(true),
        }),
        imagen: Yup.object({
                contenido: Yup.string().min(100, 'Texto muy corto'),
                extension: Yup.string().min(2, 'Texto muy corto'),
        })
       
});

export const updateProductSchema = Yup.object().shape({
        productoId: Yup.string()
                .required('El nombre es un campo requrido'),
        color: Yup.string(),
        precio: Yup.number()
                .typeError("No es un número"),
        descripcion: Yup.string()
                .min(10, 'Texto muy corto'),
        tipoProductoId: Yup.string()
                .required('El tipo de producto es un campo requrido'),
        modeloId: Yup.string()
                .nullable(true),
        categoriaId: Yup.string().when('tipoProductoId',{
                is: (value) => value !== '2872a996-7da1-40c4-835c-8a6feb98f09c',
                then: Yup.string().required('La categoria es un campo requerido'),
                otherwise: Yup.string()
        }),
        stock: Yup.number()
                .typeError("No es un número"),
        imagen: Yup.object({
                contenido: Yup.string().min(100, 'Texto muy corto'),
                extension: Yup.string().min(2, 'Texto muy corto'),
        })
       
});
export const addProductSchema = Yup.object().shape({
        productoId: Yup.string()
                .required('El campo es requrido'),
        precio: Yup.number()
                .typeError("No es un número")
                .required('Precio es un campo requrido'),
        stock: Yup.number()  
                .typeError("No es un número")  
                .required('El campo es requrido')
});

export const addProductSalesSchema = Yup.object().shape({
        productoId: Yup.string()
                .required('El campo es requrido'),
        precio: Yup.number()
                .typeError("No es un número")
                .nullable(),
        stock: Yup.number()    
                .typeError("No es un número")
                .required('El campo es requrido'),
        fechaInicio: Yup.date().nullable(),
        fechaFin: Yup.date().nullable()
});

export const ModeloSchema = Yup.object().shape({
        categoriaId: Yup.string(),
        nombre: Yup.string()
                .required('El nombre del Modelo es un campo requrido'),
        id: Yup.string(),
})

export const CategoriaSchema = Yup.object().shape({
        Id: Yup.string(),
        nombre: Yup.string()
                .required('El nombre de categoría es un campo requrido')
})

export const addRegistroPagoSchema = Yup.object().shape({
        fechaPago: Yup.string()
                .required('El campo es requrido'),
        monto: Yup.number()
                .typeError("No es un número")
                .required('Precio es un campo requrido'),
        numeroRecivo: Yup.string(),
        metodoPagoId: Yup.string()
                .required('El campo es requrido')   
});

export const createBitacoraSchema = Yup.object().shape({
        descripcion: Yup.string()
                .required('El campo es requrido'),
        monto: Yup.number()
                .typeError("No es un número")
                .required('El campo requrido')
});

export const createActividadBitacoraSchema = Yup.object().shape({
        ingresoId: Yup.string(),
        motivo: Yup.string()
                .required('El campo es requrido'),
        empleadoId: Yup.string()
                .required('El campo es requrido'),
        monto: Yup.number()
                .typeError("No es un número")
                .required('El campo requrido')
});


