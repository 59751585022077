import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Card, Row } from 'react-bootstrap';
import { createSalesSchema } from '../../utils/validations/ServiceValidation';
import { ButtonFields, FormFields, RadioGroupFields, SelectFields, InputBasic, InputFields, AsyncSelectFields,ModalComponents } from '../../components/Index';
import { getTipoComprobante, getTipoDocumento, getTipoSeguro, getTipoPago, crearVenta } from '../../services/SalesServices';
import { getComboCliente, getComboFallecido, crearCliente, crearFallecido } from '../../services/PersonServices';
import AddProductoComponents from '../products/components/AddProductoComponents';
import AddServicioComponents from '../products/components/AddServicioComponents';
import FormCreatePerson from '../person/components/FormCreatePerson';
import FormCreateDeceased from '../person/components/FormCreateDeceased';
import TablaProductosPedidos from '../products/components/TablaProductosPedidos';
import ItemFile from './components/ItemFile';
import useConverBase64 from '../../hooks/useConverBase64';
import PaymentRates from './components/PaymentRates';
import PaymentActivity from './components/PaymentActivity';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../utils/helper/AlertService';


const MD_PROD = "01";
const MD_SERV = "02";
const newModel = {
  clienteId:'',
  primerNombre:'',
  segundoNombre:'',
  apellidoPaterno:'',
  apellidoMaterno:'',
  nroDoi:'',
  direccion:'',
  telefonoUno:'',
  telefonoDos:'',
  telefonoTres:'',
  email:'',
  sexo:''
}
const newModelFinado = {
  id:'',
  primerNombre: '',
  segundoNombre: '',
  apellidoPaterno: '',
  apellidoMaterno: '',
  nroDoi: '',
  sexo: '',
  direccion: '',
  fechaFallecimiento: ''
};
function CreateSales() {
  const formikRef = useRef(null);
  const navigate = useNavigate();
  const { converBase64 } = useConverBase64();
  const [ comprobante, setComprobante ] = useState([]);
  const [ tipoPago, setTipoPago ] = useState([]);
  const [ producto, setProducto ] = useState([]);
  const [ seguro, setSeguro ] = useState([]);
  const [ modalCliente, setModalCliente ] = useState(false);
  const [ modalFinado, setModalFinado ] = useState(false);
  const [ mdProducto, setMdProducto ] = useState({mdModal: false, cod: "", titulo:""});
  const [ precioCoberturaSeguro, setPrecioCoberturaSeguro ] = useState(0);
  const [ precioBruto, setPrecioBruto ] = useState('');
  const [ showPagos, setShowPagos ] = useState(false);
  const [ tipoSeguro, setTipoSeguro ] = useState(false);
  const [ numComprobante, setNumComprobante ] = useState("");
  const [ documento, setDocumento ] = useState([]);
  const [ mdRegitroPago, setMdRegistroPago ] = useState(false);
  const [ listaPagos, setListaPagos ] = useState([]);
  const [ totalPagoDeuda, setTotalPagoDeuda ] = useState(0);
  const [ montoDeuda, setMontoDeuda ] = useState(0);

  const onClickCreateSales = (values, onSubmitProps) => {
    if(parseFloat(precioBruto) < parseFloat(precioCoberturaSeguro)){
      showErrorAlert('El precio total no puede ser menor que el seguro');
      return;
    }
    if(producto.length < 1) {
      showErrorAlert('No se encuntra nigún producto selecionado');
      return;
    }
    values.detalle = producto;
    values.numComprobante = numComprobante;
    values.documentos = tipoSeguro ? documento: [];
    values.actividaPago = showPagos ? listaPagos: [];
    if(values.tipoPagoId !== "c81c314c-40a5-467c-b8ee-b2b6513b5704"){
      values.deuda = 0;
    }
    crearVenta(values).then((response) => {   
      showSuccessAlert('Registro Exitoso')
      onSubmitProps.setSubmitting(false)
      onSubmitProps.resetForm();
      setProducto([]);
      setListaPagos([]);
      setDocumento([]);
      setNumComprobante("");
      setMontoDeuda(0);
      setShowPagos(false);
      navigate("/services/details-sales/"+response.pedidosId);
    }).catch((error) => {
      showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
    });
  }

  const onClickSendClient = (model, onSubmitProps) => {
    showConfirAlert({
        title: "Desea registrar nuevo cliente",
        text: "Verifique que todos los campos esten registrados correctamente",
        preConfirm: () => {
            crearCliente(model).then(() => {
              showSuccessAlert('Registro Exitoso');
              onSubmitProps.setSubmitting(false)
              onSubmitProps.resetForm();
              setModalCliente(false);
            }).catch((error) => {
                showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
            });
        }
    });
  }

  const onClickSendDeceased = (model, onSubmitProps) => {
    showConfirAlert({
        title: "Desea registrar nuevo fallecido",
        text: "Verifique que todos los campos esten registrados correctamente",
        preConfirm: () => {
          crearFallecido(model).then(() => {
            showSuccessAlert('Registro Exitoso');
            onSubmitProps.setSubmitting(false)
            onSubmitProps.resetForm();
            setModalFinado(false);
          }).catch((error) => {
            showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
          });
        }
    });
  }

  const onAddProducto = (item) => {
    let prod = producto.find(x => x.productoId === item.productoId)
    if(prod){
      showConfirAlert({title: 'No es posible agregar productos iguales.'})
      showErrorAlert('No es posible agregar productos iguales.',)
    }
    else{
      setProducto([...producto,item]);
    }
  }

  const onClickDelete = (id) => {
    showConfirAlert({
      title: '¿Estás seguro de eliminar?',
      text: "¡No padrás revertir esto!",
      preConfirm: (result) => {
        if (result) {
          showSuccessAlert('El registro fue eliminado con éxito')
          let new_productos = producto.filter((item) => item.productoId !== id);
          setProducto(new_productos);
        }
      }
    });
    
  }

  const onClickDeleteActividadPago = (index) => {
    var new_actividad = listaPagos.filter((_, i) => i !== index);
    setListaPagos(new_actividad);
  }

  const onChangePrecioBruto = (value) => {
    if(isNaN(value)){
      return;
    }
    const nuevosProducto = producto.map(objeto => {
      return { ...objeto, precio: '',precioTotal:''};
    });
    setProducto(nuevosProducto);
    if(parseFloat(precioCoberturaSeguro) === 0){
      setPrecioBruto(value);
      formikRef.current.setFieldValue('precioNeto',value);
    }
    else {
      setPrecioBruto(value);
      let precio_neto = parseFloat(value) - parseFloat(precioCoberturaSeguro);
      formikRef.current.setFieldValue('precioNeto',isNaN(precio_neto) ? 0 : precio_neto);
    }
    var igv = parseFloat((value*18)/100).toFixed(2);
    var op = parseFloat(value - igv).toFixed(2);
    formikRef.current.setFieldValue('impuesto',igv);
    formikRef.current.setFieldValue('montoAgravada',op);
  }

  const onClickAddFile = async(data,id) => {
    var model = null;
    if(data !== undefined) {
      model = await converBase64(data);
    }
   
    const newDoc = documento.map(doc => {
      if (doc.id === id) {
        doc.data = model;
        doc.isCargado = Object.keys(model).length ? true: false;
      }
      return doc;  
    });
    setDocumento(newDoc);
  }
  
  const onChangeConverturaSeguro = (value) => {
    if(isNaN(parseFloat(value))){
      value = 0;
    }
    if(parseFloat(precioBruto) > parseFloat(value) && parseFloat(precioBruto) !== 0) {
      let precio_neto = parseFloat(precioBruto) - parseFloat(value)
      formikRef.current.setFieldValue('precioNeto',precio_neto);
    }
    else
    {
      setPrecioCoberturaSeguro(value);
    }
  }

  const onSelectTipoSeguro = (item) => {
    if(item.value === '4dcb41b2-625b-4a05-a45a-3d683b19fd06'){
      setPrecioCoberturaSeguro(0);
      if(!isNaN(parseFloat(precioBruto))){
        formikRef.current.setFieldValue('precioNeto',parseFloat(precioBruto));
      }
      formikRef.current.setFieldValue('coberturaSeguro',0);
      setTipoSeguro(false);
    }
    else {
      setTipoSeguro(true)
    };
  }

  const onSelectTipoPago = (item) => {
    if(item.value === 'c81c314c-40a5-467c-b8ee-b2b6513b5704'){
      setShowPagos(true)
    }else {
      setShowPagos(false);
    }
  }

  const fechDataClient = async (value) => {
    const resp_cliente = await getComboCliente(value);
    return resp_cliente;
  }

  const fechDataDeceased = async (value) => {
    const resp_cliente = await getComboFallecido(value);
    return resp_cliente;
  }
  
  const getData = async () => {
    try {
      const resp_comprobante = await getTipoComprobante();
      let item = resp_comprobante.find(x => x.id === 'd4521a44-ae7e-4a62-8797-b6c52e620488');
      if(item){
        setNumComprobante(item.numComprobante)
      }
      const resp_pago = await getTipoPago();
      const resp_seguro = await getTipoSeguro();
      const resp_tipo_doc = await getTipoDocumento()
      setComprobante(resp_comprobante);
      setTipoPago(resp_pago);
      setSeguro(resp_seguro);
      setDocumento(resp_tipo_doc)
    }catch(e){

    }
    
  }

  useEffect(() => {
    getData();
  },[])

  useEffect(() => {
    let reduce = producto.reduce((acumulador, actual) => acumulador + actual.precioTotal, 0);
    if(reduce > 0) {
      var igv = parseFloat((reduce*18)/100).toFixed(2);
      var op = parseFloat(reduce - igv).toFixed(2);
      setPrecioBruto(reduce);
      formikRef.current.setFieldValue('precioNeto',reduce);
      formikRef.current.setFieldValue('precioBruto',reduce);
      formikRef.current.setFieldValue('impuesto',igv);
      formikRef.current.setFieldValue('montoAgravada',op);
    }
  },[producto]);

  useEffect(() => {
    let reduce = listaPagos.reduce((acumulador, actual) => acumulador + parseFloat(actual.monto), 0);
    let calculo = precioBruto - reduce;
    formikRef.current.setFieldValue('deuda',calculo);
    setMontoDeuda(calculo);
    setTotalPagoDeuda(reduce);
  },[listaPagos, precioBruto])

  return (
    <div>
      <Row>
        <div className='col-12'>
          <Card>
            <Card.Body>
            
              <h4 className="card-title">Registrar nueva Venta</h4>
              <div className='row'>
                
              </div>
              <FormFields
                initialValues = {{
                  numComprobante: '',
                  impuesto: 0,
                  montoAgravada:0,
                  precioBruto: '',
                  descuento: 0,
                  precioNeto: '',
                  deuda: 0,
                  tipoSeguroId: '',
                  coberturaSeguro: 0,
                  tipoPagoId: '',
                  tipoComprobanteId: 'd4521a44-ae7e-4a62-8797-b6c52e620488',
                  clienteId: '',
                  direccion:'',
                  fallecidoId: '',
                  descripcion: '',
                  detalle: [],
                  documentos:[],
                  actividaPago: []
                }}
                  onSubmit = {(values, onSubmitProps) => onClickCreateSales(values, onSubmitProps)}
                  validationSchema = { createSalesSchema }
                  formikRef={formikRef}
                >
                <section className='row'>
                  <div className='col-12'>
                    <h4 className='float-right mr-3 text-info'>N° {numComprobante}</h4>
                  </div>
                  <div className='col-12 mb-2 float-right'>
                    <div className='float-right'>
                      <RadioGroupFields
                        data={comprobante.map(item => ({value:item.id, label: item.nombre, numComprobante: item.numComprobante }))}
                        name="tipoComprobanteId"
                        onSelect={(item) => setNumComprobante(item.numComprobante)}
                      />
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-6 col-lg-6 product-item stretch-card'>
                    <div className="card mb-3">
                      <div className="card-body row">
                        <div className='col-11'>
                          <AsyncSelectFields
                            label={"Finado(a)"}
                            name={"fallecidoId"}
                            loadOptions = {fechDataDeceased}
                            onSelect={(item) => formikRef.current.setFieldValue('direccion',item.direccion)}
                          />
                        </div>
                        <div className='col-1 float-left p-0 pt-4'>
                          <i className="fa fa-plus-square text-info" onClick={()=>setModalFinado(true)}/>
                        </div>
                        <div className='col-11'>
                          <AsyncSelectFields
                            label={"Cliente(a)"}
                            name="clienteId"
                            loadOptions = {fechDataClient}
                          />
                        </div>
                        <div className='col-1 float-left p-0 pt-4'>
                          <i className="fa fa-plus-square text-info" onClick={()=>setModalCliente(true)}/>
                        </div>
                      </div> 
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-6 col-lg-6 product-item stretch-card'>
                    <div className="card mb-3">
                      <div className="card-body row">
                        <div className='col-12'>
                          <InputFields
                            label={"Dirección"}
                            as="textarea" 
                            rows={1}
                            name="direccion"
                            size="sm"
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                          <SelectFields
                            label={"Tipo de Pago"}
                            name="tipoPagoId"
                            data={tipoPago.map(item => ({value:item.id, label: item.nombre,data:item }))}
                            onSelect = {(item) => onSelectTipoPago(item)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                          <SelectFields
                            label={"Tipo Seguro"}
                            name="tipoSeguroId"
                            data={seguro.map(item => ({value:item.id, label: item.nombre }))}
                            onSelect = {(item) => onSelectTipoSeguro(item)}
                          />
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </section>
                
                <div className='row mb-2'>
                  <div className='col-12 '>
                    <button className='btn btn-outline-primary btn-sm float-right' onClick={() => setMdProducto({...mdProducto,mdModal: true, cod: MD_PROD, titulo: "Agregar Producto"})}><i className='fa fa-plus mr-2'/>Productos</button> 
                    <button className='btn btn-outline-primary btn-sm float-right mr-3' onClick={() => setMdProducto({...mdProducto,mdModal: true, cod: MD_SERV, titulo: "Agregar Servicio"})}><i className='fa fa-plus mr-2'/>Servicios</button>
                  </div>
                </div>
                <section className='row'>
                  <div className="container-fluid d-flex justify-content-center w-100">
                    <TablaProductosPedidos 
                      producto={producto}
                      onClickDelete={(id) => onClickDelete(id)}
                    />
                  </div>
                  {
                    producto && producto.length <= 0 && <div className='col-12'><p className='text-danger'>(*) La lista de Productos no tiene que estar vacio.</p></div>
                  }
                  <section className='container-fluid mt-2 w-100'>
                    <article>
                      {
                        tipoSeguro && 
                        <h5 className="text-right text-black-50"> Credito por Seguro :
                          <InputBasic 
                            type="text" 
                            placeholder = "0"  
                            onChangeInput={(value) => onChangeConverturaSeguro(value)}
                            name="coberturaSeguro"/>
                        </h5>
                      }
                    
                      <h5 className="text-right text-black-50">Cancelar en Efectivo : <InputBasic type="text" placeholder = "0" disabled name="precioNeto"/></h5>
                      <h6 className="text-right text-muted">Op. Gravada S/: <InputBasic id = "montoAgravada" placeholder = "0" name="montoAgravada" disabled/></h6>
                      <h6 className="text-right text-muted">IGV 18% S/: <InputBasic id = "impuesto" placeholder = "0" name="impuesto" disabled/></h6>
                      <h4 className="text-right"> Total : 
                        <InputBasic 
                          type="text" 
                          placeholder = "0" 
                          onChangeInput={(value) => onChangePrecioBruto(value)}
                          name="precioBruto" />
                      </h4>
                    </article>
                  </section>
                </section>
                {
                  tipoSeguro &&
                  <section className='row mt-3 mb-3'>
                    <div className='col-12'>
                      <p className='bg-primary m-0 p-1 text-center text-white font-weight-bold'>REQUITOS PARA TRAMITE</p>
                    </div>
                    {
                      documento && documento.map((item,index) => (
                        <div key={index} className='col-sm-12 col-md-6 col-lg-4'>
                          <ItemFile
                            isChecked = {item.isCargado}
                            title={item.nombre} 
                            onChangeFile={(data) => onClickAddFile(data,item.id)}
                          />
                        </div>
                      ))
                    }
                  </section>
                }
                { 
                  showPagos && 
                  <div className='row pt-5 pb-5'>
                    <div className='col-12 pb-2'>
                      <button className='btn btn-inverse-primary btn-icon float-right' onClick={() => setMdRegistroPago(true)}><i className='fa fa-plus'></i></button>
                    </div>
                    <div className='col-12'>
                      <PaymentActivity listaPagos={listaPagos} handleDelete={(index) => onClickDeleteActividadPago(index)}/>
                      <h4 className="text-right mt-3">Deuda Pendiente S/. {montoDeuda}</h4>
                    </div>
                    
                  </div>
                }
                <section className='mb-4'>
                  <InputFields
                    label={"Descripción"}
                    as="textarea" 
                    rows={1}
                    isRequired = {false}
                    name="descripcion"
                    size = "sm"
                  />
                </section>
                <div>
                  <ButtonFields/>
                </div>
              </FormFields>
            </Card.Body>
            
            {/* MODAL CLIENTE */}
            <ModalComponents 
              show={modalCliente} 
              hide={()=>setModalCliente(false)}  
              titulo={"Crear Cliente"} 
            >
              <FormCreatePerson 
                modelCliente={newModel} 
                onClickSend={(values,onSubmitProps) => onClickSendClient(values,onSubmitProps)}
              />
            </ModalComponents>

            {/* MODAL FALLECIDO */}
            <ModalComponents  
              show={modalFinado} 
              hide={()=>setModalFinado(false)}  
              titulo={"Crear Finado"}
            >
              <FormCreateDeceased 
                modelDeceased={newModelFinado}
                onClickSend={(values,onSubmitProps) => onClickSendDeceased(values,onSubmitProps)}
              />
            </ModalComponents>

            {/* MODAL PRODUCTO */}
            <ModalComponents
              show={mdProducto.mdModal}
              hide={() => setMdProducto({...mdProducto, mdModal:false})}
              titulo={"Agregar"}
              size={"md"}
            >
              { mdProducto.cod === MD_PROD && <AddProductoComponents onAddProducto={(item) => onAddProducto(item)} /> }
              { mdProducto.cod === MD_SERV && <AddServicioComponents onAddServicio={(item) => onAddProducto(item)}/>}
            </ModalComponents>

            {/* MODAL ACTIVIDAD PAGO */}
            <ModalComponents
              size={"md"}
              show={mdRegitroPago}
              hide={() => setMdRegistroPago(false)}
              titulo={"Registro de Pagos"}
            >
              <PaymentRates 
                totalPagoDeuda ={totalPagoDeuda} 
                precioTotal={precioBruto} 
                onChangeData={(data) => setListaPagos([...listaPagos,data])}/>
            </ModalComponents>
          </Card>
        </div>
      </Row>
    </div> 
  );
}


export default CreateSales;