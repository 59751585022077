import * as Yup from 'yup'

export const createSalesSchema = Yup.object().shape({
        numComprobante: Yup.string(),
        impuesto: Yup.number(),
        montoAgravada: Yup.number(),
        precioBruto: Yup.number()
                .typeError("No es un número")
                .required("El campo es requerido").moreThan(0),
        descuento: Yup.number(),
        Documentos: Yup.array(),
        precioNeto: Yup.number(),
        tipoSeguroId: Yup.string()
                .required("El campo es requerido"),
        direccion: Yup.string()
                .required("El campo es requerido"),
        coberturaSeguro: Yup.number().when('tipoSeguroId',{
                is:(value) => value !== '4dcb41b2-625b-4a05-a45a-3d683b19fd06',
                then: Yup.number().required("El compo monto es requerido").moreThan(0),
                otherwise: Yup.number()
        }), 
        tipoPagoId: Yup.string()
                .required('El campo es requrido'),
        tipoComprobanteId: Yup.string()
                .required('El campo es requrido'),
        clienteId: Yup.string()
                .required('El campo es requrido'),
        fallecidoId: Yup.string()
                .required('El campo es requerido'),
        detalle: Yup.array(),
        actividaPago: Yup.array(),
        descripcion: Yup.string(),

});

export const editSalesSchema = Yup.object().shape({
        numComprobante: Yup.string()
                .required('El campo es requrido'),
});

export const avanceEstadoSchema = Yup.object().shape({
        pedidoId: Yup.string(),
        estadoId: Yup.string()
                .required("El campo es requerido"),
        comentario: Yup.string(),
});

