import React, { useEffect, useState } from 'react';
import { Card, Row } from "react-bootstrap";
import { Search, Table, FormFields, InputFields, SelectFields, ButtonFields, ButtonResetFields, ModalComponents } from '../../components/Index';
import { createActividadBitacoraSchema } from '../../utils/validations/ProductValidation';
import { getComboEmpleado } from '../../services/PersonServices';
import { getListaIngresos, crearIngresos, actualizarIngreso } from '../../services/ExpensesService';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../utils/helper/AlertService';
import UserPermissionsComponent from '../auth/UserPermissionsComponent';

const search = {
    skip: 1,
    take: 10,
    search: "",
    empleadoId: null,
    fecha: ""
}

export default function ListIncome() {
    const modelInicial = {
        ingresoId: '',
        empleadoId: '',
        monto: '',
        motivo: ''
    }
    const [ modelIngreso, setModelIngreso ] = useState(modelInicial);
    const [ mdAgregar,setMdAgregar ] = useState(false);
    const [ empleado,setEmpleado ] = useState([]);
    const [ paramSearch, setParamSearch ] = useState(search);
    const [ listIngreso, setListIngreso ] = useState([]);
    const [ ingresoPag, setIngresoPag ] = useState({});
    const [ isActualizar, setIsActualizar ] = useState(false);

    const onClickActualizar = (values,onSubmitProps) => {
        showConfirAlert({
            title: '¿Estás seguro de actualizar ingreso?',
            text: "¡No padrás revertir esto!",
            preConfirm: () => {
                actualizarIngreso(values).then(() => {
                    showSuccessAlert('!El registro fue eliminado con éxito!');
                    fechData(paramSearch)
                    setMdAgregar(false);
                    onSubmitProps.resetForm();
                }).catch((error) => {
                    showErrorAlert('Error !', 'Error, No se logró eliminar el registro.');
                });
            }
        });
    }

    const onClickCrear = (values,onSubmitProps) => {
        crearIngresos(values).then((res) => {
            setMdAgregar(false)
            showSuccessAlert('!Registro Exitoso!');
            fechData(paramSearch);
            onSubmitProps.resetForm();
        }).catch((e) => {
            showErrorAlert('Error !',  'Error, No se logró realizar el registo.');
        });
    }

    const onShowEditar = (item) => {
        setIsActualizar(true);
        setModelIngreso(item);
        setMdAgregar(true);
    }

    const handleTableChange = (page, sizePerPage) => {
        setParamSearch({
            ...paramSearch,
            skip:sizePerPage.page,
            take:sizePerPage.sizePerPage,
        })
    }

    const onInputSearch = ({target}) => {
        const { value } = target;
        setParamSearch({...paramSearch,search:value})
    }

    const fechData = async (param) => {
        const data = await getListaIngresos(param);
        setListIngreso(data.items);
        setIngresoPag(data);
    }

    const getData = async () => {
        try {
            const data = await getComboEmpleado("");
            setEmpleado(data); 
        }
        catch(e){

        }
    }

    useEffect(() => {
        getData();
    },[])

    useEffect(() =>{
        fechData(paramSearch);
    }, [paramSearch])

    const columns = [
        { 
            dataField: "motivo", 
            text: "Motivo"
        },
        { 
            dataField: "empleado", 
            text: "Empleado"
        },
        {
            dataField: "monto",
            text: "Monto"
        },
        { 
            dataField: "fecha", 
            text: "Fecha" 
        }, 
        {
            dataField: "opciones", 
            text: "Opciones",
            sort: false,
            align: 'center',
            classes: 'py-1',
            formatter: (cellContent, row) => {
            return (
                <div>
                    <UserPermissionsComponent accion={['admin','admVenta']}>
                        <i className="ti-pencil-alt text-primary mr-2" data-toggle="tooltip" data-placement="right" title="Editar" onClick={() => onShowEditar(row)} style={{fontSize:'15px'}}/>
                    </UserPermissionsComponent>
                </div>
            );
            }
        }
    ];
  return (
    <div>
        <Row >
            <div className="col-12">
                <Card>  
                    <Card.Body>
                        <div className="row mb-4 justify-content-between">
                            <div className = "col-lg-3 d-none d-sm-none d-md-block">
                                <h4 className="card-title float-left mt-2">Lista Ingresos</h4>
                            </div>
                            <div className = "col-ms-12 col-md-6 col-lg-6  mb-2">
                                <Search
                                    onSearchInput={onInputSearch}
                                    value={paramSearch.search}
                                    onRefesh={() => setParamSearch(search)}
                                >
                                    <FormFields
                                        initialValues={paramSearch}
                                        onSubmit={(values) => setParamSearch(values)}
                                    >
                                        <div className='row'>
                                            <div className='col-ms-12 col-md-6 col-lg-6'>
                                                <SelectFields 
                                                    label={"Empleado"}
                                                    name={"empleadoId"}
                                                    isRequired = {false}
                                                    data={empleado.map((item) =>({value:item.empleadoId, label: item.nombre }))}
                                                />
                                            </div>
                                            <div className='col-ms-12 col-md-6 col-lg-6'>
                                                <InputFields
                                                    label={"Fecha Entrega"}
                                                    type="date"
                                                    name="fecha"
                                                    size = "sm"
                                                />
                                            </div>
                                            <div className='col-12'>
                                                <ButtonFields title='Buscar'/>
                                                <ButtonResetFields title='Limpiar' onClickCancel={() => setParamSearch(search)}/>
                                            </div>
                                        </div>
                                    </FormFields>
                                </Search>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                                <UserPermissionsComponent accion={['admin','admVenta']}>
                                    <button className="btn btn-outline-primary float-right" onClick={() => {setMdAgregar(true); setIsActualizar(false); setModelIngreso(modelInicial);}}>
                                        <i className="fa fa-plus mr-2"/>
                                        Nuevo Ingreso
                                    </button>
                                </UserPermissionsComponent>
                            </div>
                        </div> 
                    
                        <Table
                            columns={columns}
                            list={listIngreso}
                            keyField='ingresoId'
                            page={ingresoPag.page}
                            sizePerPage={paramSearch.take}
                            totalSize={ingresoPag.total}
                            onTableChange={handleTableChange}
                        />
                        
                        <ModalComponents
                            titulo={isActualizar ? "Actualizar Ingreso":"Agregar Ingreso"}
                            show={mdAgregar}
                            hide={() => setMdAgregar(false)}
                            size='md'
                        >
                            <FormFields
                                initialValues = {modelIngreso}
                                onSubmit = {(values, onSubmitProps) => isActualizar ? onClickActualizar(values,onSubmitProps):onClickCrear(values,onSubmitProps)}
                                validationSchema = {createActividadBitacoraSchema}
                            >
                            <div className='row'>
                                <div className='col-12'>
                                    <InputFields
                                        label={"Motivo"}
                                        type="text"
                                        name="motivo"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-sm-8 col-md-7 col-lg-7'>
                                    <SelectFields 
                                        label={"Empleado"}
                                        name={"empleadoId"}
                                        data={empleado.map((item) =>({value:item.empleadoId, label: item.nombre }))}
                                    />
                                </div>
                                <div className='col-sm-4 col-md-5 col-lg-5'>
                                    <InputFields
                                        label={"Monto"}
                                        type="text"
                                        name="monto"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-12'>
                                    <ButtonFields title='Agregar'/>
                                </div>
                            </div>
                            </FormFields>
                        </ModalComponents>
                    </Card.Body>
                </Card>
            </div>
        </Row>
    </div>
  )
}
