import React from 'react';
import { useFormikContext } from 'formik';

function ButtonFields({title = "Guardar", className = "btn btn-primary float-right ml-3 "}) {
    const {handleSubmit} = useFormikContext();
    return (
        <button
            type="submit"
            onClick={handleSubmit}
            className={className}
            >
                {title}
        </button>
    );
}

export default ButtonFields;