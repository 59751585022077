import React, { useEffect, useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import { Loading, Table, ModalComponents, Search, FormFields, AsyncSelectFields, InputFields, ButtonFields, ButtonResetFields } from '../../../components/Index';
import { getListaCliente, crearCliente, actualizarCliente, getComboCliente, deletePersona } from '../../../services/PersonServices';
import FormCreatePerson from '../components/FormCreatePerson';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../../utils/helper/AlertService';
import UserPermissionsComponent from '../../auth/UserPermissionsComponent';

const CREAR = 1;
const EDITAR = 2;
const search = {
    skip: 1,
    take: 10,
    search: "",
    clienteId: "",
    nroDoi: ""
}
function ListPersons() {
    const newModel = {
        clienteId:'',
        primerNombre:'',
        segundoNombre:'',
        apellidoPaterno:'',
        apellidoMaterno:'',
        nroDoi:'',
        direccion:'',
        telefonoUno:'',
        telefonoDos:'',
        telefonoTres:'',
        email:'',
        sexo:''
    }

    const [ listaPersona, setListaPersona ] = useState([])
    const [ loading, setLoading ] = useState([]);
    const [ modelClient, setModelClient ] = useState(newModel);
    const datos_modal = { mdModal: false, titulo: "", cod: 0 }
    const [ infModal, setInfModal ] = useState(datos_modal)
    const [ personaPag, setPersonaPag ] = useState({});
    const [ paramSearch, setParamSearch ] = useState(search);

    const onClickSendClient = (model, onSubmitProps) => {
        var data = "";
        
        showConfirAlert({
            title: infModal.cod === CREAR? "Desea registrar nuevo cliente" : "Desea actualizar cliente",
            text: "Verifique que todos los campos esten registrados correctamente",
            preConfirm: () => {
                if(infModal.cod === CREAR){
                    data = crearCliente(model);
                } else {
                    data = actualizarCliente(model);
                }

                data.then(() => {
                    showSuccessAlert('Registro Exitoso');
                    onSubmitProps.setSubmitting(false)
                    onSubmitProps.resetForm();
                    setInfModal(datos_modal)
                    getData(paramSearch);
                }).catch((error) => {
                    showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
                });
            }
        });
    }

    const onDeletePersona = (id) => {
        showConfirAlert({
            title: '¿Estás seguro de eliminar el Cliente?',
            text: "¡No padrás revertir esto!",
            preConfirm: () => {
                deletePersona(id).then(() => {
                    showSuccessAlert("Eliminación exitosa");
                    getData(paramSearch);
                }).catch((error) =>{
                    showErrorAlert("Error, vuelva intentarlo")
                })
            }
        });
    }

    const onInputSearch = ({target}) => {
        const { value } = target;
        setParamSearch({...paramSearch,search:value})
    }

    const onClickEditar = (item) => {
        setModelClient(item)
        setInfModal({...infModal,mdModal: true,titulo:"Editar Cliente", cod: EDITAR})
    }
    
    const handleTableChange = (page, sizePerPage) => {
        setParamSearch({
            ...paramSearch,
            skip:sizePerPage.page,
            take:sizePerPage.sizePerPage,
        })
    }
    const fechDataClient = async (value) => {
        const resp_cliente = await getComboCliente(value);
        return resp_cliente;
    }
    const getData = async (param) => {
        try{
            setLoading(true)
            const data = await getListaCliente(param);
            setLoading(false);
            setPersonaPag(data);
            setListaPersona(data.items);
        }catch(e){
            showErrorAlert('Error !',  e.apiMessage || 'Error desconocido');
        }
        
    }

    useEffect(() => {
        getData(paramSearch);
    },[paramSearch])

    const columns = [
        { 
            dataField: "nombreCompleto", 
            text: "Nombre"
        },
        { 
            dataField: "direccion", 
            text: "Dirección"
        },
		{ 
            dataField: "nroDoi", 
            text: "N° DNI" 
        },
        {   
            dataField: "telefonoUno",     
            text: "Celular 1"
        },
        { 
            dataField: "telefonoDos", 
            text: "Celular 2",
        },
        {
            dataField: "opciones", 
            text: "Opciones",
            sort: false,
            align: 'center',
            classes: 'py-1',
            formatter: (_, row) => {
            return (
                <div>
                    <UserPermissionsComponent accion={['admin','editarCliente']}>
                        <i className="ti-pencil-alt text-primary mr-2" data-toggle="tooltip" data-placement="right" title="Editar" onClick={() => onClickEditar(row)} style={{fontSize:'15px'}}/>
                    </UserPermissionsComponent>
                    <UserPermissionsComponent accion={['admin']}>
                        <i className="ti-trash text-danger" data-toggle="tooltip" data-placement="right" title="Eliminar" style={{fontSize:'15px'}} onClick={() => onDeletePersona(row.id)}/>
                    </UserPermissionsComponent> 
                </div>
            );
            }
        }
          
    ]
    
    return (
        <div>
            <Row >
                <div className="col-12">
                    <Card>  
                        <Card.Body>
                            <div className="row mb-4 justify-content-between">
                                <div className = "col-lg-2 d-none d-sm-none d-md-block">
                                    <h4 className="card-title float-left mt-2">Lista Clientes</h4>
                                </div>
                                <div className = "col-lg-6 col-ms-12 pb-2">
                                    <Search
                                        onSearchInput={onInputSearch}
                                        value={paramSearch.search}
                                        onRefesh={() => setParamSearch(search)}
                                    >
                                        <FormFields
                                            initialValues={paramSearch}
                                            onSubmit={(values) => setParamSearch(values)}
                                        >
                                            <div className='row'>
                                                <div className='col-ms-12 col-md-6 col-lg-6'>
                                                    <AsyncSelectFields
                                                        label={"Cliente(a)"}
                                                        isRequired = {false}
                                                        name={"clienteId"}
                                                        loadOptions = {fechDataClient}
                                                    />
                                                </div>
                                                <div className='col-ms-12 col-md-6 col-lg-6'>
                                                    <InputFields
                                                        label={"N° DNI"}
                                                        type="text"
                                                        name="nroDoi"
                                                        size = "sm"
                                                        isRequired = {false}
                                                    />
                                                </div>
                                                <div className='col-12'>
                                                    <ButtonFields title='Buscar'/>
                                                    <ButtonResetFields title='Limpiar' onClickCancel={() => setParamSearch(search)}/>
                                                </div>
                                            </div>
                                        </FormFields>
                                    </Search>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <button className="btn btn-outline-primary float-right" onClick={() => { setInfModal({...infModal,mdModal: true,titulo:"Agregar Cliente", cod: CREAR}); setModelClient(newModel)}}>
                                        <i className="fa fa-plus mr-2"/>
                                        Nuevo Cliente
                                    </button>
                                </div>
                            </div>    
                            <Table
                                columns={columns}
                                list={listaPersona}
                                page={personaPag.page}
                                sizePerPage={paramSearch.take}
                                totalSize={personaPag.total}
                                onTableChange={handleTableChange}
                            />
                            <ModalComponents
                                titulo={infModal.titulo}
                                show = {infModal.mdModal}
                                hide={() => setInfModal(datos_modal)}
                            >
                                <FormCreatePerson 
                                    modelCliente={modelClient} 
                                    onClickSend={(values,onSubmitProps) => onClickSendClient(values,onSubmitProps)}/>
                            </ModalComponents>
                            <Loading isLoading = {loading}/>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
        </div>
    );
}

export default ListPersons;