import React, { Fragment, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Row, Card } from 'react-bootstrap';
import { Search, ModalComponents, Loading, Button, Table, FormFields, ButtonFields, ButtonResetFields, InputFields } from '../../components/Index';
import UserPermissionsComponent from '../auth/UserPermissionsComponent';
import { getCaculoCierreCaja, crearCierreCaja, getListarCierreCaja, actualizarEstadoCaja } from '../../services/ExpensesService';
import { showConfirAlert, showErrorAlert, showSuccessAlert } from '../../utils/helper/AlertService';
import { format } from 'date-fns';

const search = {
    skip: 1,
    take: 10,
    search: "",
    fechaInicio:"",
    fechaFin: ""
}

export default function CierreCaja() {
    const [ mdShow, setMdShow ] = useState(false);
    const [ datosCaja, setDatosCaja ] = useState({});
    const [ detalleMP, setDetalleMP ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ cajaPag, setCajaPag ] = useState({});
    const [ ListaCaja, SetListaCaja ] = useState([]);
    const [ paramSearch, setParamSearch ] = useState(search);
    const [ isVibleButton, setIsVisibleButton ] = useState(false);

    const onCreateCierre = (model) => {
        showConfirAlert({
            title: "Desea confirmar el cierre de caja",
            text: "Verifique todos los montos antes de confirmar, una vez confirmada no se podrá revertir",
            preConfirm: () => {
                const fecha = format(new Date(), 'yyyy-MM-dd\'T\'HH:mm');
                model.fechaCierre = fecha;
                crearCierreCaja(model).then(() => {
                  showSuccessAlert('Registro Exitoso');
                  setMdShow(false);
                  getData(paramSearch);
                }).catch((error) => {
                    showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
                });
            }
        });
    }

    const onClickGenerarCierre = () => {
        setMdShow(true);
        setIsVisibleButton(true);
        fechaCierreCaja();
    }

    const fechaCierreCaja = async () => {
        try{
            setIsLoading(true);
            var caja = await getCaculoCierreCaja();
            setDetalleMP(caja.cajaMetodoPagos);
            setIsLoading(false);
            setDatosCaja(caja);
        }
        catch(e){
            showErrorAlert('Hubo un problema de registro');
        }
       
    }

    const onClickDetalle = (item) => {
        setDatosCaja(item)
        setIsVisibleButton(false);
        setDetalleMP(item.cajaMetodoPagos);
        setMdShow(true);
    }

    const onInputSearch = ({target}) => {
        const { value } = target;
        setParamSearch({...paramSearch, search: value})
    }

    const handleTableChange = (page, sizePerPage) => {
        setParamSearch({
            ...paramSearch,
            skip:sizePerPage.page,
            take:sizePerPage.sizePerPage,
        })
    }

    const onClickAprobar = async (item) => {
        var value = {
            CajaId: item.cajaId,
            Estado: "02"
        }

        showConfirAlert({
            title: "Desea aprobar cierre de Caja",
            text: "Verifique todos los montos antes de confirmar, una vez confirmada no se podrá revertir",
            preConfirm: () => {
                actualizarEstadoCaja(value).then(() => {
                  showSuccessAlert('Registro Exitoso');
                  getData(paramSearch);
                  setMdShow(false);
                }).catch((error) => {
                    showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
                });
            }
        });
    }

    const getData = async (param) => {
        setIsLoading(true);
        var data = await getListarCierreCaja(param);
        setIsLoading(false);
        setCajaPag(data);
        SetListaCaja(data.items);
    }

    useEffect(() => {
        getData(paramSearch);
    },[paramSearch])

    const columns = [
        { 
            dataField: "empleado", 
            text: "Empleado"
        },
        { 
            dataField: "totalAbonado", 
            text: "Total Abonado"
        },
		{ 
            dataField: "totalIngresos", 
            text: "Total Ingreso" 
        },
        { 
            dataField: "totalEgresos", 
            text: "Total Egresos" 
        },
        { 
            dataField: "totalCompras", 
            text: "Total Compras"
        },
        { 
            dataField: "totalCaja", 
            text: "Monto Caja"
        },
        { 
            dataField: "fechaCierre", 
            text: "Fecha Cierre"
        },
        { 
            dataField: "estado", 
            text: "Estado",
            classes: 'py-1',
            formatter: (cellContent, row) => {
                if (cellContent === '01') {
                    return (
                    <label className="badge badge-warning m-0">Vigente</label>
                    );
                } else if (cellContent === '02' ) {
                    return (
                    <label className="badge badge-success m-0">Aprobado</label>
                    );
                }
            }
        },
        {
            dataField: "opciones", 
            text: "Opciones",
            sort: false,
            align: 'center',
            classes: 'py-1',
            formatter: (_ , row) => {
            return (
                <div>
                    <i className="btn ti-eye text-primary p-1" data-toggle="tooltip" data-placement="right" title="Detalle" style={{fontSize:'15px'}} onClick={() => onClickDetalle(row)}/>
                    <UserPermissionsComponent accion={['admin']}>
                        <i className="btn ti-check-box text-success p-1" data-toggle="tooltip" data-placement="right" title="Aprobar" style={{fontSize:'15px'}} onClick={() => onClickAprobar(row)}/>
                    </UserPermissionsComponent>
                    <UserPermissionsComponent accion={['admin','admVenta']}>
                        <Link className='text-decoration-none' to={"/caja/detail/"+row.cajaId}><i className="btn ti-menu text-info p-1" data-toggle="tooltip" data-placement="right" title="Aprobar" style={{fontSize:'15px'}}/></Link>
                    </UserPermissionsComponent>
                </div>
            );
            }
        }
    ]
  return (
        <Row>
            <div className="col-12">
                <Card>  
                    <Card.Body>
                    <div className="row mb-4 justify-content-between">
                            <div className = "col-lg-3 d-none d-sm-none d-md-block">
                                <h4 className="card-title float-left mt-2">Lista Egresos Diarios</h4>
                            </div>
                            <div className = "col-ms-12 col-md-6 col-lg-6  mb-2">
                                <Search
                                    onSearchInput={onInputSearch}
                                    value={paramSearch.search}
                                    onRefesh={() => setParamSearch(search)}
                                >
                                    <FormFields
                                        initialValues={paramSearch}
                                        onSubmit={(values) => setParamSearch(values)}
                                    >
                                        <div className='row'>
                                            <div className='col-ms-12 col-md-6 col-lg-6'>
                                                <InputFields
                                                    label={"Desde"}
                                                    type="date"
                                                    isRequired={false}
                                                    name="fechaInicio"
                                                    size = "sm"
                                                />
                                            </div>
                                            <div className='col-ms-12 col-md-6 col-lg-6'>
                                                <InputFields
                                                    label={"Hasta"}
                                                    type="date"
                                                    isRequired={false}
                                                    name="fechaFin"
                                                    size = "sm"
                                                />
                                            </div>
                                            <div className='col-12'>
                                                <ButtonFields title='Buscar'/>
                                                <ButtonResetFields title='Limpiar' onClickCancel={() => setParamSearch(search)}/>
                                            </div>
                                        </div>
                                    </FormFields>
                                </Search>
                            </div>

                            <div className="col-sm-12 col-md-3 col-lg-3">
                                <UserPermissionsComponent accion={['admin','admVenta']}>
                                    <button className="btn btn-outline-primary float-right" onClick={() => onClickGenerarCierre()}>
                                        <i className="fa fa-plus mr-2"/>
                                        Generar Cierre
                                    </button>
                                </UserPermissionsComponent>
                            </div>
                        </div> 
                        <Table
                            keyField='cajaId'
                            columns={columns}
                            list={ListaCaja}
                            page={cajaPag.page}
                            sizePerPage={paramSearch.take}
                            totalSize={cajaPag.total}
                            onTableChange={handleTableChange}
                        />
                        <ModalComponents
                            titulo={"Detalle Caja"}
                            show={mdShow}
                            size='lg'
                            hide={() => setMdShow(false)}
                        >
                            <Row>
                                <div className='col-sm-12 col-md-6 col-lg-8'>
                                    <Row>
                                        <div className='col-sm-12 col-md-6 col-lg-6'>
                                            <Card className='mb-3 p-0'>
                                                <Card.Header className='p-2'>
                                                    <p className='card-title m-0'>Abonados</p>
                                                </Card.Header>
                                                <Card.Body className='p-2'>
                                                    <Row>
                                                        <div  className='col-8'>
                                                            <p>Abonodo Ventas</p>
                                                        </div>
                                                        <div className='col-4'>
                                                            <p className= 'text-right'>S/.{datosCaja.abonadoVentas}</p>
                                                        </div>
                                                        <div  className='col-8'>
                                                            <p>Abonado Alquiler</p>
                                                        </div>
                                                        <div className='col-4'>
                                                            <p className= 'text-right'>S/.{datosCaja.abonadoAlquiler}</p>
                                                        </div>
                                                        <div  className='col-8'>
                                                            <p className='font-weight-bold text-primary'>Total</p>
                                                        </div>
                                                        <div className='col-4'>
                                                            <p className= 'text-right font-weight-bold text-primary'>S/.{datosCaja.totalAbonado}</p>
                                                        </div>
                                                        
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-6'>
                                            <Card className='mb-3'>
                                                <Card.Header className='p-2'>
                                                    <p className='card-title m-0'>Detalle Abonados</p>
                                                </Card.Header>
                                                <Card.Body className='p-2'>
                                                    <Row>
                                                        {
                                                            detalleMP.map((item,index) => (
                                                                <Fragment key={index}>
                                                                    <div className='col-8'>
                                                                        <p>{item.nombre}</p>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <p className= 'text-right'>S/.{item.monto}</p>
                                                                    </div>
                                                                </Fragment>
                                                            ))
                                                        }
                                                        
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-6'>
                                            <Card className='mb-3'>
                                                <Card.Header className='p-2'>
                                                    <p className='card-title m-0'>Ingreso</p>
                                                </Card.Header>
                                                <Card.Body className='p-2'>
                                                    <Row>
                                                        <div  className='col-8'>
                                                            <p>Total Ingresos</p>
                                                        </div>
                                                        <div className='col-4'>
                                                            <p className= 'text-right'>S/.{datosCaja.totalIngresos}</p>
                                                        </div>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-6'>
                                            <Card className='mb-3'>
                                                <Card.Header className='p-2'>
                                                    <p className='card-title m-0'>Egresos</p>
                                                </Card.Header>
                                                <Card.Body className='p-2'>
                                                    <Row>
                                                        <div  className='col-8'>
                                                            <p>Total Egresos</p>
                                                        </div>
                                                        <div className='col-4'>
                                                            <p className= 'text-right'>S/.{datosCaja.totalEgresos}</p>
                                                        </div>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-6'>
                                            <Card className='mb-3'>
                                                <Card.Header className='p-2'>
                                                    <p className='card-title m-0'>Compras</p>
                                                </Card.Header>
                                                <Card.Body className='p-2'>
                                                    <Row>
                                                        <div  className='col-8'>
                                                            <p>Total Compras</p>
                                                        </div>
                                                        <div className='col-4'>
                                                            <p className= 'text-right'>S/.{datosCaja.totalCompras}</p>
                                                        </div>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Row>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <Card>
                                        <Card.Header className='p-2 bg-info'>
                                            <p className='card-title m-0 text-white'>Dinero en Caja</p>
                                        </Card.Header>
                                        <Card.Body className='p-2'>
                                            <Row>
                                                <div  className='col-8'>
                                                    <p>[+] Total Abonado</p>
                                                </div>
                                                <div className='col-4'>
                                                    <p className= 'text-right'>S/.{datosCaja.totalAbonado}</p>
                                                </div>
                                                <div  className='col-8'>
                                                    <p>[+] Total Ingreso</p>
                                                </div>
                                                <div className='col-4'>
                                                    <p className= 'text-right'>S/.{datosCaja.totalIngresos}</p>
                                                </div>
                                                <div  className='col-8'>
                                                    <p>[-] Total Egreso</p>
                                                </div>
                                                <div className='col-4'>
                                                    <p className= 'text-right'>S/.{datosCaja.totalEgresos}</p>
                                                </div>
                                                <div  className='col-8'>
                                                    <p className='font-weight-bold'>Total Caja</p>
                                                </div>
                                                <div className='col-4'>
                                                    <p className='text-right font-weight-bold bg-warning'>S/.{datosCaja.totalCaja}</p>
                                                </div>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </div>
                                {
                                    (isVibleButton &&  parseFloat(datosCaja.totalCaja)) > 0 &&(
                                        <div className='col-12'>
                                            <Button className='float-right' label='Generar Cierre' onClick={() => onCreateCierre(datosCaja)}/>
                                        </div>
                                    )
                                }
                                
                            </Row>
                            <Loading isLoading = {isLoading}/>
                        </ModalComponents>
                    </Card.Body>
                </Card>
            </div>
        </Row>
  )
}
