import React, { useState } from 'react'
import { InputGroup, FormControl, Button } from 'react-bootstrap'

export default function SearchComponents({ children, onSearchInput,onRefesh, isSearchDrowp = true, nameInput = "Buscar" }) {
  const [show,setShow] = useState(true);

  const onClickShow = () => {
    setShow(!show)
  }
  return (
    <div className='container p-0'>
      <InputGroup className="mb-0">
        <InputGroup.Prepend>
            <Button variant="outline-secondary" onClick={onRefesh}><i className='ti ti-reload'/></Button>
        </InputGroup.Prepend>
        <FormControl className={ isSearchDrowp && "border-right-0"}
            placeholder = {nameInput}
            onChange = {onSearchInput} 
            name="search"  
        />
        {
          isSearchDrowp && 
          <InputGroup.Append>
            <InputGroup.Text className= "bg-transparent p-0">
              <button className = "p-0 bg-transparent border-0 mr-3" onClick={onClickShow}>
                <i className={show ? "fa fa-angle-down icon-md":"fa fa-angle-up icon-md"}></i>
              </button>
            </InputGroup.Text>
          </InputGroup.Append>
        }
        
      </InputGroup>
      {
        show ? (" ") : (
          <div className="container position-relative">
            <div className="row bg-primary" >
              <div className="card shadow border-0 mt-1 position-absolute w-100" style={{zIndex: 3}} >
                <div className="card-body">
                    { children }
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}
