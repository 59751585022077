import * as Yup from 'yup'

export const createRentalSchema = Yup.object().shape({
        numComprobante: Yup.string(),
        impuesto: Yup.number(),
        montoAgravada: Yup.number(),
        precioBruto: Yup.number(),
        descuento: Yup.number(),
        precioNeto: Yup.number()
                .typeError("No es un número")
                .required("El campo es requerido").moreThan(0),
        direccion: Yup.string()
                .required("El campo es requerido"),
        tipoPagoId: Yup.string()
                .required('El campo es requrido'),
        tipoComprobanteId: Yup.string()
                .required('El campo es requrido'),
        clienteId: Yup.string()
                .required('El campo es requrido'),
        detalle: Yup.array(),
        actividaPago: Yup.array(),
        descripcion: Yup.string(),
     
});