import React from 'react';

export function LabelFields({label,isRequired = true}) {
    return (
        <p className="mb-0" style={{color:'#76838f', fontWeight:300, fontSize:'.800rem'}}>
            {label}
            <label className="ml-1 text-danger" style={{marginBottom:'0.2rem'}}>
                {
                    isRequired ? <>*</>: <label style={{marginBottom:'0.2rem'}}></label>
                }
                
            </label>
        </p> 
    );
}