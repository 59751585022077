import React, { useEffect, useState } from 'react';
import { Row, Col, Nav, Tab  } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getDetalleCierreCaja } from '../../services/ExpensesService';
import BasicTableComponent from '../../components/BasicTableComponent';
import { useParams } from "react-router-dom";

export default function DetailCaja() {
  let { cajaId } = useParams();
  const [ detalleCajaCompra, setDetalleCajaCompra ] = useState([]);
  const [ detalleCajaPedido, setDetalleCajaPedido ] = useState([])
  const [ detalleCajaIngreso, setDetalleCajaIngreso ] = useState([])
  const [ detalleCajaEgreso, setDetalleCajaEgreso ] = useState([])

  const getData = async (id) => {
    const data = await getDetalleCierreCaja(id)
    setDetalleCajaCompra(data.detalleCajaCompras);
    setDetalleCajaPedido(data.detalleCajaPedidos);
    setDetalleCajaIngreso(data.detalleCajaIngresos);
    setDetalleCajaEgreso(data.detalleCajaEgresos);
  }

  const columnPedido = [
    { 
      dataField: "numeroContrato", 
      text: "NRO Contrata"
    },
    { 
        dataField: "fechaContrato", 
        text: "Fecha"
    },
    { 
        dataField: "tipoServicio", 
        text: "Tipo Servicio"
    },
    { 
      dataField: "cliente", 
      text: "Cliente"
    },
    { 
      dataField: "finado", 
      text: "Finado"
    },
    { 
      dataField: "monto", 
      text: "Pago",
      formatter: (_, row) => (<p className="text-primary m-0">{parseFloat(row.monto).toFixed(2)}</p>)
    },
    { 
      dataField: "empleado", 
      text: "Usuario"
    },
    {
        dataField: "opciones", 
        text: "Opciones",
        sort: false,
        align: 'center',
        classes: 'py-1',
        style: {width: '15%'},
        formatter: (_, row) => {
        return (
          <Link className='text-decoration-none' to={"/services/details-sales/"+row.pedidoId}><i className="ti-eye text-primary mr-2" data-toggle="tooltip" data-placement="right" title="Detalle" style={{fontSize:'15px'}}/></Link>
        );
        }
    }
  ]

  const columnCompra = [
    { 
        dataField: "fechaCompra", 
        text: "Fecha"
    },
    { 
      dataField: "proveedor", 
      text: "Proveedor"
    },
    { 
      dataField: "monto", 
      text: "Pago",
      formatter: (_, row) => (<p className="text-primary m-0">{parseFloat(row.monto).toFixed(2)}</p>)
    },
    { 
      dataField: "empleado", 
      text: "Usuario"
    },
    {
        dataField: "opciones", 
        text: "Opciones",
        sort: false,
        align: 'center',
        classes: 'py-1',
        style: {width: '15%'},
        formatter: (_, row) => {
        return (
          <Link className='text-decoration-none' to={"/shopping/detail/"+row.comprasId}><i className="ti-eye text-primary mr-2" data-toggle="tooltip" data-placement="right" title="Detalle" style={{fontSize:'15px'}}/></Link>

        );
        }
    }
  ]

  const columnIngreso = [
    { 
        dataField: "fechaIngreso", 
        text: "Fecha"
    },
    { 
        dataField: "motivo", 
        text: "Motivo"
    },
    { 
      dataField: "monto", 
      text: "Pago",
      formatter: (_, row) => (<p className="text-primary m-0">{parseFloat(row.monto).toFixed(2)}</p>)
    },
    { 
      dataField: "empleado", 
      text: "Usuario"
    }
  ]

  const columnEgreso = [
    { 
      dataField: "fechaEgreso", 
      text: "Fecha"
    },
    { 
        dataField: "motivo", 
        text: "Motivo"
    },
    { 
      dataField: "monto", 
      text: "Pago",
      formatter: (_, row) => (<p className="text-primary m-0">{parseFloat(row.monto).toFixed(2)}</p>)
    },
    { 
      dataField: "empleado", 
      text: "Usuario"
    }
  ]

  useEffect(() => {
    getData(cajaId)
  },[cajaId])
  return (
    <div>
        <Row>
          <div className="col-12">
              <div className="card">
                  <div className="card-body">
                      <h4 className="card-title">Detalle de Cierre de Caja </h4>
                      <div className="tab-pills-horizontal">
                          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                              <Row>
                                  <Col xs={12}>
                                      <Nav variant="pills" className="flex-column border-0">
                                        <Nav.Item>
                                          <Nav.Link eventKey="first" onClick={() => {}} className="d-flex align-items-center">Ventas y Alquiler</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="second" onClick={() => {}} className="d-flex align-items-center">Compras</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="tree" onClick={() => {}} className="d-flex align-items-center">Ingresos</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="four" onClick={() => {}} className="d-flex align-items-center">Egresos</Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                  </Col>
                                  <Col xs={12}>
                                      <Tab.Content className="pt-0 p-0 border-0">
                                          <Tab.Pane eventKey="first">
                                            <BasicTableComponent 
                                              idKey='pedidoId'
                                              data={detalleCajaPedido}
                                              columns={columnPedido}
                                            />
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="second">
                                            <BasicTableComponent 
                                              idKey='ingresoId'
                                              data={detalleCajaCompra}
                                              columns={columnCompra}
                                            />
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="tree">
                                            <BasicTableComponent 
                                              idKey='ingresoId'
                                              data={detalleCajaIngreso}
                                              columns={columnIngreso}
                                            />
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="four">
                                            <BasicTableComponent 
                                              idKey='egresosId'
                                              data={detalleCajaEgreso}
                                              columns={columnEgreso}
                                            />
                                          </Tab.Pane>
                                      </Tab.Content>
                                  </Col>
                              </Row>
                          </Tab.Container>
                      </div>
                  </div>
              </div> 
          </div>
        </Row>
    </div>
  )
}
