import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Card,} from 'react-bootstrap';
import {Table, Search, FormFields, ButtonFields, ButtonResetFields,InputFields, Loading } from '../../components/Index';
import { getReportePedidos, getExportarExcel } from '../../services/SalesServices';
import { saveAs } from 'file-saver';
import { showErrorAlert } from '../../utils/helper/AlertService';

const search = {
    skip: 1,
    take: 10,
    search: "",
    fechaInicio:"",
    fechaFin: "",
    estadoId: "",
    tipoServicioId:'6B4CB03A-D052-4850-96F7-04A8A6007966'
}

export default function ReportRentals() {
    const [ serviciosPag, setServiciosPag ] = useState({});
    const [ listaServicio, setListaServicios ] = useState([]);
    const [ paramSearch, setParamSearch ] = useState(search);
    const [ isLoading, setIsLoading ] = useState(false);

    const handleTableChange = (page, sizePerPage) => {
        setParamSearch({
            ...paramSearch,
            skip:sizePerPage.page,
            take:sizePerPage.sizePerPage,
        })
    }

    const getData = async (param) => {
        try{
            setIsLoading(true)
            let data = await getReportePedidos(param);
            setServiciosPag(data);
            setListaServicios(data.items)
        }
        catch(e){
            showErrorAlert("Error de servidor, vuelva intentar más tarde");
        }
        finally{
            setIsLoading(false);
        }
    }

    const onInputSearch = ({target}) => {
        const { value } = target;
        setParamSearch({...paramSearch, search: value})
    }

    const onClickDescargarExcel = async (param) => {
        try{
            setIsLoading(true);
            var data = await getExportarExcel(param);
            setIsLoading(false);
            saveAs(new Blob([data]), 'reporte_alquiler.xlsx');
        } catch(e){
            showErrorAlert("Error al descargar el Excel");
        }
    }

    useEffect(() => {
        getData(paramSearch)
    },[paramSearch])

    const columns = [
        { 
            dataField: "nombreCliente", 
            text: "Cliente"
        },
        { 
            dataField: "fechaContrato", 
            text: "Fech. Contrato"
        },
		{ 
            dataField: "direccion", 
            text: "Dirección" 
        },
        { 
            dataField: "deuda", 
            text: "Deuda",
            formatter: (cellContent, row) => {
                if (cellContent === 0) {
                    return (
                    <p className="text-primary m-0">Sin Deuda</p>
                    );
                } else if (cellContent > 0 ) {
                    return (
                        <p className="text-danger font-weight-bold m-0">{parseFloat(row.deuda).toFixed(2)}</p>
                    );
                }
            }
        },
        { 
            dataField: "impuesto", 
            text: "IGV",
        },
        { 
            dataField: "precioBruto", 
            text: "Precio Total",
        },
        {   
            dataField: "estadoCodigo", 
            text: "Estado",
            classes: 'py-1',
            formatter: (cellContent, row) => {
                if (cellContent === '07') {
                    return (
                    <label className="badge badge-warning m-0">{row.estado}</label>
                    );
                } else if (cellContent === '08' ) {
                    return (
                    <label className="badge badge-success m-0">{row.estado}</label>
                    );
                }
                else if (cellContent === '09' ) {
                    return (
                    <label className="badge badge-secondary m-0">{row.estado}</label>
                    );
                }
                else if (cellContent === '10' ) {
                    return (
                    <label className="badge badge-danger m-0">{row.estado}</label>
                    );
                }
            }
        },
        {
            dataField: "opciones", 
            text: "Opciones",
            sort: false,
            align: 'center',
            classes: 'py-1',
            formatter: (_, row) => {
            return (
                <div>
                    <Link className='text-decoration-none' to={"/services/details-sales/"+row.id}><i className="ti-eye text-primary mr-2" data-toggle="tooltip" data-placement="right" title="Detalle" style={{fontSize:'15px'}}/></Link>
                </div>
            );
            }
        }
    ]
  return (
    <div>
        <Row >
            <div className='col-12'>
                <Card>  
                    <Card.Body>
                        <div className="row mb-4 justify-content-between">
                            <div className = "col-lg-3 d-none d-sm-none d-md-block">
                                <h4 className="card-title float-left mt-2">Reporte Alquiler</h4>
                            </div>
                            <div className = "col-lg-6 col-ms-12 pb-2">
                                <Search 
                                    onSearchInput={onInputSearch}
                                    value={paramSearch.search}
                                    nameInput='DNI Cliente'
                                    onRefesh={() => setParamSearch(search)}
                                >
                                    <FormFields
                                        initialValues={paramSearch}
                                        onSubmit={(values) => setParamSearch(values)}
                                    >
                                        <div className='row'>
                                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                                <InputFields
                                                    label={"Fecha de Entrega"}
                                                    type="date"
                                                    isRequired={false}
                                                    name="fechaInicio"
                                                    size = "sm"
                                                />
                                            </div>
                                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                                <InputFields
                                                    label={"Fecha de Recojo"}
                                                    type="date"
                                                    isRequired={false}
                                                    name="fechaFin"
                                                    size = "sm"
                                                />
                                            </div>
                                            
                                            <div className='col-12'>
                                                <ButtonFields title='Buscar'/>
                                                <ButtonResetFields title='Limpiar' onClickCancel={() => setParamSearch(search)}/>
                                            </div>
                                        </div>
                                    </FormFields>
                                </Search>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                                <button className="btn btn-outline-primary float-right" onClick={() => onClickDescargarExcel(paramSearch)}>
                                    <i className="fa fa-file-excel-o mr-2"/>
                                    Exportar Excel
                                </button>
                            </div>
                        </div> 
                        <div className='row mb-2'>
                            <div className='col-sm-12 col-md-4 col-lg-4'>
                                <p className='font-weight-bold'>Precio Total S/: <span className='text-info'>{parseFloat(serviciosPag.totalPrecio).toFixed(2)}</span> </p>
                            </div>
                            <div className='col-sm-12 col-md-4 col-lg-4'>
                                <p className='font-weight-bold'>Deuda S/: <span className='text-info'>{parseFloat(serviciosPag.totalDeuda).toFixed(2)}</span></p>
                            </div>
                            <div className='col-sm-12 col-md-4 col-lg-4'>
                                <p className='font-weight-bold'>Total IGV(18%) S/: <span className='text-info'>{parseFloat(serviciosPag.totalImpuesto).toFixed(2)}</span></p>
                            </div>
                        </div>
                        <Table
                            columns={columns}
                            list={listaServicio}
                            page={serviciosPag.page}
                            sizePerPage={paramSearch.take}
                            totalSize={serviciosPag.total}
                            onTableChange={handleTableChange}
                        />
                        <Loading isLoading={isLoading}/>
                    </Card.Body>
                </Card>
            </div>
        </Row>
    </div>
  )
}
