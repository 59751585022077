import React from 'react'

export default function CardItem({lista = [] , addList, updateList, deleteList}) {
  return (
    <table className="table table-striped table-borderless">
        <thead>
            <tr>
                <th></th>
                <th className="text-right pb-2 pr-0">
                    <button 
                        className="badge badge-primary ml-auto ml-2"
                        onClick={addList}
                    >
                        <i className="fa fa-plus mr-2"></i>Modelos
                    </button>
                </th>
            </tr>  
        </thead>
        <tbody>
            {
                lista && lista.map((item,index) =>(
                    <tr className="p-0" key={index}>
                        <td className="p-2">{item.nombre}</td>
                        <td className="p-2 text-right">
                            <div className="ml-auto">
                                <i className="fa fa-pencil-square-o font-weight-bold ml-auto px-1 py-1 text-info"
                                    title="editar"
                                    onClick={() => updateList(item)}
                                ></i>
                                <i className="ti-trash font-weight-bold ml-auto px-1 py-1 text-danger"
                                    title="eliminar"
                                    onClick={() => deleteList(item.id)}
                                ></i>
                            </div>
                        </td>
                    </tr>
                ))
            }
            
        </tbody>
    </table>
  )
}
