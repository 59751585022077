import React, { useEffect, useState } from 'react';
import { Row, Card } from 'react-bootstrap';
import { getPerfil } from '../../services/PersonServices';
import { ModalComponents, FormFields, InputFields, ButtonFields, ButtonResetFields, Loading, Checkbox, InputFileFields } from '../../components/Index';
import { actualizarCuentaEmpleado, actualizarEmpleado } from '../../services/PersonServices';
import { showErrorAlert, showSuccessAlert } from '../../utils/helper/AlertService';
import { updateAccountUserSchema, updateUserSchema } from '../../utils/validations/PersonValidation';

const new_model_cuenta = {
  id:'',
  email:'',
  password:'',
  confirmPassword:'',
}

const new_model = {
  id:'',
  primerNombre:'',
  segundoNombre:'',
  apellidoPaterno:'',
  apellidoMaterno:'',
  nroDOI:'',
  direccion:'',
  telefono:'',
  fotoPerfil: {
    contenido:"",
    extension:""
  }

}

function Profile() {
    const [ perfil, setPerfil ] = useState({});
    const [ mdShowCuenta, setMdShowCuenta ] = useState(false);
    const [ modelCuentaEmployees, setModelCuentaEmployees ] = useState(new_model_cuenta);
    const [ modelPerfil, setModelPerfil ] = useState(new_model);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ roles, setRoles ] = useState([]);
    const [ permisos, setPermisos ] = useState([]);
    const [ mdFoto, setMdFoto ] = useState(false);    
    const [ fotoPerfil, setFotoPerfil ] = useState(null);
    
    const onClickActualizar = (values,onSubmitProps) => {
      actualizarEmpleado(values).then(() => {
        showSuccessAlert('Actualización Exitosa');
        onSubmitProps.setSubmitting(false)
        onSubmitProps.resetForm();
        getData();
        setMdFoto(false);
      }).catch((error) => {
          showErrorAlert('Error de Actualización !',  error.apiMessage || 'Error desconocido');
      });
    }

    const onClickActualizarCuenta = (values,onSubmitProps) => {
      actualizarCuentaEmpleado(values).then((response) => {
          showSuccessAlert('La cuenta se relizó correctamente');
          onSubmitProps.setSubmitting(false)
          onSubmitProps.resetForm();
          getData()
          setMdShowCuenta(false);
      }).catch((error) => {
          showErrorAlert('Error de Actualización!',  error.apiMessage || 'Error desconocido');
      });
    }
    const onClickMdActualizar = () => {
      setModelCuentaEmployees({...modelCuentaEmployees,
        id: perfil.id,
        email: perfil.email
      })
      setMdShowCuenta(true);
    }

    const onResponseFerfil = (item) => {
      setModelPerfil({...modelPerfil,
        id: item.id,
        primerNombre: item.primerNombre,
        segundoNombre: item.segundoNombre,
        apellidoPaterno: item.apellidoPaterno,
        apellidoMaterno: item.apellidoMaterno,
        nroDOI: item.nroDOI,
        direccion: item.direccion,
        telefono:item.telefono
      })
      setMdFoto(true);
      
    }
    const getData = async () => {
      try {
        setIsLoading(true);
        const data = await getPerfil();
        setIsLoading(false);
        setPerfil(data);
        setFotoPerfil(data.foto)
        setRoles(data.roles);
        setPermisos(data.permisos);
      }
      catch(error){
        setIsLoading(false);
        showErrorAlert('Error !', error.apiMessage || 'Error desconocido');
      }
    }
    useEffect(() => {
      getData();  
    },[])
    return (
      <div>
        <Row>
          <div className="col-12">
            <Card>  
              <Card.Body>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="border-bottom text-center pb-4">
                      <img src={perfil.foto ? perfil.foto: require("../../assets/images/perfil_prueba.jpg")} alt="profile" className="img-lg rounded-circle mb-3"/>
                      <div className="mb-3">
                        <h4>{perfil.primerNombre+" " +perfil.apellidoPaterno+" "+perfil.apellidoMaterno}</h4>
                      </div>
                      <div className="d-flex justify-content-center">
                        <label className="badge badge-success mr-2">{perfil.estado}</label>
                        <label className='badge badge-info' style={{cursor:'pointer'}} onClick={() => onResponseFerfil(perfil)}><i className='ti-camera'/></label>
                      </div>
                    </div>
                    <div className="py-4">
                      <p className="clearfix">
                        <span className="float-left">
                          Estado
                        </span>
                        <span className="float-right text-muted">
                          {perfil.estado}
                        </span>
                      </p>
                      <p className="clearfix">
                        <span className="float-left">
                          Teléfono
                        </span>
                        <span className="float-right text-muted">
                          {perfil.telefono}
                        </span>
                      </p>
                      <p className="clearfix">
                        <span className="float-left">
                          Dirección
                        </span>
                        <span className="float-right text-muted">
                          {perfil.direccion}
                        </span>
                      </p>
                      <p className="clearfix">
                        <span className="float-left">
                          Email
                        </span>
                        <span className="float-right text-muted">
                          {perfil.email}
                        </span>
                      </p>
                      <p className="clearfix">
                        <span className="float-left">
                          N° DNI
                        </span>
                        <span className="float-right text-muted">
                          {perfil.nroDOI}
                        </span>
                      </p>
                    </div>
                    <button className="btn btn-primary btn-block mb-2" onClick={() => onClickMdActualizar()}>Actualizar Cuenta</button>
                  </div>
                  <div className="col-lg-8">
                    <div className="mt-4 py-2 border-top border-bottom">
                      <p className='font-weight-bold text-muted mb-0 text-center'>Roles y Permisos</p>
                    </div>
                    <div className="profile-feed">
                        <div className='row mt-3'>
                          <div className="col-12">
                            <p className='text-primary font-weight-bold'>Roles</p>
                          </div>
                          {
                            roles && roles.map((item,index) => (
                              <div key={index} className='col-sm-12 col-md-6 col-lg-6'>
                                <Checkbox 
                                  checked = {true}
                                  name='roles'
                                  disabled ={true}
                                  title={item.nombre}
                                />
                              </div>
                            ))
                          }

                          {
                            permisos.length > 0  && 
                            <div className="col-12 mt-2">
                              <p className='text-primary font-weight-bold'>Perisos</p>
                            </div>
                          }
                          {
                            permisos && permisos.map((item,index) => (
                              <div key={index} className='col-sm-12 col-md-6 col-lg-6'>
                                <Checkbox 
                                  checked = {true}
                                  name='permisos'
                                  disabled ={true}
                                  title={item.nombre}
                                />
                              </div>
                            ))
                          }
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
              <Loading isLoading={isLoading}/>
            </Card>
          </div>
        </Row>
        <ModalComponents
          titulo={"Actualizar Cuenta"}
          show={mdShowCuenta}
          hide={() => setMdShowCuenta(false)}
          size='sm'
        >
            <FormFields 
                initialValues={modelCuentaEmployees}
                onSubmit = {(values, onSubmitProps) => onClickActualizarCuenta(values,onSubmitProps)}
                validationSchema = {updateAccountUserSchema}
            >
            <div className='row'>
                <div className='col-12'>
                    <InputFields
                        label={"Email"}
                        type="email"
                        name="email"
                        size = "sm"
                    />
                </div>
                <div className='col-12'>
                    <InputFields
                        label={"Contraseña"}
                        type="password"
                        name="password"
                        size = "sm"
                    />
                </div>
                <div className='col-12'>
                    <InputFields
                        label={"Confirmación Contraseña"}
                        type="password"
                        name="confirmPassword"
                        size = "sm"
                    />
                </div>
                <div className='col-12 mt-4'>
                    <ButtonFields/>
                    <ButtonResetFields/>
                </div>
            </div>
            </FormFields>
        </ModalComponents>
        
        <ModalComponents
          titulo={"Actualizar Perfil"}
          show={mdFoto}
          size='sm'
          hide={() => setMdFoto(false)}
      >
          <FormFields
              initialValues = {modelPerfil}
              onSubmit = {(values, onSubmitProps) => onClickActualizar(values,onSubmitProps)}
              validationSchema = {updateUserSchema}
          >
          <div className='row'>
            <div className='col-12 text-center'>
              <img src={fotoPerfil ? fotoPerfil: require("../../assets/images/perfil_prueba.jpg")} alt="profile" className="img-lg rounded-circle mb-3"/>
            </div>
            <div className='col-12'>
              <InputFileFields 
                label={"Foto Empleado"}
                name={"fotoPerfil"}
                onSelectImage={(url) => setFotoPerfil(url)}
              />
            </div>
            <div className='col-12'>
                <InputFields
                    label={"Celular"}
                    type="text"
                    name="telefono"
                    size = "sm"
                />
            </div>
            <div className='col-12'>
                <InputFields
                    label={"Dirección"}
                    as="textarea" 
                    rows={1}
                    name="direccion"
                    size = "sm"
                />
            </div>
            <div className='col-12 mt-4'>
                <ButtonFields/>
                <ButtonResetFields/>
            </div>
        </div>
              
          </FormFields>
        </ModalComponents>
      </div>
    )
}
export default Profile;