import React, {useState, useEffect, useRef, Fragment} from 'react';
import { Card, Row } from 'react-bootstrap';
import { Search, FormFields, InputFields, Checkbox, ButtonFields, ButtonResetFields, ModalComponents, SelectFields, Loading } from '../../components/Index';
import { getListaActividades } from '../../services/ProductService';
import { getListaVerificacion, avanzarListaVeficacion, getListaVerificacionSelecionada, actualizarNotaDetallePedido } from '../../services/ChecklistService';
import { getComboEmpleado } from '../../services/PersonServices';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../utils/helper/AlertService';

const search = {
    skip: 1,
    take: 50,
    search: "",
    fechaInicio: "",
    fechaFin: "",
    pedidoId: ""
}

const modelInitial = {
    comentario: "",
    detallePedidoId:""
}

function ActivityList() {
    const ref = useRef();
    const [ paramSearch, setParamSearch ] = useState(search);
    const [ actividades, setActividades ] = useState([]);
    const [ mdAsigAct, setMdAsigAct ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoadingModal, setIsLoadingModal ] = useState(false);
    const [ detalleActividad, setDetalleActividad ] = useState({});
    const [ actividadPag, setActividadPag] = useState({});
    const [ checklist, setChecklist] = useState([]);
    const [ listaSelecionada, setListaSeleccionada ] = useState([]);
    const [ empleado, setEmpleado ] = useState([]);
    const [ detalleChecklist, setDetalleChecklist ] = useState({});
    const [ mdNota, setMdNota ] = useState(false);
    const [ modelNota, setModelNota ] = useState(modelInitial)

    const onClickChecklist = (values, onSubmitProps) => {
        values.listaVerificacion = listaSelecionada;
        values.estadoId = detalleChecklist.estadoIdSig;
        
        var val = listaSelecionada.find(x => x.cantInst === '');
        if(val){
            alert("Campo vacios");
        }
        else{
            showConfirAlert({
                title: '¿Estás seguro de Avanzar Actividad?',
                text: "¡No padrás revertir esto!",
                preConfirm: () => {
                    avanzarListaVeficacion(values).then(() => {
                        showSuccessAlert('Registro Exitoso');
                        onSubmitProps.setSubmitting(false)
                        onSubmitProps.resetForm();
                        getData(paramSearch);
                        setListaSeleccionada([]);
                        setDetalleChecklist({});
                        setMdAsigAct(false);
                    }).catch((error) => {
                        showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
                    });
                }
            });
        }
        
    }

    const onClickGuardarNota = (values,onSubmitProps) => {
        showConfirAlert({
            title: "Desea Ingresar un comentario",
            text: "Verifique que todos los campos esten registrados correctamente",
            preConfirm: () => {
              actualizarNotaDetallePedido(values).then(() => {
                showSuccessAlert('Registro Exitoso');
                onSubmitProps.setSubmitting(false)
                onSubmitProps.resetForm();
                getData(paramSearch);
                setMdNota(false);
              }).catch((error) => {
                showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
              });
            }
        });
    }

    const handleChangeInst = (id, event) => {
        const nuevaLista = [...listaSelecionada];
        var registro = nuevaLista.find(x => x.checklistId === id);
        if(registro){
            registro.cantInst = event.target.value;
        }
        setListaSeleccionada(nuevaLista);
    }
    const handleChangeRecojo = (id, event) => {
        const nuevaLista = [...listaSelecionada];
        var registro = nuevaLista.find(x => x.checklistId === id);
        if(registro){
            registro.cantRecojo = event.target.value;
        }
        setListaSeleccionada(nuevaLista);
    }

    const handleChangeProdInst = (id, event) => {
        const nuevaLista = [...listaSelecionada];
        var registro = nuevaLista.find(x => x.productoId === id);
        if(registro){
            registro.cantInst = event.target.value;
        }
        setListaSeleccionada(nuevaLista);
    }

    const handleChangeProdRecojo = (id, event) =>{
        const nuevaLista = [...listaSelecionada];
        var registro = nuevaLista.find(x => x.productoId === id);
        if(registro){
            registro.cantRecojo = event.target.value;
        }
        setListaSeleccionada(nuevaLista);
    }

    const onShowChechList = (item) => {
        fechListaVerificacion(item.categoriaId,item.detalleId)
        setDetalleActividad(item);
        setMdAsigAct(true)
    }

    const onShowNota = (item) => {
        setModelNota({...modelNota, 
            detallePedidoId: item.detalleId,
            comentario: item.comentario === null? "": item.comentario
        })
        setMdNota(true)
    }
    
    const handleToggle = (item) => {
        const nuevaLista = [...listaSelecionada];

        if (nuevaLista.some(x => x.checklistId === item.id)) {
          nuevaLista.splice(nuevaLista.findIndex(i => i.checklistId === item.id), 1);
        } else {
            var obj = {
                checklistId: item.id
            }
            nuevaLista.push(obj);
        }
        setListaSeleccionada(nuevaLista);
    };

    const handleToggleProducto = (id) => {
        const nuevaLista = [...listaSelecionada];

        if (nuevaLista.some(x => x.productoId === id)) {
          nuevaLista.splice(nuevaLista.findIndex(i => i.checklistId === id), 1);
        } else {
            var obj = {
                productoId: id
            }
            nuevaLista.push(obj);
        }
        setListaSeleccionada(nuevaLista);
    }

    const onInputSearch = ({target}) => {
        const { value } = target;
        paramSearch.search = value;
    }

    const getData = async (param) => {
        try {
            setIsLoading(true);
            const data  = await getListaActividades(param);
            setActividades(data.items);
            setActividadPag(data);
        }
        catch(e){
            showErrorAlert('Error !', e.apiMessage || 'Error desconocido')
        }
        finally{
            setIsLoading(false);
        }
    }

    const fechListaVerificacion = async (categoriaId,detallePedidoId) => {
        setIsLoadingModal(true);
        const lista = await getListaVerificacion(categoriaId);
        const empleado = await getComboEmpleado("");
        const listaSelec = await getListaVerificacionSelecionada(detallePedidoId)
        setIsLoadingModal(false);
        setChecklist(lista);
        setEmpleado(empleado);
        setDetalleChecklist(listaSelec);
        setListaSeleccionada(listaSelec.checklistSelecionados);
    }
    
    useEffect(() => {
        getData(paramSearch)
    },[paramSearch])

    const style = {
        maxWidth: '200px', 
        wordWrap: 'break-word', 
        whiteSpace: 'normal',
        fontSize:'11px'
    }
    return (
        <div>
            <Row>
                <div className='col-12'>
                    <Card>
                        <Card.Body>
                            <div className="row mb-4 justify-content-between">
                                <div className = "col-lg-3 d-none d-sm-none d-md-block">
                                    <h4 className="card-title float-left mt-2">Lista de Actividades</h4>
                                </div>
                                <div className = "col-lg-6 col-ms-12 float-left">
                                    <Search
                                        onSearchInput={onInputSearch}
                                        value={paramSearch.search}
                                        onRefesh={() => setParamSearch(search)}
                                    >
                                        <FormFields
                                            initialValues={paramSearch}
                                            formikRef={ref}
                                            onSubmit={(values) => setParamSearch(values)}
                                        >
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                                    <InputFields
                                                        label={"Fecha de Entrega"}
                                                        type="date"
                                                        isRequired={false}
                                                        name="fechaInicio"
                                                        size = "sm"
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                                    <InputFields
                                                        label={"Fecha de Recojo"}
                                                        type="date"
                                                        isRequired={false}
                                                        name="fechaFin"
                                                        size = "sm"
                                                    />
                                                </div>
                                                <div className='col-12 mt-2'>
                                                    <ButtonFields title='Buscar'/>
                                                    <ButtonResetFields title='Limpiar' onClickCancel={() => setParamSearch(search)}/>
                                                </div>
                                            </div>
                                        </FormFields>
                                    </Search>
                                </div>
                                <div className="col-sm-12 col-md-3 col-lg-3"></div>
                            </div> 
                            <section>
                                <div className='table-responsive'>
                                    <table className="table">
                                        <thead className="bg-primary text-white">
                                            <tr>
                                                <th>N° Contrato</th>
                                                <th>Cliente / Finado</th>
                                                <th> Dirección / Telefono</th>
                                                <th className='text-center'>Carroza</th>
                                                <th>Servicio</th>
                                                <th>F. Entrega</th>
                                                <th>F. Recojo</th>
                                                <th className='text-center'>Estado</th>
                                                <th>opción</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                actividades && actividades.map((item, index) =>
                                                item.detallePedidos.map((det, detalleIndex) => (
                                                    <tr key={det.detalleId} >
                                                        { detalleIndex === 0 ? ( 
                                                            <Fragment>
                                                                <td rowSpan={item.detallePedidos.length}>{item.numContrato}</td>
                                                                <td rowSpan={item.detallePedidos.length} style={style}>
                                                                    <p className='pb-1 mb-0 text-sm-left' style={{fontSize:'12px'}}>{item.cliente}</p>
                                                                    {item.finado !== "  " && <p className='pb-1 mb-0 text-sm-left text-primary' style={{fontSize:'10px'}}>Finado: {item.finado}</p>}
                                                                </td>
                                                                <td rowSpan={item.detallePedidos.length} style={style}>
                                                                    <p className='text-primary pb-1 mb-0' style={{fontSize:'11px'}}>Dir: {item.direccion}</p>
                                                                    <p className='text-black-50 pb-0 mb-0' style={{fontSize:'12px'}}>Tel: {item.telefonos}</p>
                                                                </td>
                                                            </Fragment>
                                                        ) : null }
                                                        <td style={style}>{det.comentario}</td>
                                                        <td>{det.nombre}</td>
                                                        <td className='font-weight-bold'>{det.fechaEntrega}</td>
                                                        <td className='font-weight-bold'>{det.fechaRecojo}</td>
                                                        <td className='text-center'><label className="badge m-0" style={{backgroundColor: `${det.estadoColor}`, color:'white'}}>{det.estado}</label></td>
                                                        <td className='text-center'>
                                                            <i className="btn ti-pencil-alt text-primary p-0 pr-2" style={{fontSize:'15px'}} data-toggle="tooltip" data-placement="right" title="Editar" onClick={() => onShowNota(det)}></i>
                                                            <i className="btn ti-new-window text-warning p-0" style={{fontSize:'15px'}} data-toggle="tooltip" data-placement="right" title="Avanzar" onClick={() => onShowChechList(det)}></i>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                            
                                        </tbody>
                                        
                                    </table>
                                </div>
                            </section>
                            <ModalComponents
                                size={"lg"} 
                                show={mdAsigAct} 
                                hide={()=>setMdAsigAct(false)}  
                                titulo={<>{detalleActividad.nombre}<span className='h6 pl-5 font-italic'>Fecha: {detalleActividad.fechaEntrega }&nbsp; al &nbsp;{detalleActividad.fechaRecojo}</span></> } 
                            >
                                <FormFields
                                    initialValues={{
                                        detalleId: detalleActividad.detalleId,
                                        estadoId: "",
                                        responsableAlmacenId: '',
                                        responsableInstalacionId:'',
                                        comentario:'',
                                        listaVerificacion: []
                                    }}
                                    onSubmit = {(values, onSubmitProps) => onClickChecklist(values, onSubmitProps)}
                                >
                                {checklist.length > 0 ? (
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-7 col-lg-7'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <p className='text-muted'>Descripción</p>
                                                </div>
                                                <div className='col-3'>
                                                    {
                                                        detalleChecklist.visibleInpIng && 
                                                        <p className='text-muted'>Cant. Instalada</p>
                                                    }
                                                </div>
                                                <div className='col-3'>
                                                    {
                                                        detalleChecklist.visibleInpRecojo && 
                                                        <p className='text-muted'>Cant. Recojo</p>
                                                    }
                                                </div>
                                                {checklist.map((item,index) => (
                                                    <Fragment key={index}>
                                                        <div className='col-6'>
                                                            <Checkbox 
                                                                disabled = {detalleChecklist.disableCheckbox}
                                                                checked={listaSelecionada.some(x => x.checklistId === item.id)}
                                                                onChange={() => handleToggle(item)}
                                                                title={item.nombre}
                                                            />
                                                        </div>
                                                        {
                                                            detalleChecklist.visibleInpIng && 
                                                            <div className='col-3'>
                                                                <input
                                                                    className={`form-control form-control-sm mb-1`}
                                                                    disabled = {(!listaSelecionada.some(x => x.checklistId === item.id) || detalleChecklist.disableCantIng)}
                                                                    name={`inst${item.id}`}
                                                                    type="number"
                                                                    value={listaSelecionada.find(x => x.checklistId === item.id) ? listaSelecionada.find(x => x.checklistId === item.id).cantInst: ''}
                                                                    onChange={(event) => handleChangeInst(item.id, event)}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            detalleChecklist.visibleInpRecojo && 
                                                            <div className='col-3'>
                                                                <input
                                                                    className={`form-control form-control-sm mb-1`}
                                                                    disabled = {(!listaSelecionada.some(x => x.checklistId === item.id) || detalleChecklist.disableCantRecojo)}
                                                                    name={`inst${item.id}`}
                                                                    type="number"
                                                                    value={listaSelecionada.find(x => x.checklistId === item.id) ? listaSelecionada.find(x => x.checklistId === item.id).cantRecojo: ''}
                                                                    onChange={(event) => handleChangeRecojo(item.id, event)}
                                                                />
                                                            </div>
                                                        }
                                                    </Fragment>
                                                ))}
                                            
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-5 col-lg-5'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className="profile-feed">
                                                        {
                                                            detalleChecklist.actividadChecklist && detalleChecklist.actividadChecklist.map((item,index) => (
                                                                <div className="d-flex align-items-start profile-feed-item p-0 pt-3" key={index}>
                                                                    <i className='fa fa-user-circle text-primary' style={{fontSize:'20px'}}></i>
                                                                    <div className="ml-4">
                                                                        <h6>
                                                                            {item.usuario}
                                                                            {item.estado && <small className="ml-5 text-warning">{item.estado}</small>}
                                                                        </h6>
                                                                        { item.almacen && <p className='mb-0'><span className='text-muted'>Responsable Almacen: </span> {item.almacen} </p>}
                                                                        { item.responsable &&  <p> <span className='text-muted'>Instalación: </span> {item.responsable} </p>}
                                                                        { item.comentario && <p className='text-justify'>{item.comentario}</p>}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                        
                                                    </div>
                                                </div>
                                                {
                                                    (detalleActividad.estadoNum === "01" || detalleActividad.estadoNum === "04") && 
                                                    <>
                                                        <div className='col-12 mt-3'>
                                                            <SelectFields
                                                                label={"Responsable Almacen"}
                                                                name="responsableAlmacenId"
                                                                data = {empleado.map(item => ({value:item.userId, label: item.nombre}))}
                                                            />
                                                        </div>
                                                        <div className='col-12'>
                                                            <SelectFields
                                                                label={"Responsable Instalación"}
                                                                name="responsableInstalacionId"
                                                                data = {empleado.map(item => ({value:item.userId, label: item.nombre}))}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                
                                                {
                                                    detalleChecklist.visibleBoton && (
                                                    <>
                                                        <div className='col-12 mt-2'>
                                                            <InputFields
                                                                label={"Comentario"}
                                                                as="textarea" 
                                                                rows={1}
                                                                isRequired={false}
                                                                name="comentario"
                                                                size="sm"
                                                            />
                                                        </div>
                                                        <div className='col-12 mt-2'>
                                                            <ButtonFields title={`Avanzar ${detalleChecklist.nombreEstadoSig}`}/>
                                                        </div>
                                                    </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-7 col-lg-7'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <p className='text-muted'>Descripción</p>
                                                </div>
                                                <div className='col-3'>
                                                    {
                                                        detalleChecklist.visibleInpIng && 
                                                        <p className='text-muted'>Cant. Instalada</p>
                                                    }
                                                </div>
                                                <div className='col-3'>
                                                    {
                                                        detalleChecklist.visibleInpRecojo && 
                                                        <p className='text-muted'>Cant. Recojo</p>
                                                    }
                                                </div>
                                                <div className='col-6'>
                                                    <Checkbox 
                                                        disabled = {detalleChecklist.disableCheckbox}
                                                        checked={listaSelecionada.some(x => x.productoId === detalleActividad.productoId )}
                                                        onChange={() => handleToggleProducto(detalleActividad.productoId)}
                                                        title={detalleActividad.nombre}
                                                    />
                                                </div>
                                                {
                                                    detalleChecklist.visibleInpIng && 
                                                    <div className='col-3'>
                                                        <input
                                                            className= "form-control form-control-sm mb-1"
                                                            disabled = {(!listaSelecionada.some(x => x.productoId === detalleActividad.productoId) || detalleChecklist.disableCantIng)}
                                                            name={`inst${detalleActividad.productoId}`}
                                                            type="number"
                                                            value={listaSelecionada.find(x => x.productoId === detalleActividad.productoId) ? listaSelecionada.find(x => x.productoId === detalleActividad.productoId).cantInst: ''}
                                                            onChange={(event) => handleChangeProdInst(detalleActividad.productoId, event)}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    detalleChecklist.visibleInpRecojo && 
                                                    <div className='col-3'>
                                                        <input
                                                            className={`form-control form-control-sm mb-1`}
                                                            disabled = {(!listaSelecionada.some(x => x.productoId === detalleActividad.productoId) || detalleChecklist.disableCantRecojo)}
                                                            name={`inst${detalleActividad.productoId}`}
                                                            type="number"
                                                            value={listaSelecionada.find(x => x.productoId === detalleActividad.productoId) ? listaSelecionada.find(x => x.productoId === detalleActividad.productoId).cantRecojo: ''}
                                                            onChange={(event) => handleChangeProdRecojo(detalleActividad.productoId, event)}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-5 col-lg-5'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className="profile-feed">
                                                        {
                                                            detalleChecklist.actividadChecklist && detalleChecklist.actividadChecklist.map((item,index) => (
                                                                <div className="d-flex align-items-start profile-feed-item p-0 pt-3" key={index}>
                                                                    {/* <img src="https://via.placeholder.com/43x43" alt="profile" className="img-sm rounded-circle"/> */}
                                                                    <i className='fa fa-user-circle text-primary' style={{fontSize:'20px'}}></i>
                                                                    <div className="ml-4">
                                                                        <h6>
                                                                            {item.usuario}
                                                                            {item.estado && <small className="ml-5 text-warning">{item.estado}</small>}
                                                                        </h6>
                                                                        { item.almacen && <p className='mb-0'><span className='text-muted'>Responsable Almacen: </span> {item.almacen} </p>}
                                                                        { item.responsable &&  <p> <span className='text-muted'>Instalación: </span> {item.responsable} </p>}
                                                                        { item.comentario && <p className='text-justify'>{item.comentario}</p>}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                        
                                                    </div>
                                                </div>
                                                {
                                                    (detalleActividad.estadoNum === "01" || detalleActividad.estadoNum === "04") && 
                                                    <>
                                                        <div className='col-12 mt-3'>
                                                            <SelectFields
                                                                label={"Responsable Almacen"}
                                                                name="responsableAlmacenId"
                                                                data = {empleado.map(item => ({value:item.userId, label: item.nombre}))}
                                                            />
                                                        </div>
                                                        <div className='col-12'>
                                                            <SelectFields
                                                                label={"Responsable Instalación"}
                                                                name="responsableInstalacionId"
                                                                data = {empleado.map(item => ({value:item.userId, label: item.nombre}))}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    detalleChecklist.visibleBoton && (
                                                    <>
                                                        <div className='col-12'>
                                                            <InputFields
                                                                label={"Comentario"}
                                                                as="textarea" 
                                                                rows={1}
                                                                isRequired={false}
                                                                name="comentario"
                                                                size="sm"
                                                            />
                                                        </div>
                                                        <div className='col-12 mt-2'>
                                                            <ButtonFields title={`Avanzar ${detalleChecklist.nombreEstadoSig}`}/>
                                                        </div>
                                                    </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}
                                </FormFields>
                                <Loading isLoading = {isLoadingModal} />
                            </ModalComponents>
                            <ModalComponents
                                size={"md"} 
                                show={mdNota} 
                                hide={()=>setMdNota(false)} 
                                titulo={"Agregar Comentario"}
                            >
                                <div className='row'>
                                    <div className='col-12'>
                                        <FormFields
                                            initialValues = {modelNota}
                                            onSubmit = {(values, onSubmitProps) => onClickGuardarNota(values,onSubmitProps)}
                                        >
                                        <div className='row'>
                                            <div className='col-12'>
                                                <InputFields
                                                    label={"Comentario"}
                                                    as="textarea" 
                                                    rows={1}
                                                    name="comentario"
                                                    size = "sm"
                                                    isRequired = {false}
                                                />
                                            </div>
                                           
                                            <div className='col-12'>
                                                <ButtonFields title = {"Guardar"}/>
                                            </div>
                                        </div>
                                        </FormFields>
                                    </div>
                                </div>
                            </ModalComponents>
                            <Loading isLoading={isLoading}/>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
        </div>
    );
}

export default ActivityList;