import React from 'react';
import ErrorMessage from '../ErrorMessage';
import { useFormikContext } from 'formik';
import Select from "react-select";
import { LabelFields } from './LabelFields';

function SelectFields({name,label, data = [], isRequired = true, onSelect = () => {}, ...otherProps }) {
    const { errors,setFieldValue,touched, values } = useFormikContext();
    
    const customThemeFn = (theme) => ({ 
        ...theme,
        spacing: {
          ...theme.spacing,
          controlHeight: 3,
          baseUnit: 3
        }
        
    })

    return (
        <div className='mb-2'>
            {
                label && <LabelFields label={label} isRequired = {isRequired}/>
            }
            <Select
                isSearchable = {true}
                theme={customThemeFn}
                value = {data.filter(({ value }) => value === values[name])}
                onChange={item => {setFieldValue(name, item.value); onSelect(item)}}
                options={data}
                {...otherProps}
            />
            <ErrorMessage error={errors[name]} visible={touched[name]}  />
        </div>
    );
}

export default SelectFields;