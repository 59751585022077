import AuthProvider from "./context/AuthProvider";
import { AppRouters } from "./routes/AppRouters";
import './App.scss';

function App() {
  return (
    <AuthProvider>
      <AppRouters/>
    </AuthProvider>
  );
}

export default App;
