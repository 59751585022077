import React,{ useContext } from 'react';
import { decodeToken } from "react-jwt";
import { Context } from '../../context/AuthContext';
import { Navigate } from 'react-router-dom';

export default function RouteProtected({accion = [], children}) {
    const { token } = useContext(Context);
    var decode = decodeToken(token);

    if(!decode.hasOwnProperty('role')){
      return null;
    }
    if(Array.isArray(decode.role)){
      const tienePermiso = decode.role.some(role => accion.includes(role));
      return tienePermiso ? <>{children}</> : <Navigate to={"/"} replace/>;
    }
    if(!Array.isArray(decode.role)){
      const tienePermiso = [decode.role].some(role => accion.includes(role));
      return tienePermiso ? <>{children}</> : <Navigate to={"/"} replace/>;
    }
    
    return <Navigate to={"/"} replace/>;
    
}
