import React from 'react';
import { useFormikContext } from 'formik';

function InputBasic({name, isMayuscula = true, onChangeInput = () => {}, ...otherProps}) {
    const { setFieldTouched,setFieldValue,errors, values} = useFormikContext();
    const onChange = (e) => {
        const { value } = e.target;
        setFieldValue(name,value);
        onChangeInput(value)
    }
    return (
        <input 
            style={{border: 0, borderBottom: errors[name] ? '2px solid red':'1px solid', width: '120px', textAlign:'right',paddingRight: '5px'}}
            onChange={onChange}
            onBlur = {() => setFieldTouched(name)}
            value = {values[name]}
            {...otherProps}
        />
    );
}

export default InputBasic;