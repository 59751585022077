import React from 'react';

function Button({
    color="primary", 
    label = "Guardar", 
    className ="",
    onClick = () => {}}) 
{
    return (
        <button type="button" className ={`btn btn-${color} ${className}`} onClick = {onClick}>
            {label}
        </button>
    );
}

export default Button;