import React from 'react'


export default function CardList({children, nombre , editList = () => {} }) {
  return (
    <div className="row project-list-showcase">
        <div className="col-12 project-grid">
            <div className="project-grid-inner">
                <div className="pl-3 border-bottom">
                    <div className="row pb-1">
                        <div className="col-6 pr-2 pl-0">
                            <h5 className="text-muted">{nombre}</h5>
                        </div>
                        <div className="col-6  ml-auto justify-content-between">
                            <div className="d-flex float-right" >
                                <button className="badge badge-info mr-2" onClick={editList}>
                                    <i className="fa fa-pencil-square-o"></i>
                                </button>
                            </div>
                            </div>
                        </div>
                </div>

                <div className="table-responsive">
                    { children }
                </div>
                    
            </div>
        </div>
    </div>
  )
}
