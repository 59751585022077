import React, { useEffect, useState } from 'react';
import { Row, Col, Nav, Tab } from "react-bootstrap";
import { getListaProducto } from '../../services/ProductService';

export default function Catalogue() {
    const modelInit = {
        skip: 1,
        take: 100,
        nombre: "",
        tipo: "01"
    };
    const [ model, setModel ] = useState(modelInit);
    const [ catalogo, setCatalogo ] = useState([]);

    const getData = async (param) => {
        const data = await getListaProducto(param);
        setCatalogo(data.items);
    }

    useEffect(() => {
        getData(model);
    },[model]);

  return (
    <div>
      <div className="col-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Catálogo</h4>
                        <div className="tab-pills-horizontal">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Row>
                                    <Col xs={12}>
                                        <Nav variant="pills" className="flex-column border-0">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first" className="d-flex align-items-center" onClick={() => setModel({...model, tipo: "01"})}>
                                                    <i className="ti-home text-primary pr-2"></i>
                                                    Productos
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second" className="d-flex align-items-center" onClick={() => setModel({...model, tipo: "02"})}>
                                                    <i className="ti-user text-danger pr-2"></i>
                                                    Sercicios
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col xs={12}>
                                        <Tab.Content className="pt-0 p-0 border-0">
                                            <Tab.Pane eventKey="first">
                                                <div className="row product-item-wrapper">
                                                    {
                                                        catalogo.map((item,index) =>(
                                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 product-item stretch-card" key={index}>
                                                            <div className="card">
                                                                <div className="card-body p-3">
                                                                    <div className="action-holder">
                                                                        <div className="sale-badge bg-success">{item.stock}</div>
                                                                    </div>
                                                                    <div className="product-img-outer">
                                                                        <img className="product_image" src={item.urlImagen} alt="prduct" />
                                                                    </div>
                                                                    <p className="product-title">{item.nombre}</p>
                                                                    {/* <p className="product-price">sss</p> */}
                                                                    <p className="product-description">Categoría: {item.categoria}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        ))
                                                    }
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <div className="row product-item-wrapper">
                                                    {
                                                        catalogo.map((item,index) =>(
                                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 product-item stretch-card" key={index}>
                                                            <div className="card">
                                                                <div className="card-body p-3">
                                                                    <div className="action-holder">
                                                                        <div className="sale-badge bg-success">{item.stock}</div>
                                                                    </div>
                                                                    <div className="product-img-outer">
                                                                        <img className="product_image" src={item.urlImagen} alt="prduct" />
                                                                    </div>
                                                                    <p className="product-title">{item.nombre}</p>
                                                                    {/* <p className="product-price">sss</p> */}
                                                                    <p className="product-description">Categoría: {item.categoria}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        ))
                                                    }
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
       {/* <Row>
        <Card className="col-lg-12">
          <Card.Body>
            <div className="row product-item-wrapper">
                {
                    premios.map((item,index) =>(
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 product-item stretch-card" key={index}>
                        <div className="card">
                            <div className="card-body">
                            <div className="action-holder">
                            <div className="sale-badge bg-success">{item.puntos}</div>
                            </div>
                            <div className="product-img-outer">
                                <img className="product_image" src={item.imagenUrl} alt="prduct" />
                            </div>
                            <p className="product-title">{item.nombre}</p>
                            <p className="product-price"></p>
                            <p className="product-description">Nombre: {item.nombre}</p>
                            </div>
                        </div>
                    </div>
                    ))
                }
            </div>
          </Card.Body>
        </Card>
        </Row>
         */}
  </div>
  )
}
