import Client from './Client';

const endpoint= "api/v1/";
//Listar Persona

export const getListaCliente = (data) => {
    return Client.post(`${endpoint}Persona/Lista`,data)
}
export const getListaFallecido = (data) => {
    return Client.post(`${endpoint}Persona/Fallecido/Listar`,data)
}
export const getListaProveedores = (search,compania,ruc) => {
    return Client.get(`${endpoint}Proveedor/Lista?Search=${search}&Compania=${compania}&Rut=${ruc}`)
}
export const getListaEmpleado = (data) => {
    return Client.post(`${endpoint}Empleado/Listar`,data);
}
export const getComboEmpleado = (search) => {
    return Client.get(`${endpoint}Empleado/Combo?Search=${search}`)
}
export const getPerfil = () => {
    return Client.get(`${endpoint}Empleado/Perfil`)
}
export const getComboCliente = (search) => {
    return Client.get(`${endpoint}Persona/ListarCombo?Search=${search}&TipoPersona=01`)
}
export const getComboFallecido = (search) => {
    return Client.get(`${endpoint}Persona/ListarCombo?Search=${search}&TipoPersona=02`)
}
export const getPermisosUser = (userId) => {
    return Client.get(`${endpoint}Permisos/UsuarioPermiso?UsuarioId=${userId}`)
}
export const getPermisos = () => {
    return Client.get(`${endpoint}Permisos/Listar`)
}

export const getBuscaPersonas = (dni) => {
    return Client.get(`${endpoint}Persona/BuscaPersona?Dni=${dni}`)
}

//Crear Persona
export const loginService = (data) => {
    return Client.post(`${endpoint}Identity/Login`, data);
}
export const crearCliente = (data) => {
    return Client.post(`${endpoint}Persona/Crear`, data)
}
export const crearFallecido = (data) => {
    return Client.post(`${endpoint}Persona/Fallecido/Crear`, data);
}
export const crearEmpleado = (data) => {
    return Client.post(`${endpoint}Empleado/Crear`, data)
}
export const crearPermisoUsuario = (data) => {
    return Client.post(`${endpoint}Permisos/Crear`, data)
}

// Actualizar

export const actualizarCliente = (data) => {
    return Client.put(`${endpoint}Persona/Actualizar`, data);
}
export const actualizarFallecido = (data) => {
    return Client.put(`${endpoint}Persona/Fallecido/Actualizar`, data);
}
export const actualizarEmpleado = (data) => {
    return Client.put(`${endpoint}Empleado/Actualizar`, data);
}

export const actualizarCuentaEmpleado = (data) => {
    return Client.put(`${endpoint}Empleado/ActualizarCuenta`, data);
}

//Eliminar 

export const deletePersona = (id) => {
    return Client.delete(`${endpoint}Persona/Delete/${id}`)
}