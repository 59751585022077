import React from 'react';
import { Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search }  from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

export default function BasicTableComponent({idKey = "id", data = [], columns}) {
  return (
    <Row>
        <div className="col-12">
            <ToolkitProvider keyField={idKey} bootstrap4 data={data} columns={columns} search>
            {(props) => (
                <div>
                <div className=" float-right mb-2 d-flex">
                    <div className="input-group-prepend hover-cursor" id="navbar-search-icon">
                        <span className="input-group-text form-control">
                            <i className="ti-search"></i>
                        </span>
                    </div>
                    <SearchBar {...props.searchProps} className="form-control" placeholder="Buscar" aria-label="search" aria-describedby="search" />
                </div>
                <BootstrapTable
                    pagination={paginationFactory()}
                    {...props.baseProps}
                    wrapperClasses="orders-table table-responsive table-hover"
                    noDataIndication="Lista Vacía"
                    bordered={false}
                />
                </div>
            )}
            </ToolkitProvider>
        </div>
    </Row>
  )
}
