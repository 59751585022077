import React, {useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Row } from "react-bootstrap";
import {Table, Search, FormFields, AsyncSelectFields, SelectFields, ButtonFields, ButtonResetFields, ModalComponents,InputFields, Loading } from '../../components/Index';
import { getEstado, getListaServicio, getListarActividadEstado, CrearActividadEstadoPedido, ActualizarNota } from '../../services/SalesServices';
import { getComboCliente } from '../../services/PersonServices';
import UserPermissionsComponent from '../auth/UserPermissionsComponent';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../utils/helper/AlertService';
import { avanceEstadoSchema } from '../../utils/validations/ServiceValidation';

const estadoDeuda = [
    {value:"01", label:"Con Deuda"},
    {value:"02", label:"Sin Deuda"}
]
const search = {
    skip: 1,
    take: 10,
    search: "",
    codigoDeuda:"",
    clienteId: "",
    estadoId: "",
    fechaInicio:"",
    fechaFin: "",
    tipoServicioId:'6B4CB03A-D052-4850-96F7-04A8A6007966'
}

const modelInicial = {
    pedidoId: "",
    estadoId: "",
    comentario: "",
}

const modelInicialNota = {
    pedidoId: "",
    nota: "",
}
const ListRentals = () => {
    const [ serviciosPag, setServiciosPag ] = useState({});
    const [ listaServicio, setListaServiciosPag ] = useState([]);
    const [ paramSearch, setParamSearch ] = useState(search);
    const [ estado, setEstado ] = useState([]);
    const [ mdEstado, setMdEstado ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ actividadEstado, setActividadEstado ] = useState([]);
    const [ modelActividad, setModelActividad ] = useState(modelInicial);
    const [ mdNota, setMdNota ] = useState(false);
    const [ modelNota, setModelNota] = useState(modelInicialNota);

    const onClickGuardar = (values,onSubmitProps) => {
        showConfirAlert({
            title: "Desea Actualizar el estado",
            text: "Verifique que todos los campos esten registrados correctamente",
            preConfirm: () => {
              CrearActividadEstadoPedido(values).then(() => {
                showSuccessAlert('Registro Exitoso');
                onSubmitProps.setSubmitting(false)
                onSubmitProps.resetForm();
                getData(paramSearch);
                setMdEstado(false);
              }).catch((error) => {
                showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
              });
            }
        });
    }

    const onClickGuardarNota = (values,onSubmitProps) => {
        showConfirAlert({
            title: "Desea Ingresar una Nota",
            text: "Verifique que todos los campos esten registrados correctamente",
            preConfirm: () => {
              ActualizarNota(values).then(() => {
                showSuccessAlert('Registro Exitoso');
                onSubmitProps.setSubmitting(false)
                onSubmitProps.resetForm();
                getData(paramSearch);
                setMdNota(false);
              }).catch((error) => {
                showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
              });
            }
        });
    }

    const onClickEstado = async (id) => {
        setMdEstado(true);
        setModelActividad({...modelActividad, pedidoId: id})
        const actividad = await getListarActividadEstado(id);
        setActividadEstado(actividad);
    }

    const onClickNota = async (item) => {
        setMdNota(true);
        setModelNota({...modelNota, 
            pedidoId: item.id,
            nota: item.nota
        })
    }

    const onInputSearch = ({target}) => {
        const { value } = target;
        setParamSearch({...paramSearch, search: value})
    }

    const fechDataClient = async (value) => {
        const resp_cliente = await getComboCliente(value);
        return resp_cliente;
    }

    const fechSatatus = async () => {
        let data = await getEstado("02");
        setEstado(data);
    }

    const handleTableChange = (page, sizePerPage) => {
        setParamSearch({
            ...paramSearch,
            skip:sizePerPage.page,
            take:sizePerPage.sizePerPage,
        })
    }

   
    const getData = async (param) => {
        try{
            setIsLoading(true);
            const data = await getListaServicio(param);
            setIsLoading(false);
            setServiciosPag(data);
            setListaServiciosPag(data.items);
        }
        catch(e){
            showErrorAlert('Hubo un problema de registro',  e.apiMessage || 'Error desconocido');
        }
       
    }

    useEffect(() => {
        fechSatatus();
    },[])

    useEffect(() => {
        getData(paramSearch);
    },[paramSearch])

    const columns = [
        { 
            dataField: "numeroContrato", 
            text: "NRO",
        },
        { 
            dataField: "fechaContrato", 
            text: "Fecha"
        },
        { 
            dataField: "nombreCliente", 
            text: "Cliente",
        },
        { 
            dataField: "deuda", 
            text: "Deuda",
            formatter: (cellContent, row) => {
                if (cellContent === 0) {
                    return (
                    <p className="text-primary m-0">Sin Deuda</p>
                    );
                } else if (cellContent > 0 ) {
                    return (
                        <p className="text-danger font-weight-bold m-0">{parseFloat(row.deuda).toFixed(2)}</p>
                    );
                }
            }
        },
        { 
            dataField: "tipoPago", 
            text: "Tipo Pago",
        },
        {   
            dataField: "estadoCodigo", 
            text: "Estado",
            classes: 'py-1',
            formatter: (cellContent, row) => {
                if (cellContent === '07') {
                    return (
                    <label className="badge badge-warning m-0">{row.estado}</label>
                    );
                } else if (cellContent === '08' ) {
                    return (
                    <label className="badge badge-success m-0">{row.estado}</label>
                    );
                }
                else if (cellContent === '09' ) {
                    return (
                    <label className="badge badge-secondary m-0">{row.estado}</label>
                    );
                }
                else if (cellContent === '10' ) {
                    return (
                    <label className="badge badge-danger m-0">{row.estado}</label>
                    );
                }
            }
        },
        {   
            dataField: "nota",     
            text: "Nota",
            style: { maxWidth: '400px', wordWrap: 'break-word', whiteSpace: 'normal' }
        },
        {
            dataField: "opciones", 
            text: "Opciones",
            sort: false,
            align: 'center',
            classes: 'py-1',
            formatter: (cellContent, row) => {
            return (
                <div>
                    <Link className='text-decoration-none' to={"/services/details-sales/"+row.id}><i className="ti-eye text-primary mr-2" data-toggle="tooltip" data-placement="right" title="Detalle" style={{fontSize:'15px'}}/></Link>
                    <UserPermissionsComponent accion={['admin','admVenta']}>
                        <Link className='text-decoration-none'><i className="ti-write text-success mr-2" data-toggle="tooltip" data-placement="right" title="Cambiar Estado" style={{fontSize:'15px'}} onClick={() => onClickEstado(row.id)}/></Link>
                    </UserPermissionsComponent>
                    <Link className='text-decoration-none'><i className="ti-pencil-alt text-warning" data-toggle="tooltip" data-placement="right" title="Cambiar Estado" style={{fontSize:'15px'}} onClick={() => onClickNota(row)}/></Link>
                </div>
            );
            }
        }
          
    ]

    return (
        <div>
            <Row >
                <div className='col-12'>
                    <Card>  
                        <Card.Body>
                            <div className="row mb-4 justify-content-between">
                                <div className = "col-lg-3 d-none d-sm-none d-md-block">
                                    <h4 className="card-title float-left mt-2">Lista Alquileres</h4>
                                </div>
                                <div className = "col-lg-6 col-ms-12 pb-2">
                                    <Search 
                                        onSearchInput={onInputSearch}
                                        value={paramSearch.search}
                                        nameInput='DNI (Cliente/fallecido) / Nro. Comprobante'
                                        onRefesh={() => setParamSearch(search)}
                                    >
                                        <FormFields
                                            initialValues={paramSearch}
                                            onSubmit={(values) => setParamSearch(values)}
                                        >
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                                    <InputFields
                                                        label={"Fecha de Entrega"}
                                                        type="date"
                                                        isRequired={false}
                                                        name="fechaInicio"
                                                        size = "sm"
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                                    <InputFields
                                                        label={"Fecha de Recojo"}
                                                        type="date"
                                                        isRequired={false}
                                                        name="fechaFin"
                                                        size = "sm"
                                                    />
                                                </div>
                                                <div className='col-ms-12 col-md-6 col-lg-6'>
                                                    <AsyncSelectFields
                                                        label={"Cliente(a)"}
                                                        isRequired = {false}
                                                        name={"clienteId"}
                                                        loadOptions = {fechDataClient}
                                                    />
                                                </div>
                                                <div className='col-ms-12 col-md-6 col-lg-6'>
                                                    <SelectFields
                                                        label={"Estado"}
                                                        name="estadoId"
                                                        isRequired={false}
                                                        data={estado.map(item => ({value:item.id, label: item.nombre }))}
                                                    />
                                                </div>
                                                <div className='col-ms-12 col-md-6 col-lg-6'>
                                                    <SelectFields
                                                        label={"Deuda"}
                                                        name="codigoDeuda"
                                                        isRequired={false}
                                                        data={estadoDeuda}
                                                    />
                                                </div>

                                                <div className='col-12'>
                                                    <ButtonFields title='Buscar'/>
                                                    <ButtonResetFields title='Limpiar' onClickCancel={() => setParamSearch(search)}/>
                                                </div>
                                            </div>
                                        </FormFields>
                                    </Search>
                                </div>
                                <div className="col-sm-12 col-md-3 col-lg-3">
                                    <UserPermissionsComponent accion={['admin','crearVenta','admVenta']}>
                                        <Link className="btn btn-outline-primary float-right" to="/services/create-rentals">
                                            <i className="fa fa-plus mr-2"/>
                                            Nuevo Alquiler
                                        </Link>
                                    </UserPermissionsComponent>
                                </div>
                            </div>  
                            <Table
                                columns={columns}
                                list={listaServicio}
                                page={serviciosPag.page}
                                sizePerPage={paramSearch.take}
                                totalSize={serviciosPag.total}
                                onTableChange={handleTableChange}
                            />
                            <Loading isLoading={isLoading}/>  
                            <ModalComponents
                                size='md'
                                titulo={"Actividad Estado"}
                                show={mdEstado}
                                hide={() => setMdEstado(false)}
                            >
                                <div className='row'>
                                    <div className='col-12'>
                                        <FormFields
                                            initialValues = {modelActividad}
                                            onSubmit = {(values, onSubmitProps) => onClickGuardar(values,onSubmitProps)}
                                            validationSchema = {avanceEstadoSchema}
                                        >
                                        <div className='row'>
                                            <div className='col-sm-6 col-md-5 col-lg-5' style={{zIndex:3}}>
                                                <SelectFields
                                                    label={"Estado"}
                                                    name="estadoId"
                                                    data={estado.map(item => ({value:item.id, label: item.nombre }))}
                                                />
                                            </div>
                                            <div className='col-sm-12 col-md-5 col-lg-5'>
                                                <InputFields
                                                    label={"Comentario"}
                                                    as="textarea" 
                                                    rows={1}
                                                    name="comentario"
                                                    size = "sm"
                                                    isRequired = {false}
                                                />
                                            </div>
                                           
                                            <div className='col-sm-6 col-md-2 col-lg-2' style={{marginTop:'20px'}}>
                                            <ButtonFields
                                                className='btn btn-primary float-right ml-3 btn-sm'
                                                title = {"Guardar"}/>
                                            </div>
                                        </div>
                                        </FormFields>
                                    </div>
                                    <div className='col-12'>
                                        <div className="mt-4">
                                            <div className="timeline alin">
                                            {
                                                actividadEstado && actividadEstado.map((item,index) => (
                                                    <div className="timeline-wrapper timeline-inverted timeline-wrapper-warning" style={{zIndex:1}} key={index}>
                                                        <div className="timeline-badge"></div>
                                                        <div className="timeline-panel p-2">
                                                            <div className="timeline-heading">
                                                                <p className="text-primary text-uppercase float-right">{item.estado}</p>
                                                                <p className="font-weight-bold">{item.nombreUsuario}</p>
                                                            </div>
                                                            <div className="timeline-body">
                                                                <p className='text-dark text-break'>{item.comentario}</p>
                                                            </div>
                                                            <div className="timeline-footer d-flex align-items-center flex-wrap">
                                                                <span className="ml-md-auto font-weight-bold">{item.fecha}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ModalComponents>

                            
                            {/* Comentario Nota */}
                            <ModalComponents
                                size='md'
                                titulo={"Actividad Nota"}
                                show={mdNota}
                                hide={() => setMdNota(false)}
                            >
                                <div className='row'>
                                    <div className='col-12'>
                                        <FormFields
                                            initialValues = {modelNota}
                                            onSubmit = {(values, onSubmitProps) => onClickGuardarNota(values,onSubmitProps)}
                                        >
                                        <div className='row'>
                                            <div className='col-12'>
                                                <InputFields
                                                    label={"Nota"}
                                                    as="textarea" 
                                                    rows={1}
                                                    name="nota"
                                                    size = "sm"
                                                    isRequired = {false}
                                                />
                                            </div>
                                           
                                            <div className='col-12'>
                                                <ButtonFields title = {"Guardar"}/>
                                            </div>
                                        </div>
                                        </FormFields>
                                    </div>
                                </div>
                            </ModalComponents>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
        </div>
    );
}

export default ListRentals;