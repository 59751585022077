import React, {useState} from 'react';
import ErrorMessage from '../ErrorMessage';
import { useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';
import { LabelFields } from './LabelFields';

function AsyncSelectFields({name,label,idFields = "id", isRequired = true, onSelect = () => {}, ...otherProps }) {
    const { errors,setFieldValue,touched, values } = useFormikContext();
    
    const [selectedValue, setSelectedValue] = useState({});

    const handleChange = value => {
        setSelectedValue(value);
    }

    const customThemeFn = (theme) => ({ 
        ...theme,
        spacing: {
          ...theme.spacing,
          controlHeight: 3,
          baseUnit: 3
        }
        
    })
    return (
        <div className='mb-2'>
            {
                label && <LabelFields label={label} isRequired = {isRequired}/>
            }
            <AsyncSelect
                cacheOptions
                defaultOptions
                theme={customThemeFn}
                value = {values[name] === selectedValue[idFields] && selectedValue}
                getOptionLabel={e => e.nombre}
                getOptionValue={e => e[idFields]}
                onChange={(item) => {
                    setFieldValue(name,item[idFields]); 
                    handleChange(item);
                    onSelect(item);
                }}         
                {...otherProps}
            />
            <ErrorMessage error={errors[name]} visible={touched[name]}  />
        </div>
    );
}

export default AsyncSelectFields;