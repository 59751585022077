import React,{ useContext } from 'react';
import { decodeToken } from "react-jwt";
import { Context } from '../../context/AuthContext';

export default function UserPermissionsComponent({accion = [], children}) {
    const { token } = useContext(Context);
    var decode = decodeToken(token);

    if(!decode.hasOwnProperty('role')){
      return null;
    }
    if(Array.isArray(decode.role)){
      const tienePermiso = decode.role.some(role => accion.includes(role));
      return tienePermiso ? <>{children}</> : null;
    }
    if(!Array.isArray(decode.role)){
      const tienePermiso = [decode.role].some(role => accion.includes(role));
      return tienePermiso ? <>{children}</> : null;
    }
    return null;
    
}
