import * as Yup from 'yup'

export const createShoppingSchema = Yup.object().shape({
    proveedorId: Yup.string()
        .required("El campo es requerido")
        .nullable()
        .label("Proveedor"),
    precioTotal: Yup.string(),
    montoAgrabada: Yup.string(),
    impuesto: Yup.string(),
    fecha: Yup.string()
        .required("El campo es requerido")
        .label("Proveedor"),
    fileCompas:Yup.object({
            contenido: Yup.string().min(100, 'Texto muy corto'),
            extension: Yup.string().min(2, 'Texto muy corto'),
    }),
    deuda:Yup.string(),
    estado: Yup.string(),
    tipoPagoId: Yup.string()
        .required("El campo es requerido"),
    detalle: Yup.array(),
    actividadPago: Yup.array()
});

export const editSalesSchema = Yup.object().shape({
    titulo: Yup.string()
        .min(5, 'Texto muy corto')
        .required('El campo es requrido')
});

export const addPagoCompraSchema = Yup.object().shape({
    compraId: Yup.string(),
    fechaPago: Yup.date()
            .required('El campo es requrido'),
    numeroRecibo: Yup.string(),
    monto: Yup.number()
            .typeError("No es un número")
            .required('El campo requrido')
});