import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Row } from "react-bootstrap";
import { getListaShopping, deleteCategoria } from '../../services/ProductService';
import { getComboProveedor } from '../../services/SupplierServices';
import { getComboEmpleado } from '../../services/PersonServices';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../utils/helper/AlertService';
import { Search, Table, FormFields, SelectFields, ButtonFields, ButtonResetFields } from '../../components/Index';
import UserPermissionsComponent from '../auth/UserPermissionsComponent';

const estadoDeuda = [
    {value:"01", label:"Con Deuda"},
    {value:"02", label:"Sin Deuda"}
]

const search = {
    skip: 1,
    take: 10,
    search: "",
    codigoDeuda:"",
    proveedorId: null,
    userId: null
}
const ListShopping = () => {
    const [ compras, setCompras ] = useState([]);
    const [ paramSearch, setParamSearch ] = useState(search);
    const [ personaPag, setPersonaPag ] = useState({});
    const [ proveedor, setProveedor ] = useState([]);
    const [ empleado,setEmpleado ] = useState([]);

    const onClickEliminar = (id) => {
        showConfirAlert({
            title: '¿Estás seguro de eliminar?',
            text: "¡No padrás revertir esto!",
            preConfirm: () => {
                deleteCategoria(id).then(() => {
                    showSuccessAlert('El registro fue eliminado con éxito');
                    getData(search);
                }).catch((error) => {
                    showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
                });
            }
        });
    }
    const onInputSearch = ({target}) => {
        const { value } = target;
        setParamSearch({...paramSearch,search:value})
    }

    const handleTableChange = (page, sizePerPage) => {
        setParamSearch({
            ...paramSearch,
            skip:sizePerPage.page,
            take:sizePerPage.sizePerPage,
        })
    }

    const getData = async (param) => {
        try {
            const data = await getListaShopping(param);
            setCompras(data.items);
            setPersonaPag(data);
        }
        catch {

        }
    }

    const getFech = async () => {
        const rep_prov = await getComboProveedor("");
        const rep_emp = await getComboEmpleado("");
        setProveedor(rep_prov);
        setEmpleado(rep_emp);
    }

    useEffect(() =>{
        getFech();
    },[])

    useEffect(() => {
        getData(paramSearch);
    },[paramSearch])

    const columns = [
        { 
            dataField: "proveedor", 
            text: "Proveedor"
        },
        {
            dataField: "telefono",
            text: "Telefono"
        },
        {   
            dataField: "fecha",     
            text: "Fech. Compra"
        },
        {
            dataField: "precioTotal",     
            text: "Precio Total"
        },
        {
            dataField: "deuda",     
            text: "Deuda",
            formatter: (cellContent, row) => {
                if (cellContent === 0) {
                    return (
                    <p className="text-primary m-0">Sin Deuda</p>
                    );
                } else if (cellContent > 0 ) {
                    return (
                        <p className="text-danger font-weight-bold m-0">{parseFloat(row.deuda).toFixed(2)}</p>
                    );
                }
            }
        },
        { 
            dataField: "empleado", 
            text: "Empleado" 
        },
        {   
            dataField: "estado", 
            text: "Estado",
            classes: 'py-1',
            formatter: (cellContent, row) => {
                if (cellContent === '02') {
                    return (
                    <label className="badge badge-primary m-0">Cerrado</label>
                    );
                } else if (cellContent === '01' ) {
                    return (
                    <label className="badge badge-danger m-0">Con Deuda</label>
                    );
                }
            }
        }, 
        {
            dataField: "opciones", 
            text: "Opciones",
            sort: false,
            align: 'center',
            classes: 'py-1',
            formatter: (cellContent, row) => {
            return (
                <div>
                    <Link className='text-decoration-none' to={"/shopping/detail/"+row.comprasId}><i className="ti-eye text-primary mr-2" data-toggle="tooltip" data-placement="right" title="Detalle" style={{fontSize:'15px'}}/></Link>
                    <UserPermissionsComponent accion={['admin','eliminarCompra']}>
                        <i className="ti-trash text-danger" data-toggle="tooltip" data-placement="right" title="Eliminar" onClick={() => onClickEliminar(row.comprasId)} style={{fontSize:'15px'}}/>
                    </UserPermissionsComponent>
                    
                </div>
            );
            }
        }
          
    ]

    return (
        <div>
            <Row >
                <div className="col-12">
                    <Card>  
                        <Card.Body>
                            <div className="row mb-4 justify-content-between">
                                <div className = "col-lg-2 d-none d-sm-none d-md-block">
                                    {/* <Link className="float-left mr-2" to={"/cuadrodemando"}>
                                        <i className=" mdi mdi-arrow-left-drop-circle-outline ml-2  text-info" style={{fontSize:30}}></i>
                                    </Link> */}
                                    <h4 className="card-title float-left mt-2">Lista Compras</h4>
                                </div>
                                <div className = "col-lg-6 col-ms-12 pb-2">
                                    <Search
                                        onSearchInput={onInputSearch}
                                        value={paramSearch.search}
                                        onRefesh={() => setParamSearch(search)}
                                    >
                                        <FormFields
                                            initialValues={paramSearch}
                                            onSubmit={(values) => setParamSearch(values)}
                                        >
                                            <div className='row'>
                                                <div className='col-ms-12 col-md-6 col-lg-6 mb-2'>
                                                    <SelectFields 
                                                        label={"Proveedor"}
                                                        name="proveedorId"
                                                        data={proveedor.map((item) =>({value:item.id, label: item.nombre }))}
                                                    />
                                                </div>
                                                <div className='col-ms-12 col-md-6 col-lg-6 mb-2'>
                                                    <SelectFields 
                                                        label={"Empleado"}
                                                        name={"userId"}
                                                        data={empleado.map((item) =>({value:item.userId, label: item.nombre }))}
                                                    />
                                                </div>
                                                <div className='col-ms-12 col-md-6 col-lg-6'>
                                                    <SelectFields
                                                        label={"Deuda"}
                                                        name="codigoDeuda"
                                                        isRequired={false}
                                                        data={estadoDeuda}
                                                    />
                                                </div>
                                                <div className='col-12'>
                                                    <ButtonFields title='Buscar'/>
                                                    <ButtonResetFields title='Limpiar' onClickCancel={() => setParamSearch(search)}/>
                                                </div>
                                            </div>
                                        </FormFields>
                                    </Search>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <UserPermissionsComponent accion={['admin','crearCompra']}>
                                        <Link className="btn btn-outline-primary float-right" to="/shopping/create">
                                            <i className="fa fa-plus mr-2"/>
                                            Nueva Compra
                                        </Link>
                                    </UserPermissionsComponent>
                                </div>
                            </div>    
                            <Table
                                columns={columns}
                                list={compras}
                                keyField='comprasId'
                                page={personaPag.page}
                                sizePerPage={paramSearch.take}
                                totalSize={personaPag.total}
                                onTableChange={handleTableChange}
                            />
                        </Card.Body>
                    </Card>
                </div>
            </Row>
        </div>
    );
}

export default ListShopping;