import React from 'react';
import { ButtonFields, ButtonResetFields, FormFields, InputFields } from '../../../components/Index';
import { createSupplierSchema } from '../../../utils/validations/PersonValidation'


function CreateSupplier({modelSuppier = {}, onClickSend}) {

    return (
        <FormFields
            initialValues = {modelSuppier}
            onSubmit = {(values, onSubmitProps) => onClickSend(values,onSubmitProps)}
            validationSchema = {createSupplierSchema}
        >
            <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-4'>
                    <InputFields
                        label={"RUC / DNI"}
                        type="text"
                        name="ruc"
                        size = "sm"
                    />
                </div>
                <div className='col-sm-12 col-md-12 col-lg-8'>
                    <InputFields
                        label={"Nombre de Empresa"}
                        type="text"
                        name="compania"
                        size = "sm"
                    />
                </div>
                <div className='col-sm-12 col-md-12 col-lg-4'>
                    <InputFields
                        label={"Dirección"}
                        as="textarea" 
                        rows={1}
                        name="direccion"
                        size = "sm"
                    />
                </div>
                <div className='col-sm-12 col-md-12 col-lg-4'>
                    <InputFields
                        label={"Teléfono/Celular"}
                        type="text"
                        name="telefono"
                        size = "sm"
                    />
                </div>
                <div className='col-sm-12 col-md-12 col-lg-4'>
                    <InputFields
                        label={"Teléfono/Celular Dos"}
                        type="text"
                        name="telefonoDos"
                        size = "sm"
                        isRequired = {false}
                    />
                </div> 
                <div className='col-12 mt-2'>
                    <ButtonFields/>
                    <ButtonResetFields/>
                </div>
            </div>
        </FormFields>
    );
}

export default CreateSupplier;