import React, { useEffect, useState } from 'react';
import { Row, Card } from 'react-bootstrap';
import { Table, Search} from '../../../components/Index';
import { getListarAniversario } from '../../../services/SalesServices';

const search = {
    skip: 1,
    take: 10,
    numeroDias: 0
}
export default function ListaAniversarios() {
    const [ listaPersona, setListaPersona ] = useState([]);
    const [ personaPag, setPersonaPag ] = useState({});
    const [ paramSearch, setParamSearch ] = useState(search);

    const handleTableChange = (page, sizePerPage) => {
        setParamSearch({
            ...paramSearch,
            skip:sizePerPage.page,
            take:sizePerPage.sizePerPage,
        })
    }

    const onInputSearch = ({target}) => {
        const { value } = target;
        var val = value ? value: 0;
        setParamSearch({...paramSearch, numeroDias:  val})
       
        
    }
    const getData = async (param) => {
        var data = await getListarAniversario(param);
        setPersonaPag(data);
        setListaPersona(data.items);
    }

    useEffect(() => {
        getData(paramSearch);
    },[paramSearch]);

    const columns = [
        { 
            dataField: "nombreCliente", 
            text: "Cliente"
        },
        { 
            dataField: "nombreFallecido", 
            text: "Fallecido"
        },
        { 
            dataField: "fechaFallecimiento", 
            text: "Fech. Fallecimiento",
        },
		{ 
            dataField: "direccion", 
            text: "Dirección" 
        },
        {   
            dataField: "telefono",     
            text: "Celular"
        },
        { 
            dataField: "diasFaltantes", 
            text: "Dias",
            formatter: (cellContent, row) => {
                if (cellContent <= 30) {
                    return (
                    <p className="text-danger font-weight-bold m-0">{row.diasFaltantes}</p>
                    );
                } else if (cellContent > 30 ) {
                    return (
                        <p className="font-weight-bold m-0">{row.diasFaltantes}</p>
                    );
                }
            }
        },
        { 
            dataField: "fechaAniversario", 
            text: "Fech. Aniversario",
        },
    ]
  return (
    <div>
        <Row >
            <div className="col-12">
                <Card>  
                    <Card.Body>
                        <div className="row mb-4 justify-content-between">
                            <div className = "col-lg-3 d-none d-sm-none d-md-block">
                                <h4 className="card-title float-left mt-2">Seguimiento Cliente</h4>
                            </div>
                            <div className = "col-lg-6 col-ms-12 pb-2">
                                <Search
                                    onSearchInput={onInputSearch}
                                    value={paramSearch.numeroDias}
                                    onRefesh={() => setParamSearch(search)}
                                    isSearchDrowp={false}
                                    nameInput='Buscar dias'
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-3">
                               
                            </div>
                        </div>  
                        <Table
                            keyField='pedidoId'
                            columns={columns}
                            list={listaPersona}
                            page={personaPag.page}
                            sizePerPage={paramSearch.take}
                            totalSize={personaPag.total}
                            onTableChange={handleTableChange}
                        />
                    </Card.Body>
                </Card>
            </div>
        </Row>
    </div>
  )
}
