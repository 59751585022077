import React, { useEffect, useState,useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Card, Row } from 'react-bootstrap';
import { createRentalSchema } from '../../utils/validations/RentalValidation';
import { ButtonFields, FormFields, RadioGroupFields, SelectFields, InputBasic, InputFields, AsyncSelectFields, ModalComponents } from '../../components/Index';
import { CrearRenta, getTipoComprobante, getTipoPago } from '../../services/SalesServices';
import AddProductoComponents from '../products/components/AddProductoComponents';
import { getComboCliente, crearCliente } from '../../services/PersonServices';
import TablaProductosPedidos from '../products/components/TablaProductosPedidos';
import PaymentActivity from '../sales/components/PaymentActivity';
import PaymentRates from '../sales/components/PaymentRates';
import FormCreatePerson from '../person/components/FormCreatePerson';
import AddServicioComponents from '../products/components/AddServicioComponents';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../utils/helper/AlertService';


const MD_PROD = "01";
const MD_SERV = "02";

const newModel = {
  clienteId:'',
  primerNombre:'',
  segundoNombre:'',
  apellidoPaterno:'',
  apellidoMaterno:'',
  nroDoi:'',
  direccion:'',
  telefonoUno:'',
  telefonoDos:'',
  telefonoTres:'',
  email:'',
  sexo:''
}

function CreateRentals() {
    const formikRef = useRef(null);
    const navigate = useNavigate();
    const [ comprobante, setComprobante ] = useState([]);
    const [ pago, setPago ] = useState([]);
    const [ producto, setProducto ] = useState([]);
    const [ mdProducto, setMdProducto ] = useState({mdModal: false, cod: "", titulo:""});
    const [ modalCliente, setModalCliente ] = useState(false);
    const [ precioTotal, setPrecioTotal ] = useState('');
    const [ showPagos, setShowPagos ] = useState(false);
    const [ numComprobante, setNumComprobante ] = useState("");
    const [ mdRegitroPago, setMdRegistroPago ] = useState(false);
    const [ listaPagos, setListaPagos] = useState([]);
    const [ totalPagoDeuda, setTotalPagoDeuda ] = useState(0);
    const [ montoDeuda, setMontoDeuda ] = useState(0);

    const onClickCreateRentals = (values, onSubmitProps) => {
      if(producto.length < 1) {
        showErrorAlert('No se encuntra nigún producto selecionado');
        return;
      }
      values.detalle = producto;
      values.precioBruto = values.precioNeto;
      values.numComprobante = numComprobante;
      values.actividaPago = showPagos ? listaPagos: [];
      if(values.tipoPagoId !== "c81c314c-40a5-467c-b8ee-b2b6513b5704"){
        values.deuda = 0;
      }
      CrearRenta(values).then((response) => {
        showSuccessAlert('Registro Exitoso')
        onSubmitProps.setSubmitting(false)
        onSubmitProps.resetForm();
        setProducto([]);
        setListaPagos([]);
        setNumComprobante("");
        setMontoDeuda(0);
        setShowPagos(false);
        navigate("/services/details-sales/"+response.pedidosId);
      }).catch((error) => {
        showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
      });
    }
    const onClickSendClient = (model, onSubmitProps) => {
      showConfirAlert({
          title: "Desea registrar nuevo cliente",
          text: "Verifique que todos los campos esten registrados correctamente",
          preConfirm: () => {
              crearCliente(model).then(() => {
                showSuccessAlert('Registro Exitoso');
                onSubmitProps.setSubmitting(false)
                onSubmitProps.resetForm();
                setModalCliente(false);
              }).catch((error) => {
                  showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
              });
          }
      });
    }

    const recalcularMontoPrecio = (value) => {
      if(isNaN(value)){
        return;
      }
      const nuevosProducto = producto.map(objeto => {
        return { ...objeto, precio: '',precioTotal:''};
      });

      setProducto(nuevosProducto);
      var igv = parseFloat((value*18)/100).toFixed(2);
      var op = parseFloat(value - igv).toFixed(2);
      formikRef.current.setFieldValue('impuesto',igv);
      formikRef.current.setFieldValue('montoAgravada',op);
      setPrecioTotal(value);
    }

    const onAddProducto = (item) => {
      let prod = producto.find(x => x.productoId === item.productoId)
      if(prod){
        showSuccessAlert('No es posible agregar productos iguales.')
      }
      else{
        setProducto([...producto,item]);
      }
    }

    const onClickDelete = (id) => {
      showConfirAlert({
        title: '¿Estás seguro de eliminar?',
        text: "¡No padrás revertir esto!",
        preConfirm: (result) => {
          if (result) {
            showSuccessAlert('El registro fue eliminado con éxito')
            let new_productos = producto.filter((item) => item.productoId !== id);
            setProducto(new_productos);
          }
        }
      });
    }
  
    const onClickDeleteActividadPago = (index) => {
      var new_actividad = listaPagos.filter((_, i) => i !== index);;
      setListaPagos(new_actividad);
    }
  
    const getData = async () => {
      try {
        const resp_comprobante = await getTipoComprobante();
        const resp_pago = await getTipoPago();
        let item = resp_comprobante.find(x => x.id === 'd4521a44-ae7e-4a62-8797-b6c52e620488');
        if(item){
          setNumComprobante(item.numComprobante)
        }
        setComprobante(resp_comprobante);
        setPago(resp_pago);
      }catch(e){
        showErrorAlert('Hubo un problema de registro',  e.apiMessage || 'Error desconocido');
      }
      
    }
  
    const onSelectTipoPago = (item) => {
      if(item.value === 'c81c314c-40a5-467c-b8ee-b2b6513b5704'){
        setShowPagos(true)
      }else {
        setShowPagos(false);
      }
    }
  
    const fechDataClient = async (value) => {
      const resp_cliente = await getComboCliente(value);
      return resp_cliente;
    }
  
    useEffect(() => {
      getData();
    },[])
  
    useEffect(() => {
      let reduce = producto.reduce((acumulador, actual) => acumulador + actual.precioTotal, 0);
      if(reduce > 0) {
        var igv = parseFloat((reduce*18)/100).toFixed(2);
        var op = parseFloat(reduce - igv).toFixed(2);
        setPrecioTotal(reduce);
        formikRef.current.setFieldValue('impuesto',igv);
        formikRef.current.setFieldValue('montoAgravada',op);
        formikRef.current.setFieldValue('precioNeto',reduce);
        formikRef.current.setFieldValue('precioBruto',reduce);
      }
    },[producto]);
  
    useEffect(() => {
      let reduce = listaPagos.reduce((acumulador, actual) => acumulador + parseFloat(actual.monto), 0);
      let calculo = precioTotal - reduce;
      formikRef.current.setFieldValue('deuda',calculo);
      setMontoDeuda(calculo);
      setTotalPagoDeuda(reduce);
    },[listaPagos, precioTotal])

    return (
      <div>
        <Row>
          <div className='col-12'>
            <Card >
              <Card.Body>
                <h4 className="card-title">Registrar nueva Alquiler</h4>
                  <FormFields
                    initialValues = {{
                      numComprobante: "",
                      impuesto: 0,
                      montoAgravada:0,
                      precioBruto: 0,
                      descuento:0,
                      direccion:'',
                      precioNeto: 0,
                      tipoPagoId: '',
                      tipoComprobanteId: 'd4521a44-ae7e-4a62-8797-b6c52e620488',
                      clienteId: '',
                      descripcion: '',
                      detalle: [],
                      actividaPago:[]
                    }}
                    onSubmit = {(values, onSubmitProps) => onClickCreateRentals(values, onSubmitProps)}
                    validationSchema = { createRentalSchema }
                    formikRef={formikRef}
                  >
                  <div className='row'>
                    <div className='col-12'>
                      <h4 className='float-right mr-3 text-info'>N°: {numComprobante}</h4>
                    </div>
                    <div className='col-12 mb-2 float-right'>
                      <div className='float-right'>
                        <RadioGroupFields
                          data={comprobante.map(item => ({value:item.id, label: item.nombre, numComprobante: item.numComprobante }))}
                          name="tipoComprobanteId"
                          onSelect={(item) => setNumComprobante(item.numComprobante)}
                        />
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-6 product-item stretch-card'>
                      <div className="card mb-3">
                        <div className="card-body row">
                          <div className='col-11 pb-2'>
                            <AsyncSelectFields
                              label={"Cliente(a)"}
                              name="clienteId"
                              loadOptions = {fechDataClient}
                            />
                          </div>
                          <div className='col-1 float-left p-0 pt-4'>
                            <i className="fa fa-plus-square text-info" onClick={()=>setModalCliente(true)}/>
                          </div>
                        </div> 
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-6 product-item stretch-card mb-3'>
                      <div className="card mb-3">
                        <div className="card-body row">
                          <div className='col-sm-12 col-md-7 col-lg-7'>
                            <InputFields
                              label={"Dirección"}
                              as="textarea" 
                              rows={1}
                              name="direccion"
                              size="sm"
                            />
                          </div>
                          <div className='col-sm-12 col-md-5 col-lg-5'>
                            <SelectFields
                              label={"Tipo de Pago"}
                              name="tipoPagoId"
                              data={pago.map(item => ({value:item.id, label: item.nombre,data:item }))}
                              onSelect = {(item) => onSelectTipoPago(item)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <div className='col-12 '>
                      <button className='btn btn-outline-primary btn-sm float-right' onClick={() => setMdProducto({...mdProducto,mdModal: true, cod: MD_PROD, titulo: "Agregar Producto"})}><i className='fa fa-plus mr-2'/>Productos</button> 
                      <button className='btn btn-outline-primary btn-sm float-right mr-3' onClick={() => setMdProducto({...mdProducto,mdModal: true, cod: MD_SERV, titulo: "Agregar Servicio"})}><i className='fa fa-plus mr-2'/>Servicicios</button>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="container-fluid d-flex justify-content-center w-100">
                      <TablaProductosPedidos 
                        producto={producto}
                        onClickDelete={(id) => onClickDelete(id)}
                      />
                    </div>
                    {
                      producto && producto.length <= 0 && <div className='col-12'><p className='text-danger'>(*) La lista de Productos no tiene que estar vacio.</p></div>
                    }
                    <div className="container-fluid mt-2 w-100">
                      <div>
                        <h6 className="text-right text-muted">Op. Gravada S/: <InputBasic id = "montoAgravada" placeholder = "0" name="montoAgravada" disabled/></h6>
                        <h6 className="text-right text-muted">IGV 18% S/: <InputBasic id = "impuesto" placeholder = "0" name="impuesto" disabled/></h6>
                        <h4 className="text-right mb-5">Total S/: <InputBasic id = "precioNeto" placeholder = "0" name="precioNeto" onChangeInput={(value) => recalcularMontoPrecio(value)}/></h4>
                      </div>
                      <hr/>
                    </div>
                  </div>
                  {
                    showPagos && 
                    <div className='row pt-5 pb-5'>
                      <div className='col-12 pb-2'>
                        <button className='btn btn-inverse-primary btn-icon float-right' onClick={() => setMdRegistroPago(true)}><i className='fa fa-plus'></i></button>
                      </div>
                      <div className='col-12'>
                        <PaymentActivity listaPagos={listaPagos} handleDelete={(index) => onClickDeleteActividadPago(index)}/>
                        <h4 className="text-right mt-3">Deuda Pendiente S/. {montoDeuda}</h4>
                      </div>
                      
                    </div>
                  }
                  <div className='mb-4'>
                    <InputFields
                      label={"Descripción"}
                      type="text"
                      isRequired = {false}
                      name="descripcion"
                      size = "sm"
                    />
                    
                  </div>
                  <div>
                    <ButtonFields/>
                  </div>
                </FormFields>
              </Card.Body>
                
              {/* MODAL CLIENTE */}
              <ModalComponents size={"lg"} 
                show={modalCliente} 
                hide={()=>setModalCliente(false)}  
                titulo={"Crear Cliente"}
              >
                <FormCreatePerson 
                  modelCliente={newModel} 
                  onClickSend={(values,onSubmitProps) => onClickSendClient(values,onSubmitProps)}
                />
              </ModalComponents>

              {/* MODAL PRODUCTOS Y SERVICICIOS */}
              <ModalComponents
                show={mdProducto.mdModal}
                hide={() => setMdProducto({...mdProducto, mdModal:false})}
                titulo={mdProducto.titulo}
                size={"md"}
              >
                { mdProducto.cod === MD_PROD && <AddProductoComponents tipo='02' onAddProducto={(item) => onAddProducto(item)} /> }
                { mdProducto.cod === MD_SERV && <AddServicioComponents onAddServicio={(item) => onAddProducto(item)}/>}
              </ModalComponents>

              {/* ACTIVIDAD PAGO */}
              <ModalComponents
                size={"md"}
                show={mdRegitroPago}
                hide={() => setMdRegistroPago(false)}
                titulo={"Registro de Pagos"}
              >
                <PaymentRates onChangeData={(data) => setListaPagos([...listaPagos,data])} precioTotal={precioTotal} totalPagoDeuda={totalPagoDeuda}/>
              </ModalComponents>
            </Card>
          </div>
        </Row>
      </div>
    );
}

export default CreateRentals;