import React, { useEffect, useState, useRef } from 'react';
import { Card, Row } from 'react-bootstrap';
import { ButtonFields, FormFields, SelectFields, InputFields, ModalComponents, ButtonResetFields, InputFileFields } from '../../components/Index';
import { createShoppingSchema, addPagoCompraSchema } from '../../utils/validations/ShoppingValidation'
import { addProductSchema } from '../../utils/validations/ProductValidation';
import { createShopping, getListaProductoCombo } from '../../services/ProductService';
import { getTipoPago } from '../../services/SalesServices';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../utils/helper/AlertService';
import { getComboProveedor } from '../../services/SupplierServices';
import { format, parseISO } from 'date-fns';

function CreateShopping() {
    const formikRef = useRef(null);
    const currentDate = format(new Date(), 'yyyy-MM-dd');
    const initProd = {
        productoId:'',
        stock:'',
        precio:''
    };
    const [producto, setProducto] = useState([]);
    const [detalle, setDetalle] = useState({});
    const [precioTotal, setPrecioTotal] = useState(0);
    const [montoDeuda, setMontoDeuda] = useState(0);
    const [mdProd, setMdProd] = useState(false);
    const [modelProd, setModelProd] = useState(initProd);
    const [cmbProducto, setCmbProducto ] = useState([]);
    const [cmbProveedor, setCmbProveedor ] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [tipoPago,setTipoPago] = useState([]);
    const [actividadPago, setActividadPago] = useState([]);
    const [showActividaPago,setShowActividadPago] = useState(false);
    const [mdActPag, setMdActPag] = useState(false);
    const [totalPagoDeuda, setTotalPagoDeuda] = useState(0);
    const [impuesto, setImpuesto] = useState(0);
    const [opAgrav, setOpAgrav] = useState(0);

    const onClickSend = (model, onSubmitProps) => {
        model.detalle = producto;
        model.precioTotal = precioTotal;
        model.montoAgrabada = opAgrav;
        model.impuesto = impuesto;
        model.actividadPago = actividadPago;
        if(model.estado === "02") {
            model.deuda = 0;
        }
        showConfirAlert({
            title: "Desea registrar nueva Compra",
            text: "Verifique que todos los campos requeridos no esten vacíos",
            preConfirm: () => {
                createShopping(model).then(() => {
                    showSuccessAlert('Registro Exitoso');
                    onSubmitProps.setSubmitting(false)
                    onSubmitProps.resetForm();
                    setShowActividadPago(false);
                    setProducto([]);
                    setActividadPago([])
                }).catch((error) => {
                    showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
                });
            }
        });
    }

    const onAddActividadPago = (values, onSubmitProps) => {
        var deuda = totalPagoDeuda + parseFloat(values.monto)
        if(precioTotal >= deuda){
            setActividadPago([...actividadPago,values]);
            onSubmitProps.resetForm();
            showSuccessAlert("Registro Exitoso");
            setMdActPag(false);
        } 
        else{
            showErrorAlert('', 'El monto de pago no puede ser mayor que el precio total');
        }
    }

    const onClickAdd = (values, onSubmitProps) => {
        let prod = producto.find(x => x.productoId === values.productoId)
        if(prod){
            prod.precio = values.precio;
            prod.precioVenta = values.precioVenta;
            prod.stock = values.stock;
            prod.precioTotal = parseFloat(values.precio) * parseInt(values.stock);
            let reduce = producto.reduce((acumulador, actual) => acumulador + actual.precioTotal, 0);
            var igv = parseFloat((reduce*18)/100).toFixed(2);
            var op = parseFloat(reduce - igv).toFixed(2);
            setImpuesto(igv);
            setOpAgrav(op);
            setPrecioTotal(reduce);
            setMdProd(false);
            setModelProd(initProd); 
        }
        else {
            values.nombre = detalle.label;
            values.precioTotal = parseInt(values.stock) * parseFloat(values.precio);
            setProducto([...producto,values]);
            onSubmitProps.resetForm();
        }
        
    }
    
    const onClickDelete = (id) => {
        showConfirAlert({
            title: '¿Estás seguro de eliminar?',
            text: "¡No padrás revertir esto!",
            preConfirm: (result) => {
              if (result) {
                showSuccessAlert('El registro fue eliminado con éxito')
                let new_productos = producto.filter((item) => item.productoId !== id);
                setProducto(new_productos);
              }
            }
          });
    }

    const onClickDeleteActividadPago = (index) => {
        var new_actividad = actividadPago.filter((_, i) => i !== index);
        setActividadPago(new_actividad);
    }

    const onSelectTipoPago = (id) => {
        if(id === 'c81c314c-40a5-467c-b8ee-b2b6513b5704'){
            formikRef.current.setFieldValue('estado',"01");
            setShowActividadPago(true);
        }
        else{
            formikRef.current.setFieldValue('estado',"02");
            setShowActividadPago(false);
            setActividadPago([]);   
        }
    }

    const onClickShowModal = (item) => {
        setMdProd(true);
        setIsUpdate(true);
        setModelProd(item);
    }

    const fechData = async () => {
        const rep_prod = await getListaProductoCombo("");
        const rep_prov = await getComboProveedor("");
        const rep_tp = await getTipoPago();
        setCmbProducto(rep_prod);
        setCmbProveedor(rep_prov);
        setTipoPago(rep_tp);
    }
    
    useEffect(() => {
        fechData()
    },[])

    useEffect(() => {
        let reduce = producto.reduce((acumulador, actual) => acumulador + parseFloat(actual.precioTotal), 0);
        var igv = parseFloat((reduce*18)/100).toFixed(2);
        var op = parseFloat(reduce - igv).toFixed(2);
        setImpuesto(igv);
        setOpAgrav(op);
        setPrecioTotal(reduce);
    },[producto]);

    useEffect(() => {
        let reduce = actividadPago.reduce((acumulador, actual) => acumulador + parseFloat(actual.monto), 0);
        let calculo = precioTotal - reduce;
        formikRef.current.setFieldValue('deuda',calculo);
        setMontoDeuda(calculo);
        setTotalPagoDeuda(reduce);
    },[actividadPago, precioTotal])

    return (
        <div>
            <Row>
                <div className='col-12'>
                    <Card>
                        <Card.Body>
                            <h4 className="card-title">Registrar nueva Compra</h4>
                            <FormFields
                                initialValues = {{
                                    proveedorId:'',
                                    fecha: currentDate,
                                    precioTotal: '',
                                    deuda: 0,
                                    estado:'',
                                    impuesto: 0,
                                    montoAgrabada: 0,
                                    tipoPagoId:'',
                                    fileCompas: {
                                        contenido:"",
                                        extension:""
                                    },
                                    detalle: []
                                }}
                                onSubmit = {(values, onSubmitProps) => onClickSend(values,onSubmitProps)}
                                validationSchema = {createShoppingSchema}
                                formikRef={formikRef}
                            >

                            <div className='row'>
                                <div className='col-sm-12 col-md-4 col-lg-6' style={{zIndex:3}}>
                                    <SelectFields
                                        label={"Proveedor"}
                                        name="proveedorId"
                                        data={cmbProveedor.map((item) =>({value:item.id, label: item.nombre }))}
                                    />
                                </div>
                                <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <InputFields
                                        label={"Fecha Compra"}
                                        type="date"
                                        name="fecha"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-sm-12 col-md-4 col-lg-3' style={{zIndex:3}}>
                                    <SelectFields
                                        label={"Tipo de Pago"}
                                        name="tipoPagoId"
                                        data={tipoPago.map(item => ({value:item.id, label: item.nombre,data:item }))}
                                        onSelect = {(item) => onSelectTipoPago(item.value)}
                                    />
                                </div>
                                <div className='col-sm-12 col-md-4 col-lg-6'>
                                    <InputFileFields 
                                        label={"Adjuntar Comprobante"}
                                        name={"fileCompas"}
                                    />
                                </div>
                                <div className='col-12 mt-3'>
                                    <button className='btn btn-inverse-success btn-icon float-right' 
                                        onClick={() => { 
                                            setModelProd(initProd); 
                                            setMdProd(true);
                                            setIsUpdate(false); 
                                        }} >
                                        <i className='ti-shopping-cart' />
                                    </button>
                                </div>
                            
                                <div className="container-fluid mt-2 d-flex justify-content-center w-100 mb-3">
                                    <div className="table-responsive table-sm w-100 w-100">
                                        <table className="table">
                                            <thead>
                                                <tr className="bg-primary  text-white">
                                                    <th className='p-2'>Nombre</th>
                                                    <th className="text-center p-2">Cantidad</th>
                                                    <th className="text-center p-2">Precio Compra (p/u)</th>
                                                    <th className="text-center p-2">Prec. Total</th>
                                                    <th className="text-center p-2">opción</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                producto && producto.map((item, index) =>
                                                <tr key={index} className="text-right">
                                                    <td className="text-left p-2">{item.nombre}</td>
                                                    <td className="text-center p-2">{item.stock}</td>
                                                    <td className="text-center p-2">{item.precio}</td>
                                                    <td className="text-right p-2">{item.precioTotal}</td>
                                                    <td className="text-center p-2">
                                                        <i className='fa fa-edit text-primary pr-2' onClick={() => onClickShowModal(item)}></i>
                                                        <i className='fa fa-trash-o text-danger' onClick={() => onClickDelete(item.productoId)}></i>
                                                    </td>
                                                </tr>
                                                )
                                            }
                                            
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {producto.length < 1 && (
                                    <div className='col-12'>
                                        <p className='text-danger'>(*) La lista de Productos no tiene que estar vacio.</p>
                                    </div>
                                )}
                                
                                <div className="container-fluid w-100">
                                    <h6 className="text-right text-muted">Op. Gravada : S/. {opAgrav}</h6>
                                    <h6 className="text-right text-muted">IGV 18% : S/. {impuesto}</h6>
                                    <h4 className="text-right">Total : S/. {parseFloat(precioTotal).toFixed(2)}</h4>
                                    <hr/>
                                </div>
                                {
                                    showActividaPago && (
                                        <div className="col-lg-12 grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title float-left">Actividad de Pagos</h4>
                                                    <button className="btn btn-outline-primary btn-sm float-right mb-2" onClick={() => setMdActPag(true)}>
                                                        <i className="fa fa-plus mr-2"/>
                                                        Nueva Registro
                                                    </button>
                                                    
                                                    <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th> # </th>
                                                                <th> Fecha </th>
                                                                <th> Monto Cancelado </th>
                                                                <th> Num. Recivo </th>
                                                                <th className='text-center'> Opción </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                actividadPago && actividadPago.map((item,index) => (
                                                                    <tr key={index}>
                                                                        <td className="py-1">{index + 1}</td>
                                                                        <td> {item.fechaPago && format(parseISO(item.fechaPago),'dd/MM/yyyy')} </td>
                                                                        <td> $ { parseFloat(item.monto).toFixed(2)} </td>
                                                                        <td> {item.numeroRecibo} </td>
                                                                        <td className='text-center'> <i className='fa fa-trash-o text-danger' onClick={() => onClickDeleteActividadPago(index)}></i> </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                                
                                                            </tbody>
                                                        </table>
                                                        
                                                    </div>
                                                    <h4 className="text-right text-danger mt-3">Deuda Pendiente S/. {montoDeuda}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className='col-12 mt-4'>
                                    <ButtonFields/>
                                    <ButtonResetFields/>
                                </div>
                            </div>
                            
                            </FormFields>
                        
                            <ModalComponents
                                size={"md"} 
                                show={mdProd} 
                                hide={()=>setMdProd(false)}  
                                titulo={"Ingresar Producto"} 
                            >
                                <FormFields
                                    initialValues = {modelProd}
                                    onSubmit = {(values, onSubmitProps) => onClickAdd(values,onSubmitProps)}
                                    validationSchema = {addProductSchema}
                                >
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <SelectFields
                                                label={"Producto"}
                                                name="productoId"
                                                isDisabled = {isUpdate}
                                                data={cmbProducto.map((item) =>({value:item.productoId, label: item.nombre }))}
                                                onSelect={(item) => setDetalle(item)}
                                            />
                                        </div>
                                        <div className='col-sm-6 col-md-5 col-lg-5'>
                                            <InputFields
                                                label={"Cantidad"}
                                                type="text"
                                                name="stock"
                                                size = "sm"
                                            />
                                        </div>
                                        <div className='col-sm-6 col-md-5 col-lg-5'>
                                            <InputFields
                                                label={"Precio de Compra (p/u)"}
                                                type="text"
                                                name="precio"
                                                size = "sm"
                                            />
                                        </div>
                                        <div className='col-ms-12 col-md-2 col-lg-2 mt-3'>
                                            <ButtonFields
                                                className='btn btn-inverse-primary btn-sm btn-block float-right'
                                                title = {
                                                    <i className='fa fa-shopping-cart '/> 
                                                }/>
                                        </div>
                                    </div>
                                </FormFields>  
                            </ModalComponents>

                            <ModalComponents
                                size={"md"} 
                                show={mdActPag} 
                                hide={()=>setMdActPag(false)}  
                                titulo={"Actividad Pago"} 
                            >
                                <FormFields
                                    initialValues = {{
                                        fechaPago: currentDate,
                                        numeroRecibo:'',
                                        monto:''
                                    }}
                                    onSubmit = {(values, onSubmitProps) => onAddActividadPago(values,onSubmitProps)}
                                    validationSchema = {addPagoCompraSchema}
                                >
                                    <div className='row'>
                                        <div className='col-ms-12 col-md-4 col-lg-4'>
                                            <InputFields
                                                label={"Fecha Pago"}
                                                type="date"
                                                name="fechaPago"
                                                size = "sm"
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-4 col-lg-4'>
                                            <InputFields
                                                label={"Monto Cancelado"}
                                                type="text"
                                                name="monto"
                                                size = "sm"
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-4 col-lg-4'>
                                            <InputFields
                                                label={"Número Recivo"}
                                                type="text"
                                                name="numeroRecibo"
                                                size = "sm"
                                                isRequired = {false}
                                            />
                                        </div>
                                        <div className='col-12 float-right mt-2'>
                                            <ButtonFields
                                                className='btn btn-outline-primary btn-sm  float-right'
                                                title = "Guardar"/>
                                        </div>
                                    </div>
                                </FormFields>  
                            </ModalComponents>
                        </Card.Body>
                    </Card>
                </div>                   
            </Row>
        </div>
    );
}

export default CreateShopping;
