import Client from './Client';

const endpoint= "api/v1/";
//Listar Persona
export const getListaVerificacion = (categoriaId) => {
    return Client.get(`${endpoint}ListaVerificacion/Listar?CategoriaId=${categoriaId}`)
}
export const getListaVerificacionSelecionada = (detallePedidoId) => {
    return Client.get(`${endpoint}ListaVerificacion/Detalle?DetallePedidoId=${detallePedidoId}`)
}
export const getCardInfo = (anio) => {
    return Client.get(`${endpoint}Dashboard/Card?anio=${anio}`);
}
export const getGraficaLineal = (anio) => {
    return Client.get(`${endpoint}Dashboard/Line?anio=${anio}`);
}
export const getGraficaPie = (anio) => {
    return Client.get(`${endpoint}Dashboard/Pie?Anio=${anio}`);
}

//Crear 
export const crearListaVeficacion = (data) => {
    return Client.post(`${endpoint}ListaVerificacion/Crear`,data)
}
export const avanzarListaVeficacion = (data) => {
    return Client.post(`${endpoint}ListaVerificacion/Avanzar`,data)
}

//actualizar 
export const actualizarNotaDetallePedido = (data) => {
    return Client.put(`${endpoint}ListaVerificacion/ActualizarNota`,data)
}

