import React, { useRef, useEffect, useState } from 'react'
import { FormFields,InputFields,ButtonFields,ButtonResetFields, SelectFields } from '../../../components/Index'
import { addRegistroPagoSchema } from '../../../utils/validations/ProductValidation';
import { getMetodPago } from '../../../services/SalesServices';
import { format } from 'date-fns';
import { showErrorAlert } from '../../../utils/helper/AlertService';
import { da } from 'date-fns/locale';

export default function PaymentRates({totalPagoDeuda,precioTotal,nroRecibo ="",onChangeData}) {
    const [metodoPago, setMetodoPago] = useState([]);
    const [temp, setTemporal] = useState("");
    const formikRef = useRef(null);
    const currentDate = format(new Date(), 'yyyy-MM-dd');

    const onClickAdd = (values, onSubmitProps) => {
        var deuda = totalPagoDeuda + parseFloat(values.monto)
        if(precioTotal >= deuda){
            values.metodoPago = temp;
            onChangeData(values);
            onSubmitProps.setSubmitting(false)
            onSubmitProps.resetForm();
        } 
        else{
            showErrorAlert('', 'El monto de pago no puede ser mayor que el precio total');
        }
    }
    const getData = async ()=> {
        var data = await getMetodPago();
        setMetodoPago(data);
    }

    useEffect(() => {
        getData();
    },[])

  return (
    <main>
        <FormFields
            initialValues={{
                fechaPago: currentDate,
                monto:'',
                numeroRecibo: nroRecibo,
                metodoPagoId: '0bc79c42-1ec0-40b3-b1c7-bfef4ee7d8ae',
            }}
            validationSchema={addRegistroPagoSchema}
            onSubmit={(values, onSubmitProps) => onClickAdd(values, onSubmitProps)}
            formikRef={formikRef}
        > 
            <div className='row'>
                <div className='col-sm-12 col-md-6 col-lg-6'>
                    <InputFields
                        name={"fechaPago"}
                        label={"Fecha"}
                        type="date"
                        size="sm"
                    />
                </div>
                <div className='col-sm-12 col-md-6 col-lg-6'>
                    <InputFields
                        name={"monto"}
                        label={"Monto cancelado"}
                        type="text"
                        size="sm"
                    />
                </div>
                <div className='col-sm-12 col-md-6 col-lg-6'>
                    <SelectFields
                        label={"Metodo Pago"}
                        name="metodoPagoId"
                        data={metodoPago.map(item => ({value:item.id, label: item.nombre}))}
                        onSelect = {(item) => setTemporal(item.label)}
                    />
                </div>
               
                <div className='col-12'>
                    <ButtonFields/>
                    <ButtonResetFields />
                </div>
            </div>
        </FormFields>
    </main>
  )
}
