import jsPDF from 'jspdf';
import QRCode from 'qrcode-generator';
import 'jspdf-autotable';
import printJS from 'print-js';

export const createTickedVenta = (data = {}) => {
    try{
        const doc = new jsPDF('p', 'mm', [70, 200]); // Establece el tamaño del documento en 80 mm x 100 mm
   
        var marginLeft = 0; 
    
        doc.setFontSize(10);
        doc.text(data.nombreCompania, 18, 4);
        doc.text('R.U.C.:'+ data.rucCompania, 20, 8);
        doc.setFontSize(9);
        doc.text('TICKED DE VENTA ELECTRONICA', 9, 18);
        doc.text(data.numComprobante, 22, 22);
    
        doc.text('Fecha       : '+ data.fecha, marginLeft, 28);
        doc.text('Cliente      : '+ data.nombreCliente, marginLeft, 32);
        doc.text('DNI           : '+ data.nroDniCliente, marginLeft, 36);
        doc.text('Cond. Pa  : '+ data.tipoComprobante, marginLeft, 40);
    
        var productos = data.detalles || [];
        var startY = 44.5;
        var colWidths = [8,46,16]; 
    
        doc.autoTable({
          startY: startY,
          head: [['Cant', 'Producto', 'Subtotal']],
          body: productos.map(item => [item.cantidad, item.nombre, item.precio]),
          theme: 'plain',
          columnStyles: { 
            0: { cellWidth: colWidths[0],cellPadding: { bottom: 1 }},
            1: { cellWidth: colWidths[1],cellPadding: { bottom: 1 }  }, 
            2: { cellWidth: colWidths[2],halign: 'right',cellPadding: { bottom: 1 }  } 
          },
          margin: {left: 0, right: 0, top: startY},
          headStyles: { 
            halign: 'left',
            cellPadding: { left: 0, bottom:2 }
          },
          didParseCell: function (data) {
            data.row.cells[2].styles.halign = 'right';
          },
          styles: {
            fontSize: 9, 
            valign: 'middle'
          }
        });
        var tableWidth = 70;
        var headStartY = startY - 1; 
        var headEndY = 49;
        
        doc.setLineWidth(0.1); 
        doc.line(marginLeft, headStartY,tableWidth, headStartY); 
        doc.line(marginLeft, headEndY, tableWidth, headEndY); 
        
        var tableHeight = doc.previousAutoTable.finalY;
        doc.line(marginLeft, tableHeight, tableWidth, tableHeight);
        var colWidthsPrecio = [54,16]
        doc.autoTable({
          startY: tableHeight + 2,
          body: [
            {"nombre":"Op. Gravada S/ :","Precio": parseFloat(data.montoAgravada).toFixed(2)},
            {"nombre":"IGV 18% S/ :","Precio": parseFloat(data.impuesto).toFixed(2)},
            {"nombre":"Importe Total S/ :","Precio": parseFloat(data.precioBruto).toFixed(2)}
          ],
          theme: 'plain',
          columnStyles: { 
            0: { cellWidth: colWidthsPrecio[0],halign: 'right',cellPadding: { bottom: 1 }},
            1: { cellWidth: colWidthsPrecio[1],halign: 'right',cellPadding: { bottom: 1 }  } 
          },
          margin: {left: 0, right: 0, top: tableHeight},
          headStyles: { 
            halign: 'left',
            cellPadding: { left: 0, bottom:2 },
            columnStyles: { 
              0: { cellWidth: colWidthsPrecio[0],halign: 'right',cellPadding: { bottom: 1 }},
              1: { cellWidth: colWidthsPrecio[1],halign: 'right',cellPadding: { bottom: 1 }  } 
            },
          },
          styles: {
            fontSize: 9, 
            valign: 'middle'
          }
        });
    
        var tableHeightPrecio = doc.previousAutoTable.finalY;
        doc.line(marginLeft, tableHeightPrecio, tableWidth, tableHeightPrecio); 
        doc.text("REGISTRO DE PAGOS",18,tableHeightPrecio + 4);
        doc.line(marginLeft, tableHeightPrecio + 5.3, tableWidth, tableHeightPrecio + 5.3); 
    
        var pagos = data.actividadPago || [];
        var colWidthsPagos = [50,20]
        var pagosHeight = tableHeightPrecio + 6
        doc.autoTable({
          startY: pagosHeight,
          head: [['Fecha', 'Monto']],
          body: pagos.map(item => [item.fechaPago, item.monto]),
          theme: 'plain',
          columnStyles: { 
            0: { cellWidth: colWidthsPagos[0],cellPadding: { bottom: 1}},
            1: { cellWidth: colWidthsPagos[1],cellPadding: { bottom: 1 }  } 
          },
          margin: {left: 0, right: 0, top: pagosHeight},
          headStyles: { 
            halign: 'left',
            cellPadding: { left: 0, bottom:2 }
          },
          didParseCell: function (data) {
            data.row.cells[1].styles.halign = 'right';
          },
          styles: {
            fontSize: 9, 
            valign: 'middle'
          }
        });
    
        var tableHeightFinal = doc.previousAutoTable.finalY;
        doc.line(marginLeft, tableHeightFinal, tableWidth, tableHeightFinal);
        doc.setFont('helvetica', 'bold') 
        doc.text( parseInt(data.deuda) > 0 ? "DEUDA PENDIENTE S/: "+ data.deuda : "NO PRESENTA DEUDA",13,tableHeightFinal + 3.7);
        doc.line(marginLeft, tableHeightFinal + 5.3, tableWidth, tableHeightFinal + 5.3);
    
        const qr = QRCode(0, 'L');
        const textoQR = data.numComprobante;
    
        qr.addData(textoQR);
        qr.make();
        const qrPixels = qr.createDataURL(10);
        doc.addImage(qrPixels, 'PNG', 20, tableHeightFinal + 8, 30, 30);
        doc.setFont('helvetica', 'normal');
        doc.text("Guarda tu vaucher. Es el sustento para",7,tableHeightFinal + 40);
        doc.text("para validar tu compra.",20,tableHeightFinal + 43);
    
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        printJS(pdfUrl);
    }
    catch(e) {
      console.log("error",e)
    } 
}

export const tickedPagoCuota = (data = {}) => {
  try{
    const doc = new jsPDF('p', 'mm', [70, 200]); // Establece el tamaño del documento en 80 mm x 100 mm

    var marginLeft = 0; 

    doc.setFontSize(10);
    doc.text(data.nombreCompania, 18, 4);
    doc.text('R.U.C.:'+ data.rucCompania, 20, 8);
    doc.setFontSize(9);
    doc.text('TICKED DE PAGO', 20, 18);
    doc.text(data.numeroRecibo, 22, 22);

    doc.text('Fecha       : '+ data.fechaPago, marginLeft, 28);
    doc.text('Cliente      : '+ data.nombreCliente, marginLeft, 32);
    doc.text('DNI           : '+ data.nroDniCliente, marginLeft, 36);

    var productos = [{nombre: "Pago de Cuota",precio: parseFloat(data.monto).toFixed(2)}];
    var startY = 44.5;
    var colWidths = [50,20]; 

    doc.autoTable({
      startY: startY,
      head: [['Motivo', 'Subtotal']],
      body: productos.map(item => [item.nombre, item.precio]),
      theme: 'plain',
      columnStyles: { 
        0: { cellWidth: colWidths[0],cellPadding: { bottom: 1 }},
        1: { cellWidth: colWidths[1],cellPadding:{ bottom: 1 }} 
      },
      margin: {left: 0, right: 0, top: startY},
      headStyles: { 
        halign: 'left',
        cellPadding: { left: 0, bottom:2 }
      },
      didParseCell: function (data) {
        data.row.cells[1].styles.halign = 'right';
      },
      styles: {
        fontSize: 9, 
        valign: 'middle'
      }
    });

    var tableWidth = 70;
    var headStartY = startY - 1; 
    var headEndY = 49;
    
    doc.setLineWidth(0.1); 
    doc.line(marginLeft, headStartY,tableWidth, headStartY); 
    doc.line(marginLeft, headEndY, tableWidth, headEndY); 
    
    var tableHeight = doc.previousAutoTable.finalY;
    doc.line(marginLeft, tableHeight, tableWidth, tableHeight);
    var colWidthsPrecio = [54,16]
    doc.autoTable({
      startY: tableHeight + 2,
      body: [
        {"nombre":"Importe Total S/ :","Precio": parseFloat(data.monto).toFixed(2)}
      ],
      theme: 'plain',
      columnStyles: { 
        0: { cellWidth: colWidthsPrecio[0],halign: 'right',cellPadding: { bottom: 1 }},
        1: { cellWidth: colWidthsPrecio[1],halign: 'right',cellPadding: { bottom: 1 }  } 
      },
      margin: {left: 0, right: 0, top: tableHeight},
      headStyles: { 
        halign: 'left',
        cellPadding: { left: 0, bottom:2 },
        columnStyles: { 
          0: { cellWidth: colWidthsPrecio[0],halign: 'right',cellPadding: { bottom: 1 }},
          1: { cellWidth: colWidthsPrecio[1],halign: 'right',cellPadding: { bottom: 1 }  } 
        },
      },
      styles: {
        fontSize: 9, 
        valign: 'middle'
      }
    });

    var tableHeightFinal = doc.previousAutoTable.finalY + 8;
    doc.line(marginLeft, tableHeightFinal, tableWidth, tableHeightFinal);
    doc.setFont('helvetica', 'bold') 
    doc.text( parseInt(data.deuda) > 0 ? "DEUDA PENDIENTE S/: "+ data.deuda : "NO PRESENTA DEUDA",13,tableHeightFinal + 3.7);
    doc.line(marginLeft, tableHeightFinal + 5.3, tableWidth, tableHeightFinal + 5.3);

    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    printJS(pdfUrl);
}
catch(e) {
    console.log("error",e)
} 
}