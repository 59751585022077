import React from 'react';
import { Formik} from 'formik';

function FormFields({initialValues,onSubmit,validationSchema, formikRef,children}) {
    return (
        <Formik
            initialValues = {initialValues}
            onSubmit = {onSubmit}
            validationSchema = {validationSchema}
            innerRef={formikRef}
            enableReinitialize
        > 
        {() => (
            <>
                {children}
            </>
        )}
        </Formik>
    );
}

export default FormFields;