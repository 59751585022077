import React, {useState} from 'react'

export default function ItemFile({title, isChecked, onChangeFile }) {
    const [isVisible, setIsVisible] = useState(false);
 
    const handleCheckboxChange = () => {
        setIsVisible(true);
    };

  return (
        <div className="form-check">
            <input 
                className="form-check-input" 
                type="checkbox" 
                id="gridCheck1" 
                checked={isChecked}
                onChange={handleCheckboxChange}
            />
            <label className="form-check-label" onClick={handleCheckboxChange}>
                {title}
            </label>
            {
                isVisible &&  
                <input type="file" 
                    className="form-control-file text-black-50" 
                    id="exampleFormControlFile1"
                    onChange={(e) => onChangeFile(e.target.files[0])}
                />
            }          
        </div>
  )
}
