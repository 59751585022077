import React, { useEffect, useState } from 'react'; 
import { Card, Row } from "react-bootstrap";
import { Table,ModalComponents, Search, FormFields, InputFields, ButtonFields, ButtonResetFields } from '../../../components/Index';
import { getListaProveedor, crearProveedor, actualizarProveedor } from '../../../services/SupplierServices';
import CreateSupplier from './CreateSupplier';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../../utils/helper/AlertService';
import UserPermissionsComponent from '../../auth/UserPermissionsComponent';

const CREAR = 1;
const EDITAR = 2;

const search = {
    skip: 1,
    take: 10,
    search: "",
    compania: "",
    ruc: ""
}
function ListSupplier() {
    const newModel = {
        id:'',
        ruc:'',
        compania:'',
        direccion:'',
        telefono:'',
        TelefonoDos:''
    }

    const [ proveedores, setProveedores ] = useState([]);
    const [ modelSupplier, setModelSuppier ] = useState(newModel);
    const datos_modal = { mdModal: false, titulo: "", cod: 0 }
    const [ infModal, setInfModal ] = useState(datos_modal)
    const [ proveedoresPag, setProveedoresPag ] = useState({})
    const [ paramSearch, setParamSearch ] = useState(search);

    const onClickSendSupplier = (model, onSubmitProps) =>{
        var data = "";

        showConfirAlert({
            title: infModal.cod === CREAR? "Desea registrar nuevo Proveedor": "Desea actualizar proveedor",
            text: "Verifique que todos los campos esten registrados correctamente",
            preConfirm: () => {
                if(infModal.cod === CREAR){
                    data = crearProveedor(model);
                } else {
                    data = actualizarProveedor(model);
                }
                data.then(() => {
                    showSuccessAlert('Registro Exitoso');
                    onSubmitProps.setSubmitting(false);
                    onSubmitProps.resetForm();
                    setInfModal(datos_modal);
                    getData(paramSearch);
                }).catch((error) => {
                    showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
                });
            }
        });
    }

    const onClickEditar = (item) => {
        setModelSuppier(item)
        setInfModal({...infModal,mdModal: true,titulo:"Editar Cliente", cod: EDITAR})
    }

    const onInputSearch = ({target}) => {
        const { value } = target;
        setParamSearch({...paramSearch,search: value});
    }

    const handleTableChange = (page, sizePerPage) => {
        setParamSearch({
            ...paramSearch,
            skip:sizePerPage.page,
            take:sizePerPage.sizePerPage,
        })
    }

    const getData = async (param) => {
        try{
            const data = await getListaProveedor(param);
            setProveedores(data.items);
            setProveedoresPag(data);
        }catch(e){
            showErrorAlert('Error !',  e.apiMessage || 'Error desconocido');
        }
    }

    useEffect(()=> {
        getData(paramSearch);
    },[paramSearch])

    const columns = [
        { 
            dataField: "ruc", 
            text: "RUC / DNI"
        },
        { 
            dataField: "compania", 
            text: "Compania"
        },
		{ 
            dataField: "direccion", 
            text: "Finado" 
        },
        {   
            dataField: "telefono",     
            text: "Telefono"
        },
        {   
            dataField: "telefonoDos",     
            text: "Telefono Dos"
        },
        {
            dataField: "opciones", 
            text: "Opciones",
            sort: false,
            align: 'center',
            classes: 'py-1',
            formatter: (cellContent, row) => {
            return (
                <div>
                    <UserPermissionsComponent accion={['admin','editarProveedor']}>
                        <i className="ti-pencil-alt text-primary mr-2" data-toggle="tooltip" data-placement="right" title="Editar" onClick={() => onClickEditar(row)} style={{fontSize:'15px'}}/>
                    </UserPermissionsComponent>
                    <UserPermissionsComponent accion={['admin']}>
                        <i className="ti-trash text-danger" data-toggle="tooltip" data-placement="right" title="Eliminar" style={{fontSize:'15px'}}/>
                    </UserPermissionsComponent>
                </div>
            );
            }
        }
          
    ]

    return (
        <div>
            <Row >
                <div className="col-12">
                    <Card >  
                        <Card.Body>
                            <div className="row mb-4 justify-content-between">
                                <div className = "col-lg-3 d-none d-sm-none d-md-block">
                                    <h4 className="card-title float-left mt-2">Lista Proveedores</h4>
                                </div>
                                <div className = "col-lg-6 col-ms-12 pb-2">
                                    <Search
                                        onSearchInput={onInputSearch}
                                        value={paramSearch.search}
                                        onRefesh={() => setParamSearch(search)}
                                    >
                                        <FormFields
                                            initialValues={paramSearch}
                                            onSubmit={(values) => setParamSearch(values)}
                                        >
                                            <div className='row'>
                                                <div className='col-ms-12 col-md-6 col-lg-6'>
                                                    <InputFields
                                                        label={"Nombre de Compania"}
                                                        type="text"
                                                        name="compania"
                                                        size = "sm"
                                                        isRequired = {false}
                                                    />
                                                </div>
                                                <div className='col-ms-12 col-md-6 col-lg-6'>
                                                    <InputFields
                                                        label={"RUC / DNI"}
                                                        type="text"
                                                        name="ruc"
                                                        size = "sm"
                                                        isRequired = {false}
                                                    />
                                                </div>
                                                <div className='col-12'>
                                                    <ButtonFields title='Buscar'/>
                                                    <ButtonResetFields title='Limpiar' onClickCancel={() => setParamSearch(search)}/>
                                                </div>
                                            </div>
                                        </FormFields>
                                    </Search>
                                </div>
                                <div className="col-sm-12 col-md-3 col-lg-3">
                                    <UserPermissionsComponent accion={['admin','crearProveedor']}>
                                        <button className="btn btn-outline-primary float-right" onClick={() =>{setInfModal({...infModal,mdModal: true,titulo:"Agregar Proveedor", cod: CREAR}); setModelSuppier(newModel)}}>
                                            <i className="fa fa-plus mr-2"/>
                                            Nuevo Proveedor
                                        </button>
                                    </UserPermissionsComponent>
                                </div>
                            </div>    
                            <Table
                                columns={columns}
                                list={proveedores}
                                page={proveedoresPag.page}
                                sizePerPage={paramSearch.take}
                                totalSize={proveedoresPag.total}
                                onTableChange={handleTableChange}
                            />

                            <ModalComponents 
                                size='md'
                                titulo={infModal.titulo}
                                show={infModal.mdModal}
                                hide={() => setInfModal(datos_modal)}
                            >
                                <CreateSupplier 
                                    modelSuppier={modelSupplier}
                                    onClickSend={(model, onSubmitProps) => onClickSendSupplier(model, onSubmitProps)}
                                />
                            </ModalComponents>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
        </div>
    );
}

export default ListSupplier;