import React, { useState, useRef } from 'react';
import { ButtonFields, ButtonResetFields, FormFields, InputFields, Loading, RadioGroupFields} from '../../../components/Index';
import { createPersonSchema } from '../../../utils/validations/PersonValidation';
import { getBuscaPersonas } from '../../../services/PersonServices';

export default function FormCreatePerson({modelCliente = {}, onClickSend}) {
    const formikRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const onInputSearch = async (value) => {
        try{
            if(value !== "") {
                setIsLoading(true)
                const data = await getBuscaPersonas(value);
                setIsLoading(false);
                formikRef.current.setFieldValue('primerNombre',data.primerNombre);
                formikRef.current.setFieldValue('segundoNombre',data.segundoNombre);
                formikRef.current.setFieldValue('apellidoPaterno',data.apellidoPaterno);
                formikRef.current.setFieldValue('apellidoMaterno',data.apellidoMaterno);
                formikRef.current.setFieldValue('sexo',data.sexo);
                formikRef.current.setFieldValue('direccion',data.direccion);
                formikRef.current.setFieldValue('telefonoUno',data.telefonoUno);
                formikRef.current.setFieldValue('telefonoDos',data.telefonoDos);
                formikRef.current.setFieldValue('telefonoTres',data.telefonoTres);

            }   
        }
        catch(e){
            setIsLoading(false);
        }
    }

    const data = [
        {
            value:"M",
            label: "Masculino"
        },
        {
            value:"F",
            label: "Femenino"
        }
    ]

  return (
    <FormFields
        initialValues = {modelCliente}
        onSubmit = {(values, onSubmitProps) => onClickSend(values,onSubmitProps)}
        validationSchema = {createPersonSchema}
        formikRef={formikRef}
    >
        <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-3'>
                <InputFields
                    label={"Número de DNI"}
                    type="text"
                    name="nroDoi"
                    size = "sm"
                    onSearch={(item) => onInputSearch(item)}
                />
            </div>
            <div className='col-sm-12 col-md-12 col-lg-3'>
                <InputFields
                    label={"Apellido Paterno"}
                    type="text"
                    name="apellidoPaterno"
                    size = "sm"
                />
            </div>
            <div className='col-sm-12 col-md-12 col-lg-3'>
                <InputFields
                    label={"Apellido Materno"}
                    type="text"
                    name="apellidoMaterno"
                    size = "sm"
                />
            </div>
            <div className='col-sm-12 col-md-12 col-lg-3'>
                <InputFields
                    label={"Nombre"}
                    type="text"
                    name="primerNombre"
                    size = "sm"
                />
            </div>
            <div className='col-sm-12 col-md-12 col-lg-3'>
                <InputFields
                    label={"Segundo Nombre"}
                    type="text"
                    name="segundoNombre"
                    size = "sm"
                    isRequired = {false}
                />
            </div>
            <div className='col-sm-12 col-md-12 col-lg-3'>
                <RadioGroupFields 
                    label={"Selecione el Sexo"}
                    data={data}
                    name="sexo"
                />
            </div>
            <div className='col-sm-12 col-md-12 col-lg-6'>
                <InputFields
                    label={"Dirección"}
                    as="textarea" 
                    rows={1}
                    name="direccion"
                    size = "sm"
                />
            </div>
            <div className='col-sm-12 col-md-12 col-lg-3'>
                <InputFields
                    label={"Celular 1"}
                    type="tel"
                    name="telefonoUno"
                    size = "sm"
                />
            </div>
            <div className='col-sm-12 col-md-12 col-lg-3'>
                <InputFields
                    label={"Celular 2"}
                    type="tel"
                    name="telefonoDos"
                    size = "sm"
                />
            </div>
            <div className='col col-sm-12 col-md-12 col-lg-6'>
                <InputFields
                    label={"Celular 3"}
                    type="text"
                    name="telefonoTres"
                    size = "sm"
                    isRequired = {false}
                />
            </div>
            <div className='col-12'>
                <ButtonFields/>
                <ButtonResetFields/>
            </div>
        </div>
        
        <Loading isLoading = {isLoading}/>
    </FormFields>
  )
}
