import React from 'react'

export default function ItemName({titulo, detalle, className = '', classNameText = 'text-dark'}) {
  return (
    <p className={`clearfix ${className}`}>
        <span className="pr-2 font-weight-bold">
            {titulo}
        </span>
        <span className={classNameText}>
            {detalle}
        </span>
    </p>
  )
}
