import Swal from 'sweetalert2';

const showAlert = (options, customOptions) => {
    return Swal.fire({
      ...options,
      ...customOptions
    });
  };
  
  const showSuccessAlert = (title, text = '', timer = 1500) => {
    return showAlert({
      icon: 'success',
      showConfirmButton: false,
      title,
      text,
      timer
    });
  };
  
  const showErrorAlert = (title = "", text = "") => {
    return showAlert({
      icon: 'error',
      title,
      text
    });
  };

  const showConfirAlert = (options) => {
    return showAlert({
      icon: 'info',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      ...options
      
    });
  };

export { showSuccessAlert, showErrorAlert, showConfirAlert };