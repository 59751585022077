import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Row } from 'react-bootstrap';
import { Loading, Table, ModalComponents, ButtonFields, ButtonResetFields, FormFields, InputFields, Search } from '../../components/Index';
import { getListaEmpleado, actualizarEmpleado, actualizarCuentaEmpleado } from '../../services/PersonServices';
import { updateUserSchema, updateAccountUserSchema } from '../../utils/validations/PersonValidation';
import { showErrorAlert, showSuccessAlert } from '../../utils/helper/AlertService';

const search = {
    skip: 1,
    take: 10,
    search: ""
}
export default function ListEmployees() {
    const new_model = {
        id:'',
        primerNombre:'',
        segundoNombre:'',
        apellidoPaterno:'',
        apellidoMaterno:'',
        nroDOI:'',
        direccion:'',
        telefono:''
    }
    const new_model_cuenta = {
        id:'',
        email:'',
        password:'',
        confirmPassword:'',
    }
    const [ modelEmployees, setModelEmployees ] = useState(new_model);
    const [ modelCuentaEmployees, setModelCuentaEmployees ] = useState(new_model_cuenta);
    const [ listEmpleado, setListEmpleado] = useState([]);
    const [ mdShow, setMdShow ] = useState(false);
    const [ mdShowCuenta, setMdShowCuenta ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ paramSearch, setParamSearch ] = useState(search);
    const [ empleadoPag, setEmpleadoPag ] = useState({})

    const onClickActualizar = (values,onSubmitProps) => {
        actualizarEmpleado(values).then(() => {
            showSuccessAlert('Actualización Exitosa');
            onSubmitProps.setSubmitting(false)
            onSubmitProps.resetForm();
            getData(paramSearch);
            setMdShow(false);
            
        }).catch((error) => {
            showErrorAlert('Error de Actualización !',  error.apiMessage || 'Error desconocido');
        });
            
    }

    const onClickActualizarCuenta = (values,onSubmitProps) => {
        actualizarCuentaEmpleado(values).then((response) => {
            showSuccessAlert('La cuenta se relizó correctamente');
            onSubmitProps.setSubmitting(false)
            onSubmitProps.resetForm();
            getData(paramSearch);
            setMdShowCuenta(false);
        }).catch((error) => {
            showErrorAlert('Error de Actualización!',  error.apiMessage || 'Error desconocido');
        });
    }

    const onShowMdAcualizar = (item) => {
        setModelEmployees(item);
        setMdShow(true);
    }

    const onShowMdAcualizarCuenta = (item) => {
        setModelCuentaEmployees({...modelCuentaEmployees,
            id:item.id,
            email:item.email,
        });
        setMdShowCuenta(true);
    }

    const onInputSearch = ({target}) => {
        const { value } = target;
        setParamSearch({...paramSearch,search:value})
    }

    const handleTableChange = (page, sizePerPage) => {
        setParamSearch({
            ...paramSearch,
            skip:sizePerPage.page,
            take:sizePerPage.sizePerPage,
        })
    }
    const getData = async (param) => {
        try{
            setIsLoading(true)
            var data = await getListaEmpleado(param);
            setIsLoading(false)
            setEmpleadoPag(data);
            setListEmpleado(data.items);
        }
        catch(e){
            showErrorAlert('Error !',  e.apiMessage || 'Error desconocido');
        }
    }
    useEffect(() => {
        getData(paramSearch);
    },[paramSearch])

    const columns = [
        { 
            dataField: "nombreCompleto", 
            text: "Nombre"
        },
        { 
            dataField: "direccion", 
            text: "Dirección",
            style: { width: '450px', wordWrap: 'break-word', whiteSpace: 'normal' } 
        },
        { 
            dataField: "email", 
            text: "Correo"
        },
		{ 
            dataField: "nroDOI", 
            text: "N° DNI" 
        },
        {   
            dataField: "telefono",     
            text: "telefono"
        },
        {
            dataField:'estado',
            text:"Estado"
        },
        {
            dataField: "opciones", 
            text: "Opciones",
            sort: false,
            align: 'center',
            classes: 'py-1',
            formatter: (cellContent, row) => {
            return (
                <div>
                    <i className="ti-pencil-alt text-primary mr-2" data-toggle="tooltip" data-placement="right" title="Editar" onClick={() => onShowMdAcualizar(row)} style={{fontSize:'15px'}}/>
                    <i className="ti-unlock text-warning mr-2" data-toggle="tooltip" data-placement="right" title="Editar Cuenta" onClick={() => onShowMdAcualizarCuenta(row)} style={{fontSize:'15px'}}/>
                    <i className="ti-trash text-danger" data-toggle="tooltip" data-placement="right" title="Eliminar" style={{fontSize:'15px'}}/>
                </div>
            );
            }
        }
          
    ]
  return (
    <div>
        <Row>
            <div className="col-12">
                <Card >  
                    <Card.Body>
                        <div className="row mb-4 justify-content-between">
                            <div className = "col-lg-3 d-none d-sm-none d-md-block">
                                <h4 className="card-title float-left mt-2">Lista Empleados</h4>
                            </div>
                            <div className = "col-lg-6 col-ms-12 pb-2">
                                <Search 
                                    onSearchInput={onInputSearch}
                                    value={paramSearch.search}
                                    onRefesh={() => setParamSearch(search)}
                                    isSearchDrowp = {false}
                                />
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                                <Link className="btn btn-outline-primary float-right" to={"/user/create"}>
                                    <i className="fa fa-plus mr-2"/>
                                    Nuevo Empleado
                                </Link>
                            </div>
                        </div>    
                        <Table
                            columns={columns}
                            list={listEmpleado}
                            page={empleadoPag.page}
                            sizePerPage={paramSearch.take}
                            totalSize={empleadoPag.total}
                            onTableChange={handleTableChange}
                        />
                        <Loading isLoading={isLoading}/>

                        {/* Actualizar empleado */}
                        <ModalComponents
                            titulo={"Actualizar Empleado"}
                            show={mdShow}
                            hide={() => setMdShow(false)}
                        >
                            <FormFields
                                initialValues = {modelEmployees}
                                onSubmit = {(values, onSubmitProps) => onClickActualizar(values,onSubmitProps)}
                                validationSchema = {updateUserSchema}
                            >
                            <div className='row'>
                                <div className='col-sm-12 col-md-12 col-lg-3'>
                                    <InputFields
                                        label={"Apellido Paterno"}
                                        type="text"
                                        name="apellidoPaterno"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-3'>
                                    <InputFields
                                        label={"Apellido Materno"}
                                        type="text"
                                        name="apellidoMaterno"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-3'>
                                    <InputFields
                                        label={"Nombre"}
                                        type="text"
                                        name="primerNombre"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-3'>
                                    <InputFields
                                        label={"Segundo Nombre"}
                                        type="text"
                                        name="segundoNombre"
                                        size = "sm"
                                        isRequired = {false}
                                    />
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-3'>
                                    <InputFields
                                        label={"Número de DNI"}
                                        type="text"
                                        name="nroDOI"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-3'>
                                    <InputFields
                                        label={"Celular"}
                                        type="text"
                                        name="telefono"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6'>
                                    <InputFields
                                        label={"Dirección"}
                                        as="textarea" 
                                        rows={1}
                                        name="direccion"
                                        size = "sm"
                                    />
                                </div>
                                
                                <div className='col-12 mt-4'>
                                    <ButtonFields/>
                                    <ButtonResetFields/>
                                </div>
                            </div>
                                
                            </FormFields>
                        </ModalComponents>

                        {/* Actualizar cuenta */}
                        <ModalComponents
                            titulo={"Actualizar Cuenta"}
                            show={mdShowCuenta}
                            hide={() => setMdShowCuenta(false)}
                            size='sm'
                        >
                            <FormFields 
                                initialValues={modelCuentaEmployees}
                                onSubmit = {(values, onSubmitProps) => onClickActualizarCuenta(values,onSubmitProps)}
                                validationSchema = {updateAccountUserSchema}
                            >
                            <div className='row'>
                                <div className='col-12'>
                                    <InputFields
                                        label={"Email"}
                                        type="email"
                                        name="email"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-12'>
                                    <InputFields
                                        label={"Contraseña"}
                                        type="password"
                                        name="password"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-12'>
                                    <InputFields
                                        label={"Confirmación Contraseña"}
                                        type="password"
                                        name="confirmPassword"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-12 mt-4'>
                                    <ButtonFields/>
                                    <ButtonResetFields/>
                                </div>
                            </div>
                            </FormFields>
                        </ModalComponents>
                    </Card.Body>
                </Card>
            </div>
        </Row>
    </div>
  )
}
