
export default function useConverBase64() {
    const converBase64 = (archivo) =>{

        return new Promise((resolve) =>{
            const extension = archivo.name.split(".").pop();
    
            const lector = new FileReader();
            lector.readAsDataURL(archivo);
            lector.onload = () => resolve({
                contenido: lector.result.split(",")[1],
                extension: extension,
            });
            lector.onerror = error => PromiseRejectionEvent(error);
        })
    }

  return {
    converBase64
    }
}
