import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Nav, Tab } from 'react-bootstrap';
import { Table, ModalComponents } from '../../components/Index';
import { getListaProducto, deleteProducto } from '../../services/ProductService';
import { showErrorAlert, showConfirAlert, showSuccessAlert } from '../../utils/helper/AlertService';
import UpdateProducts from './UpdateProducts';
import UserPermissionsComponent from '../auth/UserPermissionsComponent';

const ListProducts = () => {
    const model = {
        skip: 1,
        take: 10,
        nombre: "",
        tipo: "01"
    };

    const [ modelProducto, setModelProducto ] = useState(model);
    const [ producto, setProducto ] = useState([]);
    const [ mdProducto, setMdProducto ] = useState(false);
    const [ values, setValues] = useState({});
    const [ personaPag, setPersonaPag ] = useState({});

    const onShowMdProductos = (row) => {
        setMdProducto(true);
        setValues(row);
    };

    const onRefProd = () => {
        setMdProducto(false);
        getProducto(modelProducto);
    }

    const onDeleteProducto = (id) => {
        showConfirAlert({
            title: '¿Estás seguro de eliminar el producto?',
            text: "¡No padrás revertir esto!",
            preConfirm: () => {
                deleteProducto(id).then(() => {
                    showSuccessAlert("Eliminación Exitoso");
                    getProducto(modelProducto);
                }).catch((error) =>{
                    showErrorAlert("Error, vuelva intentarlo")
                })
            }
        });
    }

    const getProducto = async (model) => {
        try
        {
            const data = await getListaProducto(model);
            setPersonaPag(data);
            setProducto(data.items);
        }
        catch(e) {

        }
        
    }

    useEffect(() => {
        getProducto(modelProducto);
    },[modelProducto])


    const columnsProd = [
        { 
            dataField: "nombre", 
            text: "Nombre",
            style: {width: '53%'}
        },
        { 
            dataField: "categoria", 
            text: "Categoría",
            style: {width: '22%'}
        },
        { 
            dataField: "stock", 
            text: "Stock",
            align: 'center',
            style: {width: '10%'}
        },
        {
            dataField: "opciones", 
            text: "Opciones",
            sort: false,
            align: 'center',
            classes: 'py-1',
            style: {width: '15%'},
            formatter: (cellContent, row) => {
            return (
                <div>
                    <UserPermissionsComponent accion={['admin','editarCompra']}>
                        <i className="ti-pencil-alt text-primary mr-2" data-toggle="tooltip" data-placement="right" title="Detalle" onClick={() => onShowMdProductos(row)} style={{fontSize:'15px'}}/>
                    </UserPermissionsComponent>
                    <UserPermissionsComponent accion={['admin']}>
                        <i className="ti-trash text-danger" data-toggle="tooltip" data-placement="right" title="Eliminar" style={{fontSize:'15px'}} onClick={() => onDeleteProducto(row.id)}/>
                    </UserPermissionsComponent>
                </div>
            );
            }
        }
          
    ]

    const columnsService = [
        { 
            dataField: "nombre", 
            text: "Nombre",
            style: {width: '85%'}
        },
        {
            dataField: "opciones", 
            text: "Opciones",
            sort: false,
            align: 'center',
            style: {width: '15%'},
            formatter: (cellContent, row) => {
            return (
                <div>
                    <UserPermissionsComponent accion={['admin']}>
                        <i className="ti-trash text-danger" data-toggle="tooltip" data-placement="right" title="Eliminar" style={{fontSize:'15px'}} onClick={() => onDeleteProducto(row.id)}/>
                    </UserPermissionsComponent>
                </div>
            );
            }
        }
          
    ]

    return (
        <div>
            <Row >
                <div className="col-12">
                    <Card>  
                        <Card.Body>
                            <div className="row mb-4 justify-content-between">
                                <div className = "col-lg-2 d-none d-sm-none d-md-block">
                                    <h4 className="card-title float-left mt-2">Lista Productos</h4>
                                </div>
                                <div className = "col-lg-6 col-ms-12">
                                
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <UserPermissionsComponent accion={['admin','crearProducto']}>
                                        <Link className="btn btn-outline-primary float-right" to="/products/create">
                                            <i className="fa fa-plus mr-2"/>
                                            Nuevo Producto
                                        </Link>
                                    </UserPermissionsComponent>
                                </div>
                            </div>    
                        
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="tab-pills-vertical">
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                            <Row>
                                            <Col sm={3}>
                                                <Nav variant="pills" className="flex-column">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="first" className="d-flex align-items-center" onClick ={() => setModelProducto({...modelProducto, tipo: "01"})}>
                                                            Productos Venta
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="second" className="d-flex align-items-center" onClick={() => setModelProducto({...modelProducto, tipo: "02"})} >
                                                            Productos Alquiler
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item>
                                                        <Nav.Link eventKey="third" className="d-flex align-items-center" onClick={() => setModelProducto({...modelProducto, tipo: "03"})}>
                                                            Servicios
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="four" className="d-flex align-items-center" onClick={() => setModelProducto({...modelProducto, tipo: "04"})}>
                                                            Egresos Operativos
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                            <Col sm={9}>
                                                <Tab.Content className='border-0 p-0'>
                                                    <Tab.Pane eventKey="first">
                                                        <Table 
                                                            list={producto} 
                                                            columns={columnsProd}
                                                            page={personaPag.page}
                                                            sizePerPage={personaPag.take}
                                                            totalSize={personaPag.tatal}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="second">
                                                        <Table 
                                                            list={producto} 
                                                            columns={columnsProd}
                                                            page={personaPag.page}
                                                            sizePerPage={personaPag.take}
                                                            totalSize={personaPag.total}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="third">
                                                        <Table 
                                                            list={producto} 
                                                            columns={columnsService}
                                                            page={personaPag.page}
                                                            sizePerPage={personaPag.take}
                                                            totalSize={personaPag.total}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="four">
                                                        <Table 
                                                            list={producto} 
                                                            columns={columnsService}
                                                            page={personaPag.page}
                                                            sizePerPage={personaPag.take}
                                                            totalSize={personaPag.total}
                                                        />
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
            <ModalComponents
                titulo={"Editar Producto"}
                show={mdProducto}
                hide={() => setMdProducto(false)}
            >
                <UpdateProducts valueInitial={values} onClickUpdateProd={() => onRefProd()}/>
            </ModalComponents>
        </div>
    );
}

export default ListProducts;