import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import UserPermissionsComponent from '../auth/UserPermissionsComponent';

const Sidebar = () => {
    const [ state, setState ] = useState({});
    const toggleMenuState = (menuState) => {
        if (state[menuState]) {
          setState({[menuState] : false});
        } else if(Object.keys(state).length === 0) {
          setState({[menuState] : true});
        } else {
          Object.keys(state).forEach(i => {
            setState({[i]: false});
          });
          setState({[menuState] : true});
        }
    }
   
    useEffect(() => {
        const dropdownPaths = [
            { path:'/service', state: 'menuService' },
            { path:'/person', state: 'menuPerson' },
            { path:'/caja', state: 'menuCaja' },
            { path:'/repost', state: 'menuReport' },
            { path:'/product', state: 'menuProduct' },
            { path:'/admin', state: 'menuAdmin' },
            { path:'/user', state: 'menuUser' }
        ]
        dropdownPaths.forEach((obj => {
            if (isPathActive(obj.path)) {
            setState({[obj.state] : true})
            }
        }));
        
    },[])

   
    const isPathActive = (path) => {
        return window.location.pathname.startsWith(path);
    }

    useEffect(() => {
        const body = document.querySelector('body');
        document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
          el.addEventListener('mouseover', function() {
            if (body.classList.contains('sidebar-icon-only')) {
              el.classList.add('hover-open');
            }
          });
          el.addEventListener('mouseout', function() {
            if (body.classList.contains('sidebar-icon-only')) {
              el.classList.remove('hover-open');
            }
          });
        });
    }, []);
    
    return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
                <li className={ isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
                    <Link className="nav-link" to="/">
                        <i className="ti-dashboard menu-icon"></i>
                        <span className="menu-title">Cuadro de Mando</span>
                    </Link>
                </li>
                <li className={ isPathActive('/service') ? 'nav-item active' : 'nav-item' }>
                    <div className={ state.menuService ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('menuService')} data-toggle="collapse">
                        <i className="ti-home menu-icon"></i>
                        <span className="menu-title">Servicios</span>
                        <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={ state.menuService }>
                    <ul className="nav flex-column sub-menu">
                        <li className="nav-item"> <Link className={ isPathActive('/services/list-sales') ? 'nav-link active' : 'nav-link' } to="/services/list-sales">Ventas</Link></li>
                        <li className="nav-item"> <Link className={ isPathActive('/services/list-rentals') ? 'nav-link active' : 'nav-link' } to="/services/list-rentals">Alquileres</Link></li>
                    </ul>
                    </Collapse>
                </li>
                <li className={ isPathActive('/person') ? 'nav-item active' : 'nav-item' }>
                    <div className={ state.menuPerson ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('menuPerson')} data-toggle="collapse">
                        <i className="ti-layers-alt menu-icon"></i>
                        <span className="menu-title">Clientes</span>
                        <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={ state.menuPerson }>
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item"> <Link className={ isPathActive('/person/list-person') ? 'nav-link active' : 'nav-link' } to="/person/list-person">Clientes</Link></li>
                            <li className="nav-item"> <Link className={ isPathActive('/person/list-deceased') ? 'nav-link active' : 'nav-link' } to="/person/list-deceased">Fallecidos</Link></li>
                        </ul>
                    </Collapse>
                </li>
                <li className={ isPathActive('/seguimiento-cliente') ? 'nav-item active' : 'nav-item' }>
                    <Link className='nav-link' to="/seguimiento-cliente">
                        <i className='ti-calendar menu-icon' />
                        <span className="menu-title">Seguiento Cliente </span>
                    </Link>
                </li>
                <li className={ isPathActive('/activities') ? 'nav-item active' : 'nav-item' }>
                    <Link className='nav-link' to="/activities">
                        <i className='ti-light-bulb menu-icon' />
                        <span className="menu-title">Capillas</span>
                    </Link>
                </li>
                <li className={ isPathActive('/supplier') ? 'nav-item active' : 'nav-item' }>
                    <Link className='nav-link' to="/supplier/list-supplier">
                        <i className='ti-truck menu-icon' />
                        <span className="menu-title">Proveedores</span>
                    </Link>
                </li>
                <UserPermissionsComponent accion={['admin','admVenta']}>
                    <li className={ isPathActive('/shopping') ? 'nav-item active' : 'nav-item' }>
                        <Link className='nav-link' to="/shopping/list">
                            <i className='ti-shopping-cart menu-icon' />
                            <span className="menu-title">Compras</span>
                        </Link>
                    </li>
                </UserPermissionsComponent>
                <li className={ isPathActive('/caja') ? 'nav-item active' : 'nav-item' }>
                    <div className={state.menuCaja ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('menuCaja')} data-toggle="collapse">
                        <i className='fa fa-calculator menu-icon' />
                        <span className="menu-title">Caja</span>
                        <i className="menu-arrow"/>
                    </div>
                    <Collapse in = {state.menuCaja}>
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item"> <Link className={ isPathActive('/caja/list-egresos') ? 'nav-link active' : 'nav-link' } to="/caja/list-egresos">Bitácora de Egresos</Link></li>
                            <UserPermissionsComponent accion={['admin','admVenta']}>
                                <li className="nav-item"> <Link className={ isPathActive('/caja/list-ingresos') ? 'nav-link active' : 'nav-link' } to="/caja/list-ingresos">Ingresos</Link></li>
                            </UserPermissionsComponent>
                            <UserPermissionsComponent accion={['admin','admVenta']}>
                                <li className="nav-item"> <Link className={ isPathActive('/caja/cierre') ? 'nav-link active' : 'nav-link' } to="/caja/cierre">Cierre Caja</Link></li>
                            </UserPermissionsComponent>
                        </ul>
                    </Collapse>
                </li>
                <UserPermissionsComponent accion={['admin']}>
                    <li className={ isPathActive('/report') ? 'nav-item active' : 'nav-item' }>
                        <div className={state.menuCaja ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('menuReport')} data-toggle="collapse">
                            <i className='ti ti-bar-chart menu-icon' />
                            <span className="menu-title">Reportes</span>
                            <i className="menu-arrow"/>
                        </div>
                        <Collapse in = {state.menuReport}>
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className={ isPathActive('/report/list-sales') ? 'nav-link active' : 'nav-link' } to="/report/list-sales">Ventas</Link></li>
                                <li className="nav-item"> <Link className={ isPathActive('/report/list-rentals') ? 'nav-link active' : 'nav-link' } to="/report/list-rentals">Alquiler</Link></li>
                                <li className="nav-item"> <Link className={ isPathActive('/report/list-compras') ? 'nav-link active' : 'nav-link' } to="/report/list-compras">Compras</Link></li>
                            </ul>
                        </Collapse>
                    </li>
                </UserPermissionsComponent>
                <li className={ isPathActive('/product') ? 'nav-item active' : 'nav-item' }>
                    <div className={state.menuProduct ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('menuProduct')} data-toggle="collapse">
                        <i className='ti-package menu-icon' />
                        <span className="menu-title">Producto</span>
                        <i className="menu-arrow"/>
                    </div>
                    <Collapse in = {state.menuProduct}>
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item"> <Link className={ isPathActive('/products/list-products') ? 'nav-link active' : 'nav-link' } to="/products/list-products">Productos</Link></li>
                            <li className="nav-item"> <Link className={ isPathActive('/products/catalogue') ? 'nav-link active' : 'nav-link' } to="/products/catalogue">Catálogo</Link></li>
                            <li className="nav-item"> <Link className={ isPathActive('/products/categories') ? 'nav-link active' : 'nav-link' } to="/products/categories">Categorias</Link></li>
                        </ul>
                    </Collapse>
                </li>
                <li className={ isPathActive('/admin') ? 'nav-item active' : 'nav-item' }>
                    <div className={ state.menuAdmin ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('menuAdmin')} data-toggle="collapse">
                        <i className="ti-settings menu-icon"></i>
                        <span className="menu-title">Administración</span>
                        <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={ state.menuAdmin }>
                    <ul className="nav flex-column sub-menu">
                        <li className="nav-item"> <Link className={ isPathActive('/admin/list-company') ? 'nav-link active' : 'nav-link' } to="/admin/list-company">Empresas</Link></li>
                        {/* <li className="nav-item"> <Link className={ isPathActive('/services/list-rentals') ? 'nav-link active' : 'nav-link' } to="/services/list-rentals">Alquileres</Link></li> */}
                    </ul>
                    </Collapse>
                </li>
                <li className={ isPathActive('/user') ? 'nav-item active' : 'nav-item' }>
                    <div className={state.menuUser ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('menuUser')} data-toggle="collapse">
                        <i className='ti-user menu-icon' />
                        <span className="menu-title">Usuario</span>
                        <i className="menu-arrow"/>
                    </div>
                    <Collapse in = {state.menuUser}>
                        <ul className="nav flex-column sub-menu">
                            <UserPermissionsComponent accion={['admin']}>
                                <li className="nav-item"> <Link className={ isPathActive('/user/user-management') ? 'nav-link active' : 'nav-link' } to="/user/user-management">Roles y Permisos</Link></li>
                                <li className="nav-item"> <Link className={ isPathActive('/user/list-employees') ? 'nav-link active' : 'nav-link' } to="/user/list-employees">Listar Empleados</Link></li>
                                <li className="nav-item"> <Link className={ isPathActive('/user/create') ? 'nav-link active' : 'nav-link' } to="/user/create">Crear Empleado</Link></li>
                            </UserPermissionsComponent>
                            <li className="nav-item"> <Link className={ isPathActive('/user/perfil') ? 'nav-link active' : 'nav-link' } to="/user/perfil">Perfil</Link></li>
                        </ul>
                    </Collapse>
                </li>
                
            </ul>
        </nav>
    );
}

export default Sidebar;