import React from 'react';
import { Modal } from 'react-bootstrap';


function ModalComponents({size = "lg",show,hide,titulo, children}) {
    return (
        <Modal
            size={size}
            show={show}
            onHide={hide} 
            aria-labelledby="example-modal-sizes-title-md"
        >
            <Modal.Header closeButton className="mr-2">
                <Modal.Title className='h5 text-muted'>{titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-4'>
                {children}
            </Modal.Body>
            
        </Modal>
    );
}

export default ModalComponents;