import React from 'react';
import ErrorMessage from '../ErrorMessage';
import { useFormikContext } from 'formik'
import { LabelFields } from './LabelFields';

function RadioGroupFields({data = [], name, label, isRequired = true, onSelect = () => {} }) {
    const { setFieldValue, errors, touched, values } = useFormikContext();

    return (
        <div className="form-group mb-0">
            {
                label && <LabelFields label={label} isRequired = {isRequired}/>
            }
            <div className="d-flex">
                {
                    data.map((item,index) => (
                        <div className="form-check mr-3 mb-0" key={index}>
                            <input
                                type="radio"
                                name={name}
                                value={item.value}
                                checked={values[name] === item.value}
                                onChange={() => { setFieldValue(name,item.value); onSelect(item) }}
                                className="form-check-input"
                            />
                        <label className="form-check-label card-description">{item.label}</label>
                    </div>
                    ))
                }
            </div>
            <ErrorMessage error={errors[name]} visible={touched[name]}  />
        </div>
    );
}

export default RadioGroupFields;