import React from 'react';
import { Card, Row } from 'react-bootstrap';
import FormCreateDeceased from '../components/FormCreateDeceased';

function CreateDeceased() {

    return (
        <div>
            <Row>
                <div className='col-12'>
                    <Card>
                        <Card.Body>
                            <h4 className="card-title">Registrar nuevo Fallecido</h4>
                            <FormCreateDeceased />
                        </Card.Body>
                    </Card>
                </div>
            </Row>
        </div>
    );
}

export default CreateDeceased;