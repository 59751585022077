import React from 'react'

export default function PaymentActivity({listaPagos = [], opc = true, handleDelete = () => {}, handlePrint =() => {} }) {
  return (
    <div className='row'>
        <div className='col-12'>
            <p className='bg-primary m-0 p-1 text-center text-white font-weight-bold'>REGISTRO DE PAGOS EN EFECTIVO</p>
        </div>
        <div className='col-12'>
            <div className="table-responsive table-sm w-100">
                <table className="table table-bordered">
                    <thead>
                        <tr className="font-weight-bold">
                            <th className='text-center p-2'>#</th>
                            <th className='text-center p-2 '>Fecha</th>
                            <th className="text-center p-2">Monto Cancelado</th>
                            <th className="text-center p-2">Metodo Pago</th>
                            <th className="text-center p-2">N° de Recibo</th>
                            {!opc && <th className="text-center p-2">Usuario</th>}
                            <th className="text-center p-2">OPC.</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        listaPagos && listaPagos.map((item, index) =>
                        <tr key={index} className="text-right">
                            <td className="text-center p-2">{index + 1 }</td>
                            <td className="text-center p-2">{item.fechaPago}</td>
                            <td className="text-right p-2">{ parseFloat(item.monto).toFixed(2)}</td>
                            <td className="text-center p-2">{item.metodoPago}</td>
                            <td className="text-center p-2">{item.numeroRecibo}</td>
                            {
                                !opc && 
                                <td className="text-center p-2">
                                    {item.nombreEmpleado}
                                </td>
                            } 
                            <td className="text-center p-2">
                                {
                                    opc ? <i className='fa fa-trash-o text-danger' style={{cursor:'pointer'}} onClick={() => handleDelete(index)}/> :
                                    <i className='ti ti-printer text-success' style={{cursor:'pointer'}} onClick={() => handlePrint(item)}/>
                                }
                            </td>
                        </tr>)
                    }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}
