import { useCallback, useContext, useState } from 'react'
import { Context } from '../context/AuthContext'
import { loginService } from '../services/PersonServices';

export default function useUser() {
    const { token, setToken } = useContext(Context)
    const [ error, setError ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);

    const login = useCallback((values) => {
        setIsLoading(true)
        loginService(values).then(res => {
            setToken(JSON.stringify(res.accessToken));
            sessionStorage.setItem('jwt', res.accessToken); 
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
            setError("El email y/o password son incorrectos")
        })
        
    },[setToken]);
    
    const logout = useCallback(() => {
        setToken('');
        sessionStorage.removeItem('jwt');
    },[setToken]);
    
    const isLoggedIn = !!token;

  return {
    login,
    logout,
    isLoggedIn,
    error,
    isLoading
  }
}

