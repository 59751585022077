import Client from './Client';

const endpoint= "api/v1/";

export const getComboProveedor = (search) => {
    return Client.get(`${endpoint}Proveedor/combo?Search=${search}`);
}

export const getListaProveedor = (data) => {
    return Client.post(`${endpoint}Proveedor/Lista`,data);
}

// POST
export const crearProveedor = (data) => {
    return Client.post(`${endpoint}Proveedor/Crear`, data);
}

export const actualizarProveedor = (data) => {
    return Client.put(`${endpoint}Proveedor/Actualizar`, data);
}