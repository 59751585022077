import React from "react";
import { Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2" style={{fontSize:'.825rem'}}>
      Mostrado { from } a { to } de { size } Resultados
    </span>
);

function TableComponents({
    list,
    columns,
    page,
    sizePerPage,
    totalSize,
    onTableChange,
    paginationTotalRenderer = customTotal,
    showTotal = true,
    keyField = 'id'
}) {
    return (
        <Row>
            <div className="col-12">
                <ToolkitProvider keyField={keyField} bootstrap4 data={list} columns={columns} search>
                    {(props) => (
                        <div>
                            <BootstrapTable
                                remote
                                pagination={paginationFactory({ page, sizePerPage, totalSize, paginationTotalRenderer,showTotal})}
                                onTableChange = {onTableChange}
                                {...props.baseProps}
                                wrapperClasses="orders-table table-responsive table-hover"
                                bordered={false}
                                noDataIndication="Lista Vacía"
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </div>
        </Row>
    );
}

export default TableComponents;