import React, { useState } from 'react';
import { FormFields, AsyncSelectFields, InputFields, ButtonFields } from '../../../components/Index';
import { addProductSalesSchema } from '../../../utils/validations/ProductValidation';
import { getListaProductoCombo, getDisponiblidadProducto, getProductoAlquilerCombo } from '../../../services/ProductService';
import { showErrorAlert } from '../../../utils/helper/AlertService';


const AddProductoComponents = ({ tipo = "",onAddProducto }) => {

    const [ showAlquiler,  setShowAlquiler ] = useState(false);
    const [ detalle, setDetalle ] = useState({});
    

    const onClickAdd = (values, onSubmitProps) => {
        values.fechaInicio = values.fechaInicio === '' ? null : values.fechaInicio;
        values.fechaFin = values.fechaFin === '' ? null : values.fechaFin;
        values.descripcion = detalle.descripcion;
        values.nombre = detalle.nombre;
        values.precioTotal = values.precio === "" ? '' : parseInt(values.stock) * parseFloat(values.precio);

        getDisponiblidadProducto(values).then(() => {
            onAddProducto(values);
            onSubmitProps.resetForm();
        }).catch((e) => {
            showErrorAlert('Error !',  e.apiMessage || 'Error desconocido');
        });
        
    }

    const onSelectProducto = (data) => {
        (data.tipoProductoId === "e9303eb0-a00f-4fd3-a142-24fd067cfb56") ? setShowAlquiler(true): setShowAlquiler(false);
        setDetalle(data);
    }

    const fechDataProduct = async (value) => {
        var resp_product = {};
        if(tipo === "02"){
            resp_product = await getProductoAlquilerCombo(value);
        }
        else {
            resp_product = await getListaProductoCombo(value);
        }
       
        return resp_product;
    }
    return (
        <FormFields
            initialValues = {{
                productoId:'',
                stock:'1',
                precio: "",
                fechaInicio: '',
                fechaFin: ''
            }}
            onSubmit = {(values, onSubmitProps) => onClickAdd(values, onSubmitProps)}
            validationSchema = {addProductSalesSchema}
        >
            <div className='row'>
                <div className='col-12'>
                    <AsyncSelectFields
                        idFields = {"productoId"}
                        label={"Producto"}
                        name={"productoId"}
                        loadOptions = {fechDataProduct}
                        onSelect={(item) => onSelectProducto(item)}
                    />
                </div>
                {
                showAlquiler && 
                <>
                    <div className='col-sm-12 col-md-6 col-lg-6'>
                        <InputFields
                            label={"Fecha Alquiler"}
                            type="datetime-local"
                            name="fechaInicio"
                            size = "sm"
                            isRequired = {false}
                        />
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-6'>
                        <InputFields
                            label={"Fecha Entrega"}
                            type="datetime-local"
                            name="fechaFin"
                            size = "sm"
                            isRequired = {false}
                        />
                    </div>
                </>
                }
                
                <div className='col-sm-6 col-md-5 col-lg-5'>
                    <InputFields
                    label={"Cantidad"}
                    type="text"
                    name="stock"
                    size = "sm"
                    />
                </div>
                <div className='col-sm-6 col-md-5 col-lg-5'>
                    <InputFields
                        isRequired = {false}
                        label={"Precio Unitario"}
                        type="text"
                        name="precio"
                        size = "sm"
                    />
                </div>
                <div className='col-ms-12 col-md-2 col-lg-2' style={{marginTop:'20px'}}>
                    <ButtonFields
                        className='btn btn-inverse-primary btn-sm btn-block float-right'
                        title = {
                            <i className='fa fa-shopping-cart'/> 
                        }/>
                </div>
            </div>
        </FormFields>
    );
}

export default AddProductoComponents;