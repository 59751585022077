import Client from './Client';

const endpoint= "api/v1/";

// Apis para Listar
export const getListaCategoria = () => {
    return Client.get(`${endpoint}Categoria/Lista`)
}
export const getListaAllCategoria = () => {
    return Client.get(`${endpoint}Categoria/ListaAll`)
}
export const getTipoProducto = () => {
    return Client.get(`${endpoint}TipoProducto/Lista`)
}
export const getListaModelo = (categoriaId) => {
    return Client.get(`${endpoint}Modelo/Lista?CategoriaId=${categoriaId}`)
}
export const getListaProducto = (data) => {
    return Client.post(`${endpoint}Producto/Listar`,data)
}
export const getListaProductoTipoCombo = (tipo) => {
    return Client.get(`${endpoint}Producto/Combo?TipoCodido=${tipo}`)
}
export const getListaProductoCombo = (search) => {
    return Client.get(`${endpoint}Producto/Combo?Search=${search}&TipoCodido=01&TipoCodido=02&TipoCodido=04`)
}
export const getProductoAlquilerCombo = (search) => {
    return Client.get(`${endpoint}Producto/Combo?Search=${search}&TipoCodido=02`)
}
export const getListaServiciosCombo = (search) => {
    return Client.get(`${endpoint}Producto/Combo?Search=${search}&TipoCodido=03`)
}
export const getListaTipoServicio = () => {
    return Client.get(`${endpoint}TipoServicio/Lista`)
}
export const getListaShopping = (data) => {
    return Client.post(`${endpoint}Compras/Lista`,data);
}
export const getDetalleShopping = (compraId) => {
    return Client.get(`${endpoint}Compras/Detalle?CompraId=${compraId}`);
}
export const getDisponiblidadProducto = (data) => {
    return Client.post(`${endpoint}Producto/Disponibilidad`,data);
}
export const getListaActividades = (data) => {
    return Client.post(`${endpoint}DetalleActividad/Lista`,data);
}


// Apis para Crear
export const createCategories = (data) => {
    return Client.post(`${endpoint}Categoria/Crear`, data)
}

export const createProducto = (data) => {
    return Client.post(`${endpoint}Producto/Crear`, data)
}

export const createPayment = (data) => {
    return Client.post(`${endpoint}Compras/CrearPagos`, data)
}

export const createShopping = (data) => {
    return Client.post(`${endpoint}Compras/Crear`, data)
}

export const createModel = (data) => {
    return Client.post(`${endpoint}Modelo/Crear`, data)
}

// Eliminar 

export const deleteModel = (id) => {
    return Client.delete(`${endpoint}Modelo/Delete/${id}`)
}
export const deleteCategoria = (id) => {
    return Client.delete(`${endpoint}Compras/Eliminar/${id}`)
}
export const deleteProducto = (id) => {
    return Client.delete(`${endpoint}Producto/Delete/${id}`)
}

// Actualizar
export const editModel = (data) => {
    return Client.put(`${endpoint}Modelo/Editar`,data)
}
export const actualizarProducto = (data) => {
    return Client.put(`${endpoint}Producto/Actualizar`,data)
}
export const editCategory = (data) => {
    return Client.put(`${endpoint}Categoria/Editar`,data)
}