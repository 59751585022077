import React from 'react';

function ErrorMessage({error,visible}) {
    if(!visible || !error) return null
    return (
        <div>
            <p className ="text-danger">{error}</p>
        </div>
    );
}

export default ErrorMessage;