import apiConfig from './ApiConfig';
import handleApiError from './ErrorHandler';

const Client = {
    post: async (url, data, type = 'json') => {
      try {
        const response = await apiConfig.post(url, data, { responseType: type});
        return response.data;
      } catch (error) {
        handleApiError(error);  
      }
    },
    get: async (url) => {
      try {
        const response = await apiConfig.get(url);
        return response.data;
      } catch (error) {
        handleApiError(error);
      }
    },
    put: async (url, data) => {
        try {
          const response = await apiConfig.put(url, data);
          return response.data;
        } catch (error) {
          handleApiError(error);
        }
    },
    delete: async (url, data) => {
        try {
          const response = await apiConfig.delete(url);
          return response.data;
        } catch (error) {
          handleApiError(error);
        }
      },
  };
  
  export default Client;