import { useState } from 'react';
import { Context } from './AuthContext';


const AuthProvider = ({ children }) => {
    const [ token , setToken] = useState(() => sessionStorage.getItem("jwt") || '')

  return (
    <Context.Provider value={{ token, setToken }}>
        {children}
    </Context.Provider>
  )
}

export default AuthProvider;
