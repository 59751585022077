import React, { useEffect, useState } from 'react';
import { Card,Row } from 'react-bootstrap';
import CardList from './components/CardList';
import CardItem from './components/CardItem';
import { Button, ButtonFields, FormFields, ModalComponents, InputFields } from '../../components/Index';
import { createModel, createCategories, editModel, editCategory, getListaAllCategoria, deleteModel } from '../../services/ProductService';
import { CategoriaSchema, ModeloSchema } from '../../utils/validations/ProductValidation';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../utils/helper/AlertService';


const CATEGORIA = 1;
const MODELO = 2;
const CATEGORIA_UPDATE = 3;
const MODELO_UPDATE = 4;


export default function ListCategories() {
    const [ categorias, setCategorias ] = useState([]);
    const [ model, SetModel ] = useState({});
    const datos_modal = { mdModal: false, titulo: "", cod: 0 }
    const [ infModal, setInfModal ] = useState(datos_modal)

    const onClickSend = (values, onSubmitProps) => {
        var data = "";
        if(infModal.cod === MODELO){
            data = createModel(values);
        }
        if(infModal.cod === CATEGORIA){
            data = createCategories(values);
        }
        if(infModal.cod === MODELO_UPDATE){
            data = editModel(values)
        }
        if(infModal.cod === CATEGORIA_UPDATE){
            data = editCategory(values);
        }

        data.then(() => {
            showSuccessAlert('La operación fue realizado con éxito')
            getData();
            setInfModal(datos_modal);
            onSubmitProps.setSubmitting(false)
            onSubmitProps.resetForm();
        }).catch((error) => {
            showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
        });
    }

    const addList = (id) => {
        SetModel({categoriaId: id, nombre:''})
        setInfModal({...infModal,mdModal: true,titulo:"Agregar Modelo", cod: MODELO})
        
    }
    const addCategoria = () => {
        SetModel({id: "", nombre:''})
        setInfModal({...infModal,mdModal: true,titulo:"Agregar Categoria", cod: CATEGORIA})
    }

    const deleteLista = (id) => {
        showConfirAlert({
            title: '¿Estás seguro de eliminar?',
            text: "¡No padrás revertir esto!",
            preConfirm: () => {
                deleteModel(id).then(() => {
                    showSuccessAlert('El registro fue eliminado con éxito');
                    getData();
                }).catch((error) => {
                    showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
                });
            }
        });
    }

    const udateLista = (item) => {
        SetModel({id: item.id, nombre: item.nombre})
        setInfModal({...infModal,mdModal: true,titulo:"Editar Modelo", cod: MODELO_UPDATE})
    }

    const udateCategory = (item) => {
        SetModel({id: item.id, nombre: item.nombre})
        setInfModal({...infModal,mdModal: true,titulo:"Editar Categoria", cod: CATEGORIA_UPDATE})
    }

    const getData = async () => {
        const response = await getListaAllCategoria();
        setCategorias(response)
    }

    useEffect(() => {
        getData();
    },[]);
    
  return (
    <div>
        <Row>
            <div className='col-12'>
                <Card>
                    <Card.Body>
                        <section className="row">
                            <div className='col-ms-12 col-6 d-ms-none'>
                                <h4 className="card-title">Categorias</h4>
                            </div>
                            <div className='col-ms-12 col-6'>
                                <button 
                                    className="badge badge-primary ml-auto ml-2 float-right"
                                    onClick={() => addCategoria()}
                                >
                                    <i className="fa fa-plus mr-2"></i> Categoria
                                </button>
                            </div>
                        </section>
                        <section className='row'>
                        {
                            categorias && categorias.map((item, index) => (
                                <article key={index} className="col-sm-12 col-md-6 col-lg-6">
                                    <CardList 
                                        nombre={item.nombre} 
                                        editList={() => udateCategory(item)}
                                    >
                                        <CardItem 
                                            lista={item.listaModelo}
                                            deleteList={(id) => deleteLista(id)}
                                            updateList={(modelo) => udateLista(modelo)}
                                            addList={() => addList(item.id)}
                                        />
                                    </CardList>
                                </article>
                            ))
                        }
                        </section>
                        <ModalComponents
                            size="md"
                            show={infModal.mdModal}
                            hide={() => setInfModal(datos_modal)}
                            titulo={infModal.titulo}
                        >
                            <FormFields
                                initialValues = {model}
                                onSubmit = {(values, onSubmitProps) => onClickSend(values,onSubmitProps)}
                                validationSchema = {(infModal.cod === 1 || infModal.cod === 3) ? CategoriaSchema: ModeloSchema }
                                >
                                    <div className="row">
                                        <section className="col-12">
                                            <InputFields
                                                label={"Nombre"}
                                                type="text"
                                                name="nombre"
                                                size = "sm"
                                            />
                                        </section>
                                        <div className="col-12 float-right mt-2">
                                            <ButtonFields/>
                                            <Button 
                                                onClick={() => setInfModal(datos_modal)}
                                                className = "float-right ml-3"
                                                color = "danger"
                                                label= "Cancelar"
                                            />
                                        </div>
                                </div> 
                            </FormFields>
                        </ModalComponents>
                    </Card.Body>
                </Card>
            </div>
        </Row>
    </div>
  )
}
