import React from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import { logingSchema } from '../../utils/validations/PersonValidation';
import {Navigate} from 'react-router-dom';
import useUser from '../../hooks/useUser';
import Loading from '../../components/Loading';

function Login() {
    const { isLoggedIn, login, isLoading, error } = useUser();

    if(isLoggedIn){
        return <Navigate to="/" replace/>
    }

    return (
        <div className='bg-gris d-flex justify-content-center align-items-center vh-100'>
            <div className='bg-white p-5 rounded' style={{width:'23rem'}}>
                <div className='d-flex justify-content-center'>
                    <img src={require("../../assets/images/perfil_prueba.jpg")} alt='logo-icon' style={{height:'7rem'}}></img>
                </div>
                <h4 className='text-center font-weight-bold text-secondary'>Login</h4>
                {error && <p className='text-danger text-center pb-0'>{error}</p>}
                <Formik
                    initialValues={{
                        email:"",
                        password:""
                    }}
                    validationSchema={logingSchema}
                    onSubmit={(values) => login(values)}
                >
                    <Form>
                        <section className='pt-4'>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text bg-info" id="basic-addon1">
                                        <i className='fa fa-user-o text-white'></i>
                                    </span>
                                </div>
                                <Field type="email" name="email" className="form-control p-2" placeholder="Username"/>
                            </div>
                            <ErrorMessage name="email" component="p" className="error text-danger"  />
                        </section>
                        
                      
                        <section className='pt-2'>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text bg-info" id="basic-addon1">
                                        <i className='fa fa-lock text-white'></i>
                                    </span>
                                </div>
                                <Field type="password" name="password" className="form-control p-2" placeholder="password"/>
                            </div>
                            <ErrorMessage name="password" component="p" className="error text-danger"  />
                        </section>
                            
                        <button type='submit' className='btn btn-info w-100 mt-4'>Login</button>
                    </Form>
                </Formik>
                <Loading isLoading = {isLoading}/>
            </div>
        </div>
    );
}

export default Login;