import React, { useEffect, useState } from 'react';
import { Card, Row } from "react-bootstrap";
import { Search, Table, FormFields, InputFields, SelectFields, ButtonFields, ButtonResetFields, ModalComponents } from '../../components/Index';
import { createBitacoraSchema, createActividadBitacoraSchema } from '../../utils/validations/ProductValidation';
import { getComboEmpleado } from '../../services/PersonServices';
import { getListaBitacora, crearBitacora, crearDetalleBitacora, getListaDetalleBitacora, eliminarBitacora, eliminarDetalleBitacora, actualizarBitacora } from '../../services/ExpensesService';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../utils/helper/AlertService';
import UserPermissionsComponent from '../auth/UserPermissionsComponent';
 
const search = {
    skip: 1,
    take: 10,
    search: "",
    empleadoId: null,
    fecha: ""
}

const estados = [
    {
        value:"01", 
        label:"En Curso"
    },
    {
        value:"03", 
        label:"Aprobado"
    }
]
export default function ListExpenses() {
    const [ paramSearch, setParamSearch ] = useState(search);
    const [ listEgresos, setListEgresos ] = useState([]);
    const [ expensesPag, setExpensesPag ] = useState({});
    const [ mdDetalle, setMdDetalle ] = useState(false);
    const [ mdAgregar, setMdAgregar ] = useState(false);
    const [ detalleEgresos, setDetalleEgresos ] = useState([]);
    const [ bitacora, setBitacora ] = useState({});
    const [ empleado, setEmpleado ] = useState([]);

    const onClickAprobacion = (value) => {
        showConfirAlert({
            title: '¿Confirmar solicitud de aprobación?',
            text: "¡No padrás revertir esto!",
            preConfirm: () => {
                actualizarBitacora(value).then(() => {
                    showSuccessAlert('!Solicitud Enviada!');
                    fechData(paramSearch)
                    setMdDetalle(false);
                }).catch((error) => {
                    showErrorAlert('Error !',  'Ocurrio un error, vuelva intentarlo');
                });
            }
        });
    }

    const onClickConfirmarAprobacion = (values) => {
        actualizarBitacora(values).then(() => {
            showSuccessAlert('!Solicidud Confirmada!');
            fechData(paramSearch)
            setMdDetalle(false);
        }).catch((e) => {
            showErrorAlert('Error !',  'Ocurrio un error, vuelva intentarlo');
        });
    }
    
    const onClickCrear = (values,onSubmitProps) => {
        crearBitacora(values).then((res) => {
            showSuccessAlert('!Registro Exitoso!');
            fechData(paramSearch);
            onSubmitProps.resetForm();
        }).catch((e) => {
            showErrorAlert('Error !',  'Error, No se logró realizar el registo.');
        });
    }

    const onClickSendActividad = (values,onSubmitProps) => {
        crearDetalleBitacora(values).then((res) => {
            showSuccessAlert('!Registro Exitoso!');
            fechDetalle(values.bitacoraId);
            onSubmitProps.resetForm();
        }).catch((e) => {
            showErrorAlert('Error !',  'Error, No se logró realizar el registo.');
        });
    }

    const onClickEliminarBitacora = (bitacoraId) => {
        showConfirAlert({
            title: '¿Estás seguro de eliminar?',
            text: "¡No padrás revertir esto!",
            preConfirm: () => {
                eliminarBitacora(bitacoraId).then(() => {
                    showSuccessAlert('!El registro fue eliminado con éxito!');
                    fechData(paramSearch)
                    setMdDetalle(false);
                }).catch((error) => {
                    showErrorAlert('Error !', 'Error, No se logró eliminar el registro.');
                });
            }
        });
    }

    const onClickEliminarDetalleBitacora = (DetalleBitacoraId) => {
        showConfirAlert({
            title: '¿Estás seguro de eliminar?',
            text: "¡No padrás revertir esto!",
            preConfirm: () => {
                eliminarDetalleBitacora(DetalleBitacoraId).then(() => {
                    showSuccessAlert('!El registro fue eliminado con éxito!');
                    fechData(paramSearch)
                    setMdDetalle(false);
                }).catch((error) => {
                    showErrorAlert('Error !',  'Error, No se logró eliminar el registro.');
                });
            }
        });

    }
    
    const handleTableChange = (page, sizePerPage) => {
        setParamSearch({
            ...paramSearch,
            skip:sizePerPage.page,
            take:sizePerPage.sizePerPage,
        })
    }

    const onClickDetalle = async (item) => {
        setBitacora(item);
        setMdDetalle(true)
        fechDetalle(item.bitacoraId);
    }

    const onInputSearch = ({target}) => {
        const { value } = target;
        setParamSearch({...paramSearch,search:value})
    }

    const fechDetalle = async (id) => {
        const data =  await getListaDetalleBitacora(id);
        setDetalleEgresos(data)
    }
    const fechData = async (param) => {
        const data = await getListaBitacora(param);
        setListEgresos(data.items);
        setExpensesPag(data);
    }

    const getData = async () => {
        const data = await getComboEmpleado("");
        setEmpleado(data);
    }

    useEffect(() => {
        getData();
    },[]);

    useEffect(() =>{
        fechData(paramSearch);
    }, [paramSearch])


    const columns = [
        { 
            dataField: "motivo", 
            text: "Motivo"
        },
        { 
            dataField: "empleado", 
            text: "Empleado"
        },
        {
            dataField: "monto",
            text: "Monto"
        },
        {
            dataField: "gasto",
            text: "Gastó"
        },
        {   
            dataField: "devuelto",     
            text: "Devolvió"
        },
        { 
            dataField: "fecha", 
            text: "Fecha" 
        },
        {   
            dataField: "estado", 
            text: "Estado",
            classes: 'py-1',
            formatter: (cellContent, row) => {
                if (cellContent === '01') {
                    return (
                    <label className="badge badge-success m-0">En Curso</label>
                    );
                } 
                else if (cellContent === '02' ) {
                    return (
                    <label className="badge badge-warning m-0">Pendiente Aprobación</label>
                    );
                }
                else if (cellContent === '03' ) {
                    return (
                    <label className="badge badge-info m-0">Aprobado</label>
                    );
                }
            }
        }, 
        {
            dataField: "opciones", 
            text: "Opciones",
            sort: false,
            align: 'center',
            classes: 'py-1',
            formatter: (cellContent, row) => {
            return (
                <div>
                    <i className="ti-eye text-primary mr-2" data-toggle="tooltip" data-placement="right" title="Detalle" onClick={() => onClickDetalle(row)} style={{fontSize:'15px'}}/>
                    <UserPermissionsComponent accion={['admin','admVenta']}>
                        <i className="ti-trash text-danger" data-toggle="tooltip" data-placement="right" title="Eliminar" onClick={() => onClickEliminarBitacora(row.bitacoraId)} style={{fontSize:'15px'}}/>
                    </UserPermissionsComponent>
                </div>
            );
            }
        }
          
    ]
  return (
    <div>
        <Row >
            <div className="col-12">
                <Card>  
                    <Card.Body>
                        <div className="row mb-4 justify-content-between">
                            <div className = "col-lg-3 d-none d-sm-none d-md-block">
                                <h4 className="card-title float-left mt-2">Lista Egresos Diarios</h4>
                            </div>
                            <div className = "col-ms-12 col-md-6 col-lg-6  mb-2">
                                <Search
                                    onSearchInput={onInputSearch}
                                    value={paramSearch.search}
                                    onRefesh={() => setParamSearch(search)}
                                >
                                    <FormFields
                                        initialValues={paramSearch}
                                        onSubmit={(values) => setParamSearch(values)}
                                    >
                                        <div className='row'>
                                            <div className='col-ms-12 col-md-6 col-lg-6'>
                                                <SelectFields 
                                                    label={"Empleado"}
                                                    name={"empleadoId"}
                                                    isRequired = {false}
                                                    data={empleado.map((item) =>({value:item.empleadoId, label: item.nombre }))}
                                                />
                                            </div>
                                            <div className='col-ms-12 col-md-6 col-lg-6'>
                                                <InputFields
                                                    label={"Fecha Entrega"}
                                                    type="date"
                                                    name="fecha"
                                                    size = "sm"
                                                    
                                                />
                                            </div>
                                            <div className='col-12'>
                                                <ButtonFields title='Buscar'/>
                                                <ButtonResetFields title='Limpiar' onClickCancel={() => setParamSearch(search)}/>
                                            </div>
                                        </div>
                                    </FormFields>
                                </Search>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                                <UserPermissionsComponent accion={['admin','admVenta']}>
                                    <button className="btn btn-outline-primary float-right" onClick={() => setMdAgregar(true)}>
                                        <i className="fa fa-plus mr-2"/>
                                        Egreso Diario
                                    </button>
                                </UserPermissionsComponent>
                            </div>
                        </div> 
                    
                        <Table
                            columns={columns}
                            list={listEgresos}
                            keyField='bitacoraId'
                            page={expensesPag.page}
                            sizePerPage={paramSearch.take}
                            totalSize={expensesPag.total}
                            onTableChange={handleTableChange}
                        />
                        <ModalComponents
                                titulo={`${bitacora.fecha}  -  ${bitacora.empleado}`}
                                show = {mdDetalle}
                                hide={() => {setMdDetalle(false); fechData(paramSearch)}}
                                size='md'
                            >
                            <div>
                                <div className="row">
                                    {
                                        (bitacora.estado === "01") &&
                                        <div className='col-12'>
                                            <FormFields
                                                initialValues = {{
                                                    bitacoraId: bitacora.bitacoraId,
                                                    descripcion: '',
                                                    monto: ''
                                                }}
                                                onSubmit = {(values, onSubmitProps) => onClickSendActividad(values,onSubmitProps)}
                                                validationSchema = {createBitacoraSchema}
                                            >
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-7 col-lg-7'>
                                                    <InputFields
                                                        label={"Descripción"}
                                                        type="text"
                                                        name="descripcion"
                                                        size = "sm"
                                                    />
                                                </div>
                                                <div className='col-sm-6 col-md-3 col-lg-3'>
                                                    <InputFields
                                                        label={"Monto"}
                                                        type="text"
                                                        name="monto"
                                                        size = "sm"
                                                    />
                                                </div>
                                                <div className='col-sm-6 col-md-2 col-lg-2' style={{marginTop:'20px'}}>
                                                <ButtonFields
                                                    className='btn btn-primary float-right ml-3 btn-sm'
                                                    title = {"Agregar"}/>
                                                </div>
                                            </div>
                                            </FormFields>
                                        </div>
                                    }
                                    <div className="col-12">
                                        <div className="mt-4">
                                            <div className="timeline alin">
                                            {
                                                detalleEgresos && detalleEgresos.map((item, index) => (
                                                    <div className="timeline-wrapper timeline-inverted timeline-wrapper-warning" key={index}>
                                                        <div className="timeline-badge"></div>
                                                        <div className="timeline-panel p-2">
                                                            <div className="timeline-heading">
                                                                {bitacora.estado === "01" && <i className="ti-trash text-danger float-right" data-toggle="tooltip" data-placement="right" title="Eliminar" onClick={() => onClickEliminarDetalleBitacora(item.detalleBitacoraId)}/>}
                                                                <p className="text-uppercase font-weight-bold">{item.descripcion}</p>
                                                            </div>
                                                            <div className="timeline-body">
                                                                <p className='text-dark'>Precio: S/. {item.monto}</p>
                                                            </div>
                                                            <div className="timeline-footer d-flex align-items-center flex-wrap">
                                                                <span className="ml-md-auto font-weight-bold">{item.fecha}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        bitacora.estado === "01" &&
                                        <div className='col-12 mt-4'>
                                            <button className='btn btn-primary btn-sm float-right' onClick={() => onClickAprobacion({bitacoraId: bitacora.bitacoraId, codEstado: "02"})}>Solicitar aprobación</button>
                                        </div>
                                    }
                                    {
                                        bitacora.estado === "02" &&
                                        <UserPermissionsComponent accion={['admin','admVenta']}>
                                            <div className='col-12 mt-4'>
                                                <FormFields
                                                        initialValues = {{
                                                            bitacoraId: bitacora.bitacoraId,
                                                            codEstado: '03'
                                                        }}
                                                        onSubmit = {(values) => onClickConfirmarAprobacion(values)}
                                                    >
                                                    <div className='row'>
                                                        <div className='col-lg-4 col-md-4 col-sm-12'><p className='text-muted'>Verificar antes de aceptar la aprovación</p></div>
                                                        <div className='col-lg-4 col-md-4 col-sm-6'>
                                                            <SelectFields 
                                                                name={"codEstado"}
                                                                data={estados}
                                                            />
                                                        </div>
                                                        <div className='col-lg-4 col-md-4 col-sm-6'>
                                                            <ButtonFields
                                                                className='btn btn-primary float-right ml-3 btn-sm btn-block'
                                                                title = {"Confirmar"}/>
                                                        </div>
                                                    </div>
                                                </FormFields>
                                            </div>
                                        </UserPermissionsComponent>
                                    }                                   
                                </div>
                            </div>
                        </ModalComponents>

                        <ModalComponents
                            titulo={"Agregar"}
                            show={mdAgregar}
                            hide={() => setMdAgregar(false)}
                            size='md'
                        >
                            <FormFields
                                initialValues = {{
                                    empleadoId: '',
                                    monto: '',
                                    motivo: ''

                                }}
                                onSubmit = {(values, onSubmitProps) => onClickCrear(values,onSubmitProps)}
                                validationSchema = {createActividadBitacoraSchema}
                            >
                            <div className='row'>
                                <div className='col-12'>
                                    <InputFields
                                        label={"Motivo"}
                                        type="text"
                                        name="motivo"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-sm-8 col-md-7 col-lg-7'>
                                    <SelectFields 
                                        label={"Empleado"}
                                        name={"empleadoId"}
                                        data={empleado.map((item) =>({value:item.empleadoId, label: item.nombre }))}
                                    />
                                </div>
                                <div className='col-sm-4 col-md-5 col-lg-5'>
                                    <InputFields
                                        label={"Monto"}
                                        type="text"
                                        name="monto"
                                        size = "sm"
                                    />
                                </div>
                                <div className='col-12'>
                                    <ButtonFields title='Agregar'/>
                                </div>
                            </div>
                            </FormFields>
                        </ModalComponents>
                    </Card.Body>
                </Card>
            </div>
        </Row>
    </div>
  )
}
