import React, { useEffect, useState } from 'react';
import { Card, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getDetalleShopping, createPayment } from '../../services/ProductService';
import { ButtonFields, FormFields, InputFields, ModalComponents, ItemName } from '../../components/Index';
import { addPagoCompraSchema } from '../../utils/validations/ShoppingValidation';
import { showErrorAlert,showConfirAlert,showSuccessAlert } from '../../utils/helper/AlertService';
import { format } from 'date-fns';
 
export default function DetailShopping() {
    let { compraId } = useParams();
    const currentDate = format(new Date(), 'yyyy-MM-dd');
    const [compra, setCompra] = useState({});
    const [actividadPago, setActividadPago] = useState([]);
    const [mdActPag, setMdActPag] = useState(false);
    const [totalPagoDeuda, setTotalPagoDeuda] = useState(0);

    const onAddActividadPago = (values,onSubmitProps) => {
        var deuda = parseFloat(totalPagoDeuda) + parseFloat(values.monto)
        if(parseFloat(compra.precioTotal) >= deuda) {
            showConfirAlert({
                title: "Desea registrar Pago",
                text: "Verifique que todos los campos requeridos no esten vacíos",
                preConfirm: () => {
                    createPayment(values).then(() => {
                        showSuccessAlert('Registro Exitoso');
                        onSubmitProps.setSubmitting(false)
                        onSubmitProps.resetForm();
                        getData(compraId);
                        setMdActPag(false);
                    }).catch((error) => {
                        showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
                    });
                }
            });
        }
        else{
            showErrorAlert('', 'El Monto de Pago no puede ser mayor que el precio total'); 
        }
    }
    
    const getData = async (id) => {
        const result = await getDetalleShopping(id);
        setCompra(result);
        setActividadPago(result.pagosCompras);
    }
    useEffect(() => {
        let reduce = actividadPago.reduce((acumulador, actual) => acumulador + parseFloat(actual.monto), 0);
        setTotalPagoDeuda(reduce);
    },[actividadPago,compra])

    useEffect(() =>{
        getData(compraId);
    },[compraId])
  return (
    <div>
        <Row >
            <div className="col-12">
                <Card>  
                    <Card.Body>
                        <div className='row'>
                            <section className='col-12  d-none d-sm-none d-md-block mb-2'>
                                <button className="btn p-0 mr-3 float-left text-decoration-none" onClick={() => window.history.go(-1)}>
                                    <i className="ti-arrow-circle-left text-info" style={{fontSize:19}}></i>
                                </button>
                                <h4 className='card-title float-left pt-1'>Detalle Compra</h4>
                            </section>
                            <section className='col-6'>
                                <div className='row mb-3'>
                                    <div className='col-auto col-lg-12 mb-2'>
                                        <ItemName 
                                            className='m-0'
                                            titulo={"Estado:"}
                                            classNameText={compra.estado === "01" ? 'text-danger font-weight-bold':'text-primary font-weight-bold'}
                                            detalle={compra.estado === "01" ? "Con Deuda": "Cerrado"}
                                        />
                                    </div>
                                    <div className='col-auto col-lg-12 mb-2'>
                                        <ItemName 
                                            className='m-0'
                                            titulo={"Proveedor:"}
                                            detalle={compra.proveedorNombre}
                                        />
                                    </div>
                                    <div className='col-auto col-lg-12 mb-2'>
                                        <ItemName 
                                            className='m-0'
                                            titulo={"Dirección:"}
                                            detalle={compra.direccion}
                                        />
                                    </div>
                                    <div className='col-auto col-lg-12 mb-2'>
                                        <ItemName 
                                            className='m-0'
                                            titulo={"Telefono / Celular:"}
                                            detalle={compra.telefono}
                                        />
                                    </div>
                                </div>
                            </section>
                            <section className='col-6'>
                                <div className='row mb-3'>
                                    <div className='col-auto col-lg-12 mb-2'>
                                        <ItemName 
                                            className='m-0'
                                            titulo={"Tipo de Pago:"}
                                            detalle={compra.tipoPago}
                                        />
                                    </div>
                                    <div className='col-auto col-lg-12 mb-2'>
                                        <ItemName 
                                            className='m-0'
                                            titulo={"Fecha de Compra:"}
                                            detalle={compra.fecha}
                                        />
                                    </div>
                                    <div className='col-auto col-lg-12 mb-2'>
                                        <ItemName 
                                            className='m-0'
                                            titulo={"Empleado:"}
                                            detalle={compra.empleado}
                                        />
                                    </div>
                                </div>
                            </section>
                            <section className="container-fluid mt-2 d-flex justify-content-center w-100">
                                <div className="table-responsive w-100">
                                    <table className="table">
                                        <thead>
                                            <tr className="bg-primary text-white">
                                                <th>Nombre</th>
                                                <th className="text-center">Cantidad</th>
                                                <th className="text-center">Precio Compra (p/u)</th>
                                                <th className="text-center">Sub. Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            compra.detalles && compra.detalles.map((item, index) =>
                                            <tr key={index} className="text-right">
                                                <td className="text-left">{item.productoNombre}</td>
                                                <td className="text-center">{item.cantidad}</td>
                                                <td className="text-center">$ {parseFloat(item.precio).toFixed(2)}</td>
                                                <td className="text-center">$ {parseFloat(item.subTotal).toFixed(2)}</td>
                                            
                                            </tr>
                                            )
                                        }
                                        
                                        </tbody>
                                    </table>
                                </div>
                            </section>
                            <div className="container-fluid mt-3 w-100">
                                <h6 className="text-right text-muted">Op. Gravada : S/. {parseFloat(compra.montoAgrabada).toFixed(2)}</h6>
                                <h6 className="text-right text-muted">IGV 18% : S/. {parseFloat(compra.impuesto).toFixed(2)}</h6>
                                <h4 className="text-right mb-3">Total : S/. {parseFloat(compra.precioTotal).toFixed(2)}</h4>
                                <hr/>
                            </div>
                            {
                                compra.tipoPagoId === "c81c314c-40a5-467c-b8ee-b2b6513b5704" && (
                                    <div className="col-lg-12 grid-margin stretch-card">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="card-title float-left">Actividad de Pagos</h4>
                                                {
                                                    compra.estado === "01" && (
                                                        <button className="btn btn-outline-primary btn-sm float-right mb-2" onClick={() => setMdActPag(true)}>
                                                            <i className="fa fa-plus mr-2"/>
                                                            Nueva Registro
                                                        </button>
                                                    )
                                                }
                                                
                                                <div className="table-responsive">
                                                    <table className="table table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th> # </th>
                                                            <th> Fecha </th>
                                                            <th> Monto Cancelado </th>
                                                            <th> Num. Recivo </th>
                                                            <th> Empleado</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            actividadPago && actividadPago.map((item,index) => (
                                                                <tr key={index}>
                                                                    <td className="py-1">{index + 1}</td>
                                                                    <td> {item.fechaPago} </td>
                                                                    <td> $ {parseFloat(item.monto).toFixed(2)} </td>
                                                                    <td> {item.numeroRecibo} </td>
                                                                    <td> {item.empleado} </td>
                                                                </tr>
                                                            ))
                                                        }
                                                            
                                                        </tbody>
                                                    </table>
                                                    
                                                </div>
                                                <h4 className="text-right text-danger mt-3">Deuda Pendiente S/. {compra.deuda}</h4>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <ModalComponents
                            size={"md"} 
                            show={mdActPag} 
                            hide={()=>setMdActPag(false)}  
                            titulo={"Actividad Pago"} 
                        >
                            <FormFields
                                initialValues = {{
                                    compraId: compraId,
                                    fechaPago: currentDate,
                                    numeroRecibo:'',
                                    monto:'',
                                }}
                                onSubmit = {(values, onSubmitProps) => onAddActividadPago(values,onSubmitProps)}
                                validationSchema = {addPagoCompraSchema}
                            >
                                <div className='row'>
                                    <div className='col-ms-12 col-md-4 col-lg-4'>
                                        <InputFields
                                            label={"Fecha Pago"}
                                            type="date"
                                            name="fechaPago"
                                            size = "sm"
                                        />
                                    </div>
                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                        <InputFields
                                            label={"Monto de Pago"}
                                            type="text"
                                            name="monto"
                                            size = "sm"
                                        />
                                    </div>
                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                        <InputFields
                                            label={"Número Recivo"}
                                            type="text"
                                            name="numeroRecibo"
                                            size = "sm"
                                            isRequired = {false}
                                        />
                                    </div>
                                    <div className='col-12 float-right mt-2'>
                                        <ButtonFields
                                            className='btn btn-outline-primary btn-sm  float-right'
                                            title = "Guardar"/>
                                    </div>
                                </div>
                            </FormFields>  
                        </ModalComponents>
                    </Card.Body>
                </Card>
            </div>
        </Row>
    </div>
  )
}
