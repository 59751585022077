import React from 'react';
import { Form } from 'react-bootstrap';
import ErrorMessage from '../ErrorMessage';
import { useFormikContext } from 'formik';
import { LabelFields } from './LabelFields';

function InputFields({label,name, onSearch = () => {}, isRequired = true, ...otherProps}) {
    const { setFieldTouched, setFieldValue, errors,touched, values } = useFormikContext();
    const handleInputChange = (e) => {
        const { value, type } = e.target;
        const tipo = ['text','textarea']
        if (tipo.includes(type) && typeof value === 'string') {
            setFieldValue(name, value.toUpperCase());  // Convierte a mayúsculas y actualiza el valor en Formik
        } else {
            setFieldValue(name, value);  // Si no es una cadena, solo actualiza el valor
        }
    };
    return (
        <div className='mb-2'>
            {
                label && <LabelFields label={label} isRequired = {isRequired} />
            }
            <Form.Control
                onChange={handleInputChange}
                onBlur = {() => {setFieldTouched(name); onSearch(values[name])}}
                value = {values[name] || ''}
                {...otherProps}
            />
            <ErrorMessage error={errors[name]} visible={touched[name]}  />
        </div>
    );
}

export default InputFields;