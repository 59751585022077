import React, { useEffect, useState } from 'react';
import { Card, Row } from "react-bootstrap";
import {Loading, Table, ModalComponents, Search, InputFields, AsyncSelectFields, FormFields, ButtonFields, ButtonResetFields} from '../../../components/Index';
import { getListaFallecido, crearFallecido, actualizarFallecido, getComboFallecido,deletePersona } from '../../../services/PersonServices';
import FormCreateDeceased from '../components/FormCreateDeceased';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../../utils/helper/AlertService';
import UserPermissionsComponent from '../../auth/UserPermissionsComponent';

const CREAR = 1;
const EDITAR = 2;
const search = {
    skip: 1,
    take: 10,
    search: "",
    fallecidoId: "",
    nroDoi: ""
}

function ListDeceasedPersons(props) {
    const newModel = {
        id:'',
        primerNombre: '',
        segundoNombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        nroDoi: '',
        sexo: '',
        direccion: '',
        fechaFallecimiento: ''
    };
    const [ fallecidos, setFallecidos ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ modelDeceased, setModelDeceased ] = useState(newModel);
    const datos_modal = { mdModal: false, titulo: "", cod: 0 }
    const [ infModal, setInfModal ] = useState(datos_modal)
    const [ paramSearch, setParamSearch ] = useState(search);
    const [ fallecidosPag, setFallecidosPag ] = useState({})

    const onClickSendDeceased = (model, onSubmitProps) => {
        var data = "";
        showConfirAlert({
            title: infModal.cod === CREAR ? "Desea registrar nuevo fallecido": "Desea actualizar fallecido",
            text: "Verifique que todos los campos esten registrados correctamente",
            preConfirm: () => {
                if(infModal.cod === CREAR){
                    data = crearFallecido(model);
                } else {
                    data = actualizarFallecido(model);
                }
                data.then(() => {
                    showSuccessAlert('Registro Exitoso');
                    onSubmitProps.setSubmitting(false)
                    onSubmitProps.resetForm();
                    setInfModal(datos_modal);
                    getData(paramSearch)
                }).catch((error) => {
                    showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
                });
            }
        });
    }

    const onDeletePersona = (id) => {
        showConfirAlert({
            title: '¿Estás seguro de eliminar Fallecido?',
            text: "¡No padrás revertir esto!",
            preConfirm: () => {
                deletePersona(id).then(() => {
                    showSuccessAlert("Eliminación exitosa");
                    getData(paramSearch);
                }).catch((error) =>{
                    showErrorAlert("Error, vuelva intentarlo")
                })
            }
        });
    }

    const onClickEditar = (item) => {
        setModelDeceased(item)
        setInfModal({...infModal,mdModal: true,titulo:"Editar Finado", cod: EDITAR})
    }

    const onInputSearch = ({target}) => {
        const { value } = target;
        setParamSearch({...paramSearch, search:value})
    }

    const handleTableChange = (page, sizePerPage) => {
        setParamSearch({
            ...paramSearch,
            skip:sizePerPage.page,
            take:sizePerPage.sizePerPage,
        })
    }

    const getData = async (param) => {
        try{
            setLoading(true);
            const data = await getListaFallecido(param);
            setLoading(false);
            setFallecidos(data.items);
            setFallecidosPag(data);
        }
        catch(e)
        {
            showErrorAlert("Error de servidor, vuelva intentarlo");
        }
       
    }
    const fechDataFallecido = async (value) => {
        const data = await getComboFallecido(value);
        return data;
    }
    useEffect(() =>{
        getData(paramSearch);
    },[paramSearch])

    const columns = [
        { 
            dataField: "nombreCompleto", 
            text: "Nombre"
        },
        { 
            dataField: "nroDoi", 
            text: "DNI"
        },
        { 
            dataField: "direccion", 
            text: "Dirección",
        },
        {   
            dataField: "fechaFal",     
            text: "Fech. Fallecimiento"
        },
        {
            dataField: "opciones", 
            text: "Opciones",
            align: 'center',
            sort: false,
            classes: 'py-1',
            formatter: (cellContent, row) => {
            return (
                <div>
                    <UserPermissionsComponent accion={['admin','editarFallecido']}>
                        <i className="ti-pencil-alt text-primary mr-2" data-toggle="tooltip" data-placement="right" title="Editar" onClick={() => onClickEditar(row)} style={{fontSize:'15px'}}/>
                    </UserPermissionsComponent>
                    <UserPermissionsComponent accion={['admin']}>
                        <i className="ti-trash text-danger" data-toggle="tooltip" data-placement="right" title="Eliminar" style={{fontSize:'15px'}} onClick={() => onDeletePersona(row.id)}/>
                    </UserPermissionsComponent>
                </div>
            );
            }
        }
          
    ]
    return (
        <div>
            <Row>
                <div className="col-12">
                    <Card >  
                        <Card.Body>
                            <div className="row mb-4 justify-content-between">
                                <div className = "col-lg-2 d-none d-sm-none d-md-block">
                                    <h4 className="card-title float-left mt-2">Lista Fallecidos</h4>
                                </div>
                                <div className = "col-lg-6 col-ms-12 pb-2">
                                    <Search
                                        onSearchInput={onInputSearch}
                                        value={paramSearch.search}
                                        onRefesh={() => setParamSearch(search)}
                                    >
                                        <FormFields
                                            initialValues={paramSearch}
                                            onSubmit={(values) => setParamSearch(values)}
                                        >
                                            <div className='row'>
                                                <div className='col-ms-12 col-md-6 col-lg-6'>
                                                    <AsyncSelectFields
                                                        label={"Finado(a)"}
                                                        isRequired = {false}
                                                        name={"fallecidoId"}
                                                        loadOptions = {fechDataFallecido}
                                                    />
                                                </div>
                                                <div className='col-ms-12 col-md-6 col-lg-6'>
                                                    <InputFields
                                                        label={"N° DNI"}
                                                        type="text"
                                                        name="nroDoi"
                                                        size = "sm"
                                                        isRequired = {false}
                                                    />
                                                </div>
                                                <div className='col-12'>
                                                    <ButtonFields title='Buscar'/>
                                                    <ButtonResetFields title='Limpiar' onClickCancel={() => setParamSearch(search)}/>
                                                </div>
                                            </div>
                                        </FormFields>
                                    </Search>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <button className="btn btn-outline-primary float-right" onClick={() =>{setInfModal({...infModal,mdModal: true,titulo:"Agregar Finado", cod: CREAR}); setModelDeceased(newModel)}}>
                                        <i className="fa fa-plus mr-2"/>
                                        Nuevo Fallecido
                                    </button>
                                </div>
                            </div>    
                            <Table
                                columns={columns}
                                list={fallecidos}
                                 page={fallecidosPag.page}
                                sizePerPage={paramSearch.take}
                                totalSize={fallecidosPag.total}
                                onTableChange={handleTableChange}
                            />
                            <ModalComponents
                                show={infModal.mdModal}
                                hide={() => setInfModal(datos_modal)}
                                titulo={infModal.titulo}
                            >
                                <FormCreateDeceased 
                                    modelDeceased={modelDeceased}
                                    onClickSend={(values,onSubmitProps) => onClickSendDeceased(values,onSubmitProps)}
                                />
                            </ModalComponents>
                            <Loading isLoading = {loading} />
                        </Card.Body>
                    </Card>
                </div>
            </Row>
        </div>
    );
}
export default ListDeceasedPersons;