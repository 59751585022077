import React from 'react';

const Footer = () => {
 
    return (
        <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block"></span>
                <span className="text-muted float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> Funeraría Villa Fe </span>
            </div>
        </footer>
    );
  
}

export default Footer;