import React, { useEffect, useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import { ButtonFields, ButtonResetFields, FormFields, InputFields, InputFileFields, Loading, SelectFields} from '../../components/Index';
import { createProductSchema } from '../../utils/validations/ProductValidation'
import { createProducto ,getListaCategoria, getListaModelo, getTipoProducto } from '../../services/ProductService'
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../utils/helper/AlertService';

function CreateProducts() {
    const [categoria, setCategoria] = useState([]);
    const [modelo, setModelo] = useState([]);
    const [tipoProducto, setTipoProducto] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showCategoria, setShowCategoria] = useState(false);
    const [image, setImage] = useState(null);

    const onClickSend = (model, onSubmitProps) => {
        showConfirAlert({
            title: "Desea registrar nuevo Producto",
            text: "Verifique que todos los campos esten registrados correctamente",
            preConfirm: () => {
                createProducto(model).then(() => {
                    showSuccessAlert('Registro Exitoso');
                    onSubmitProps.setSubmitting(false)
                    onSubmitProps.resetForm();
                    setImage(null);
                }).catch((error) => {
                    showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
                });
            }
        });
    }
    const getDataModel = async (categoriaId) => {
        setLoading(true)
        const data = await getListaModelo(categoriaId);
        setLoading(false);
        setModelo(data);
        
    }

    const onSelectTipoProducto = async (item) => {
        if(item !== '2872a996-7da1-40c4-835c-8a6feb98f09c' && item !== '19585307-e94f-468f-b90f-413f7d8b41eb'){
            setShowCategoria(true)
            setLoading(true);
            const data = await getListaCategoria();
            setLoading(false);
            setCategoria(data);
        }
        else{
            setModelo([]);
            setShowCategoria(false);
        }
    }
    const getData = async () => {
        try{
            setLoading(true);
            const data = await getTipoProducto();
            setLoading(false);
            setTipoProducto(data);
        }
        catch(e){
            showErrorAlert('Hubo un problema de registro',  e.apiMessage || 'Error desconocido');
        }
    }

    useEffect(() => {
        getData();
    },[])

    return (
        <div>
            <Row>
                <div className='col-12'>
                    <Card>
                        <Card.Body>
                            <h4 className="card-title">Registrar nuevo Producto</h4>
                            <FormFields
                                initialValues = {{
                                    nombre:'',
                                    color:'',
                                    descripcion:'',
                                    tipoProductoId:'',
                                    modeloId: null,
                                    categoriaId:null,
                                    imagen:{
                                        contenido:"",
                                        extension:""
                                    }
                                }}
                                onSubmit = {(values, onSubmitProps) => onClickSend(values,onSubmitProps)}
                                validationSchema = {createProductSchema}
                            >
                            <div className='row'>
                                <div className='col-sm-12 col-md-12 col-lg-8'>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-12 col-lg-6'>
                                            <InputFields
                                                label={"Nombre"}
                                                type="text"
                                                name="nombre"
                                                size = "sm"
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-12 col-lg-6'>
                                            <SelectFields
                                                label={"Tipo"}
                                                name="tipoProductoId"
                                                data={tipoProducto.map((item) =>({value:item.id, label: item.nombre }))}
                                                onSelect={(item) => onSelectTipoProducto(item.value)}
                                                isLoading = {loading}
                                            />
                                        </div>
                                        {
                                            showCategoria && 
                                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                                <SelectFields
                                                    label={"Categoría"}
                                                    name="categoriaId"
                                                    data={categoria.map((item) =>({value:item.id, label: item.nombre }))}
                                                    onSelect={(item) => getDataModel(item.value)}
                                                    isLoading = {loading}
                                                />
                                            </div>
                                        }
                                        {
                                            modelo.length > 0 && 
                                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                                <SelectFields
                                                    label={"Modelo"}
                                                    name="modeloId"
                                                    isRequired={false}
                                                    data={modelo.map((item) =>({value:item.id, label: item.nombre }))}
                                                    isLoading = {loading}
                                                />
                                        </div>
                                        }
                                        {
                                            showCategoria && 
                                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                                <InputFields
                                                    label={"Color"}
                                                    type="text"
                                                    name="color"
                                                    size = "sm"
                                                    isRequired = {false}
                                                />
                                            </div>
                                        }
                                        
                                        <div className='col-sm-12 col-md-12 col-lg-6'>
                                            <InputFields
                                                label={"Descipción"}
                                                type="text"
                                                name="descripcion"
                                                size = "sm"
                                                isRequired = {false}
                                            />
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-4 mt-4'>
                                    <Card>
                                        <Card.Body className='p-3'>
                                            <div className='h-75 w-100'>
                                                <img src={image} className="img-fluid" alt=""></img>
                                            </div>
                                            <div className='pt-4'>
                                                <InputFileFields 
                                                    name={"imagen"}
                                                    onSelectImage={(url) => setImage(url)}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-12 mt-2'>
                                    <ButtonFields/>
                                    <ButtonResetFields/>
                                </div>
                            </div>
                                
                            </FormFields>
                            <Loading/>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
        </div>
    );
}

export default CreateProducts;