import React from 'react';

function Checkbox({title, ...otherProps }) {
    return (
        <div className="form-check m-1">
            <label className="form-check-label">
                <input 
                    type="checkbox"
                    className="form-check-input"
                    {...otherProps}
                />
                <i className="input-helper"></i>
                {title}
            </label>
        </div>
    );
}

export default Checkbox;