import React from 'react'
import { format, parseISO } from 'date-fns';

export default function TablaProductosPedidos({producto = [], onClickDelete}) {
  return (
    <div className="table-responsive table-sm w-100 w-100">
        <table className="table">
            <thead>
                <tr className="bg-primary  text-white">
                    <th className="text-left p-2">Cant.</th>
                    <th className='p-2'>Nombre</th>
                    <th className="p-2">Fec. Inicio</th>
                    <th className="p-2">Fec. Fin</th>
                    <th className="text-right p-2">Precio Uni.</th>
                    <th className="text-right p-2">Prec. Total</th>
                    <th className="text-center p-2">opción</th>
                </tr>
            </thead>
            <tbody>
            {
                producto && producto.map((item, index) =>
                <tr key={index} className="text-right">
                    <td className="text-left pl-3 p-2">{item.stock}</td>
                    <td className="text-left p-2">{item.nombre}</td>
                    <td className="text-left p-2">{item.fechaInicio && format(parseISO(item.fechaInicio),'dd/MM/yyyy HH:mm')}</td>
                    <td className="text-left p-2">{item.fechaFin && format(parseISO(item.fechaFin),'dd/MM/yyyy HH:mm')}</td>
                    <td className="text-rightr p-2">{item.precio}</td>
                    <td className="text-right p-2">{item.precioTotal}</td>
                    <td className="text-center">
                        <i className='fa fa-trash-o text-danger' data-toggle="tooltip" data-placement="right" title="Eliminar" onClick={() => onClickDelete(item.productoId)}></i>
                    </td>
                </tr>)
            }
            </tbody>
        </table>
    </div>
  )
}
