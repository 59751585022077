import axios from 'axios';
import handleApiError from './ErrorHandler';

const instance = axios.create({
    //baseURL: "https://localhost:7193/"
    baseURL: "https://villafe.somee.com/villafeApi/"
});

instance.interceptors.request.use(
    (config) => {
      var token = sessionStorage.getItem("jwt");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => handleApiError(error)
);

export default instance;