import React, { useState, useEffect, Fragment } from 'react'
import { Card, Row } from 'react-bootstrap';
import { ButtonFields, FormFields, Checkbox, SelectFields , Loading } from '../../components/Index';
import { getComboEmpleado, getPermisosUser, getPermisos, crearPermisoUsuario } from '../../services/PersonServices';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../utils/helper/AlertService';
import { createPermisoSchema } from '../../utils/validations/PersonValidation';


export default function UserManagement() {
  const [usuarios, setUsuarios] = useState([]);
  const [permisos, setPermisos] = useState(null);
  const [permisosSeleccionados, setPermisosSeleccionados] = useState([]);
  const [rolesSeleccionados, setRolesSeleccionados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPermisos, setShowPermisos] = useState(true);

  const handleUsuarioSeleccionado = async (usuarioId) => {
    try {
      const permisosUser = await getPermisosUser(usuarioId);
      setPermisosSeleccionados(permisosUser.listaPermisos); 
      setRolesSeleccionados(permisosUser.listaRoles);
    }
    catch(e){
      showErrorAlert('Error !', e.apiMessage || 'Error desconocido');
    }
  };

  const onClickSend = (values,onSubmitProps) => {
    values.permisos = permisosSeleccionados;
    values.roles = rolesSeleccionados;

    showConfirAlert({
      title: "Desea guardar los cambios",
      text: "Verifique que todos los campos esten registrados correctamente",
      preConfirm: () => {
        crearPermisoUsuario(values).then(() => {
          showSuccessAlert('Registro Exitoso');
        }).catch((error) => {
            showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
        });
      }
    });
  }

  const handleTogglePermiso = (permisoId) => {
    const nuevosPermisos = [...permisosSeleccionados];
    
    if (nuevosPermisos.includes(permisoId)) {
      nuevosPermisos.splice(nuevosPermisos.indexOf(permisoId), 1);
    } else {
      nuevosPermisos.push(permisoId);
    }
    setPermisosSeleccionados(nuevosPermisos);
  };

  const handleToggleRoles = (rolId) => {
    const nuevosRoles = [...rolesSeleccionados];
    if (nuevosRoles.includes(rolId)) {
      nuevosRoles.splice(nuevosRoles.indexOf(rolId), 1);
    } else {
      nuevosRoles.push(rolId);
    }
    setRolesSeleccionados(nuevosRoles);
  };

  const getData = async () => {
    try{
      setIsLoading(true)
      const data = await getComboEmpleado("");
      const permisos = await getPermisos();
      setIsLoading(false);
      setUsuarios(data);
      setPermisos(permisos);
    }
    catch(e){
      showErrorAlert('Error !', e.apiMessage || 'Error desconocido');
    }
  }

  useEffect(() => {
    var rol = rolesSeleccionados.find(x => x === "71f2c0de-d224-441c-b79c-20f0a1c31c2e");
    rol ? setShowPermisos(false): setShowPermisos(true);
  },[rolesSeleccionados])

  useEffect(() => {
    getData();
  },[]);

  return (
    <div>
        <Row>
            <div className="col-12">
                <Card >  
                    <Card.Body>
                        <FormFields
                            initialValues = {{
                              usuarioId: "",
                              permisos: [],
                              roles: []
                            }}
                            onSubmit = {(values, onSubmitProps) => onClickSend(values,onSubmitProps)}
                            validationSchema = {createPermisoSchema}
                        >
                        <div className='row'>
                          <div className='col-12'>
                              <h4 className="card-title float-left mt-2">Asignación de Roles y Permisos</h4>
                          </div>
                          <div className="col-lg-4">
                              <div className="py-4">
                                  <SelectFields 
                                      name={"usuarioId"}
                                      label={"Usuario"}
                                      data={usuarios.map((item) =>({value:item.userId, label: item.nombre }))}
                                      onSelect={(item) => handleUsuarioSeleccionado(item.value) }
                                  />
                              </div>
                          </div>
                          <div className="col-lg-8">
                              <div className="mt-4 py-2 border-bottom">
                                  <h4 className='text-muted pl-2'>Roles</h4>
                              </div>
                              <div className="profile-feed pt-2">
                                  <div className='row'>
                                    {
                                      permisos && permisos.roles.map((item, index) => (
                                        <div key={index} className='col-sm-12 col-md-6 col-lg-4'>
                                          <Checkbox 
                                            checked={rolesSeleccionados.includes(item.rolId)}
                                            onChange={() => handleToggleRoles(item.rolId)}
                                            title={item.nombre}
                                          />
                                        </div>
                                      ))
                                    }
                                  </div>
                              </div>
                              {
                                showPermisos && (
                                  <Fragment>
                                    <div className="mt-4 py-2 border-bottom">
                                        <h4 className='text-muted pl-2'>Permisos</h4>
                                    </div>
                                    <div className="profile-feed pt-2">
                                        <div className='row'>
                                          {
                                            permisos && permisos.permisos.map((item, index) => (
                                              <div key={index} className='col-sm-12 col-md-6 col-lg-4'>
                                                <Checkbox 
                                                  checked={permisosSeleccionados.includes(item.permisoId)}
                                                  onChange={() => handleTogglePermiso(item.permisoId)}
                                                  title={item.nombre}
                                                />
                                              </div>
                                            ))
                                          }
                                        </div>
                                    </div>
                                  </Fragment>)
                              }
                          </div>
                          <Loading isLoading={isLoading}/>
                          <div className='col-12 mt-4'>
                            <ButtonFields/>
                          </div>
                        </div>
                      </FormFields>
                    </Card.Body>
                </Card>
            </div>
        </Row>
    </div>
    
  );
  
}
