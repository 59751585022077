import React, { useContext } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Login from '../pages/auth/Login';
import { Context } from '../context/AuthContext';
import ActivityList from '../pages/activityDetails/ActivityList';
import Catalogue from '../pages/products/Catalogue';
import CreateDeceased from '../pages/person/Deceased/CreateDeceased';
import CreateEmployees from '../pages/employees/CreateEmployees';
import CreateSales from '../pages/sales/CreateSales';
import CreateShopping from '../pages/shopping/CreateShopping';
import CreatePerson from '../pages/person/client/CreatePerson';
import CreateSupplier from '../pages/person/supplier/CreateSupplier';
import CreateProducts from '../pages/products/CreateProducts';
import CreateRentals from '../pages/rentals/CreateRentals';
import CierreCaja from '../pages/caja/CierreCaja';
import Dashboard from '../pages/dashboard/Dashboard';
import DetailSales from '../pages/sales/DetailSales';
import DetailCaja from '../pages/caja/DetailCaja';
import DetailShopping from '../pages/shopping/DetailShopping';
import Footer from '../pages/shared/Footer';
import Navbar from '../pages/shared/Navbar';
import Sidebar from '../pages/shared/Sidebar';
import ListaAniversarios from '../pages/person/client/ListaAniversarios';
import ListCategories from '../pages/products/ListCategories';
import ListCompany from '../pages/admin/ListCompany';
import ListDeceasedPersons from '../pages/person/Deceased/ListDeceasedPersons';
import ListEmployees from '../pages/employees/ListEmployees';
import ListExpenses from '../pages/caja/ListExpenses';
import ListShopping from '../pages/shopping/ListShopping';
import ListSales from '../pages/sales/ListSales';
import ListSupplier from '../pages/person/supplier/ListSupplier';
import ListPersons from '../pages/person/client/ListPersons';
import ListProducts from '../pages/products/ListProducts';
import ListRentals from '../pages/rentals/ListRentals';
import ListIncome from '../pages/caja/ListIncome';
import Profile from '../pages/employees/Profile';
import ReportSales from '../pages/reports/ReportSales';
import ReportRentals from '../pages/reports/ReportRentals';
import ReportShopping from '../pages/reports/ReportShopping';
import UserManagement from '../pages/employees/UserManagement';
import Error404 from '../pages/error_page/Error404';
import RouteProtected from '../pages/auth/RouteProtected';



const Verified = () => {
    const { token } = useContext(Context);
    const location = useLocation();
    
    if (!token) {
      return (
        <Navigate to="/login" state={{ from: location }} replace />
      );
    }
  
    return (
      <div className="container-scroller">
        <Navbar/>
        <div className="container-fluid page-body-wrapper">
          <Sidebar/>
          <div className="main-panel">
            <div className="content-wrapper">  
              <Outlet/>
            </div>
            <Footer/>
          </div>
        </div>
      </div>
    )
};

export const AppRouters = () => {
    return (
        <>
          <Routes>
              {/* Public route */}
              {/* <Route path='/' element={<ExistSesion />} /> */}
              <Route path="/login" element={<Login/>} />

              {/* Private route */}
              <Route path="/" element={<Verified/>}>
              <Route index path="/" element={<Dashboard/>} />
                {/* <Route path="dashboard" element={<Dashboard/>} /> */}
                
                <Route path="activities" element={<ActivityList/>} />
                <Route path="admin/">
                  <Route path="list-company" element={<ListCompany/>} />
                </Route> 
                <Route path="caja/">
                  <Route path="list-egresos" element={<ListExpenses/>} />
                  <Route path="list-ingresos" element={<RouteProtected accion={['admin','admVenta']}><ListIncome/></RouteProtected>} />
                  <Route path="cierre" element={<RouteProtected accion={['admin','admVenta']}><CierreCaja/></RouteProtected>} />
                  <Route path="detail/:cajaId" element={<RouteProtected accion={['admin','admVenta']}><DetailCaja/></RouteProtected>} />
                </Route> 
                <Route path='report/'>
                  <Route path="list-sales" element={<RouteProtected accion={['admin']}><ReportSales/></RouteProtected>} />
                  <Route path="list-rentals" element={<RouteProtected accion={['admin']}><ReportRentals/></RouteProtected>} />
                  <Route path="list-compras" element={<RouteProtected accion={['admin']}><ReportShopping/></RouteProtected>}/>
                </Route>
                <Route path="seguimiento-cliente" element={<ListaAniversarios/>} />
                {/* <Route path="inventory/">
                  <Route path="list-inventory" element={<ListExpenses/>} />
                </Route> */}
                <Route path="services/">
                  <Route path="list-sales" element={<ListSales/>} />
                  <Route path="create-sales" element={<CreateSales/>} />
                  <Route path="list-rentals" element={<ListRentals/>} />
                  <Route path="create-rentals" element={<CreateRentals/>} />
                  <Route path="details-sales/:pedidoId" element={<DetailSales/>} />
                </Route> 
                <Route path="shopping/">
                  <Route path="create" element={<CreateShopping/>} />
                  <Route path="list" element={<ListShopping/>} />
                  <Route path="detail/:compraId" element={<DetailShopping/>} />
                </Route>
                <Route path='person/'>
                  <Route path="create" element={<CreatePerson/>}/>
                  <Route path="create-deceased" element={<CreateDeceased/>}/>
                  <Route path="list-person" element={<ListPersons/>}/>
                  <Route path="list-deceased" element={<ListDeceasedPersons/>}/>
                </Route>
                <Route path='products/'>
                  <Route path="create" element={<CreateProducts/>}/>
                  <Route path="list-products" element={<ListProducts/>}/>
                  <Route path="categories" element={<ListCategories/>}/>
                  <Route path="catalogue" element={<Catalogue/>}/>
                </Route>
                <Route path='supplier/'>
                  <Route path="create" element={<CreateSupplier/>}/>
                  <Route path="list-supplier" element={<ListSupplier/>}/>
                </Route>
                <Route path='user/'>
                  <Route path="create" element={<CreateEmployees/>}/>
                  <Route path='list-employees' element={<ListEmployees/>}/>
                  <Route path="perfil" element={<Profile/>}/>
                  <Route path="user-management" element={<RouteProtected accion={['admin']} ><UserManagement/></RouteProtected>}/>
                </Route>
                <Route path='*' element={<Navigate replace to="/error_404"/>} />
              </Route>
              <Route path='/error_404' element={<Error404/>}/>
          </Routes>
        </>
    );
}
